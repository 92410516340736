import React, { useEffect, useContext, useState, Fragment } from "react";
import Logo from "../assets/loginpagelogo.png";
import HrmsImage from "../assets/hrms.png";
import { useForm } from "react-hook-form";
import Error from "../utilities/Error";
import { LoginContext } from "../context/LoginContext";
import axios from "axios";
const bcrypt = require("bcryptjs");

const LoginForm = () => {
  const [hasError, setHasError] = useState(false);
  const { register, handleSubmit, errors, formState, reset } = useForm();
  const { dispatchLogin } = useContext(LoginContext);

  const onSubmit = async (data) => {
    setHasError(false);

    var userResult = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getloginuser`,
      { params: { userName: data.username } }
    );

    let isValid = false;
    if (userResult.data.length !== 0) {
      isValid = bcrypt.compareSync(
        data.password,
        userResult.data[0].passwordhash
      );
    }

    if (isValid) {
      console.log("successfully logged in.");
      localStorage.setItem("userid", userResult.data[0].userid);
      localStorage.setItem("role", userResult.data[0].role);
      localStorage.setItem("username", userResult.data[0].username);
      localStorage.setItem("unit", userResult.data[0].unitname);
      localStorage.setItem("sectionid", userResult.data[0].sectionid);

      dispatchLogin({
        type: "SET_LOGGEDINUSER",
        payload: userResult.data[0],
      });
    } else {
      console.log("not able to log in.");
      setHasError(true);
      reset(0);
    }
  };
  return (
    <div className="h-screen w-full bg-white flex justify-center items-center">
      <div className="grid grid-cols-3 w-3/4 bg-theme-grey rounded-md">
        <div className="flex flex-col justify-center items-center col-span-2 pt-2">
          <img src={Logo} className="mb-2" alt="dashboard" />
          <img src={HrmsImage} className="w-64" alt="dashboard" />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid col-span-1 text-left py-4 px-4 md:py-8 md:px-8 rounded-md  rounded-l-none bg-gray-900 text-white"
        >
          {hasError && <Error message="Invalid username or password" />}
          <div>
            <div className="flex justify-center">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-20 w-20 text-center"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <p className="pl-1 text-2xl mb-5">
              Hello, <span className="text-sm"> Let's get started.</span>
            </p>
            <p className="text-sm mb-2 pl-1 font-medium">Username</p>
            <input
              name="username"
              type="text"
              className="border text-black rounded-3xl w-full py-1.5 px-3 text-sm outline-none"
              ref={register({ required: true })}
              autoComplete="off"
            />
            {errors.username && <Error message="Username is required" />}

            <p className="text-sm mt-4 mb-2 pl-1 font-medium">Password</p>
            <input
              name="password"
              type="password"
              className="border text-black rounded-3xl w-full py-1.5 px-3 text-sm outline-none"
              ref={register({ required: true })}
              autoComplete="off"
            />
            {errors.password && <Error message="Password is required" />}
            <div className="mt-6 flex space-x-4 justify-between">
              <button
                className="w-1/2 px-4 py-2 focus:outline-none text-white shadow-md bg-sky-blue rounded-lg hover:bg-orange-darker font-bold text-sm flex justify-center"
                type="submit"
              >
                {formState.isSubmitting ? (
                  <Fragment>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Authenticating</span>
                  </Fragment>
                ) : (
                  "Login"
                )}
              </button>
              {/* <button
                className="text-gray-300 text-xs"
                type="button"
                onClick={toggleLogin}
              >
                Forgot Password?
              </button> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

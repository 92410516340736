import React, { Fragment, useEffect } from "react";
import { getFormattedDateInputDate } from "../../../utilities/functions/UtilityFunctions";
import Circle from "../../../assets/circle.png";
import Line from "../../../assets/minus.png";
import DownArrow from "../../../assets/arrow-down.png";
const EmployeeCareerTimeline = ({
  empCareerTimelineList,
  empNextProgressionDetails,
  nextProgressionDate,
  empPersonalDetailsObj,
  empCurrentOfficialDetailsObj,
}) => {
  return (
    <div className="flex flex-col px-4">
      <div className="flex items-center text-center mb-4">
        <div className="p-3 bg-green-600 text-white font-bold rounded-md border-8 border-green-300 border-r-0 border-l-0">
          {getFormattedDateInputDate(nextProgressionDate)}
        </div>
        <img src={Line} className="h-6 w-20"></img>
        <div>
          <div className="flex">
            <div className="p-1 bg-green-600 w-1/2 text-sm text-center rounded-t-md text-white">
              next progression status
            </div>
          </div>
          <div className="p-3 bg-white-300 border border-gray-300 rounded-md">
            {empPersonalDetailsObj.employeename} to be promoted to
            <span className="italic">
              {empCurrentOfficialDetailsObj.emptypename
                .toLowerCase()
                .includes("regular employee") ? (
                <span className="ml-1 font-bold">
                  Level {empNextProgressionDetails.levelnumber} -{" "}
                  {empNextProgressionDetails.tolevelname}
                </span>
              ) : (
                <span className="ml-1 font-bold">
                  {empNextProgressionDetails.toemployeetypename}
                </span>  
              )}
            </span>{" "}
            on this date.{" "}
          </div>
        </div>
      </div>

      <div className="flex justify-center mb-4 animate-pulse">
        <svg
          width="43"
          height="63"
          viewBox="0 0 43 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10"
        >
          <rect
            x="14"
            y="15"
            width="15"
            height="38"
            fill="#63d1ac"
            className="text-green-500"
          />
          <path d="M21.5 0L40.1195 23.25H2.88045L21.5 0Z" fill="#63d1ac" />
          <rect x="14" y="55" width="15" height="3" fill="#63d1ac" />
          <rect x="14" y="60" width="15" height="3" fill="#63d1ac" />
        </svg>
      </div>
      {empCareerTimelineList &&
        empCareerTimelineList.map((x, index) => {
          return (
            <div className="mb-3">
              <div className="flex items-center mb-3">
                <div className="p-3 bg-gray-600 text-white font-bold rounded-md border-8 border-gray-400 border-r-0 border-l-0">
                  {getFormattedDateInputDate(x.promotiondate)}
                </div>
                <img src={Line} className="h-6 w-20"></img>
                <div className="">
                  <div className="p-3 bg-white-300 border-gray-300 border rounded-md">
                    {index === empCareerTimelineList.length - 1 ? (
                      <div>
                        {empPersonalDetailsObj.employeename} was appointed on
                        this date in{" "}
                        <span className="font-bold italic">
                          {x.emptypename}
                        </span>
                      </div>
                    ) : (
                      <div>
                        {empPersonalDetailsObj.employeename} was promoted to{" "}
                        <span className="font-bold italic mr-1">
                          {x.emptypename
                            .toLowerCase()
                            .includes("regular employee") ||
                          x.emptypename === undefined ? (
                            <span>
                              Level {x.emplevelnumber} - {x.emplevelname}{" "}
                            </span>
                          ) : (
                            <span> {x.emptypename}</span>
                          )}
                        </span>
                        on this date.
                      </div>
                    )}
                  </div>
                  {index === 0 ? (
                    <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-b-md text-white">
                      current status
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {index === empCareerTimelineList.length - 1 ? (
                <div>
                  <div className="flex justify-center">
                    {" "}
                    <img
                      src={DownArrow}
                      className="h-9 mb-3 transform rotate-180"
                    ></img>
                  </div>
                  <div className="flex justify-center">
                    <img src={Circle} className="h-9 "></img>
                  </div>
                </div>
              ) : (
                <div
                  className={`flex justify-center ${
                    index === empCareerTimelineList.length - 1 ? "" : ""
                  }`}
                >
                  <img
                    src={DownArrow}
                    className="h-9  transform rotate-180"
                  ></img>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default EmployeeCareerTimeline;

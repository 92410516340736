import "./App.css";
import React, { Fragment } from "react";

import RouteComponent from "./version2/Routing/RouteComponent";

function App() {
  return (
    <Fragment>
      <div className="App">
        <RouteComponent />
      </div>

      {/* OLD VERSION */}
      {/* <LoginProvider>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/" exact component={LoginComponent} />
              <Route
                path="/systemadmindashboard"
                exact
                component={SystemAdminHome}
              />
              <Route
                path="/employeemanagement"
                exact
                component={SystemAdminHome}
              />
              <Route
                path="/sectionconfiguration"
                exact
                component={SystemAdminHome}
              />
              <Route
                path="/designationconfiguration"
                exact
                component={SystemAdminHome}
              />
              <Route
                path="/employeetypeconfiguration"
                exact
                component={SystemAdminHome}
              />
              <Route
                path="/levelconfiguration"
                exact
                component={SystemAdminHome}
              />
              <Route
                path="/promotionpatternconfiguration"
                exact
                component={SystemAdminHome}
              />
              <Route
                path="/levelpatternconfiguration"
                exact
                component={SystemAdminHome}
              />

              <Route
                path="/controllingofficerdashboard"
                exact
                component={ControllingOfficerHome}
              />
              <Route
                path="/employee-management"
                exact
                component={ControllingOfficerHome}
              />

              <Route
                path="/sectionhead/sectionheaddashboard"
                exact
                component={SectionHeadHome}
              />
              <Route
                path="/sectionhead/assessment"
                exact
                component={SectionHeadHome}
              />
            </Switch>
          </Router>
          <LogoutComponent />
        </div>
      </LoginProvider> */}
    </Fragment>
  );
}

export default App;

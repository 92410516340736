import React, { Fragment } from "react";
import Line from "../../../assets/minus.png";
import DownArrow from "../../../assets/arrow-down.png";
import { getFormattedDateInputDate } from "../../../utilities/functions/UtilityFunctions";
import Accordian from "../../ReusableComponents/accordian/Accordian";

const EmployeeCareerTimeline = ({
  empPersonalDetails,
  appointmentDetails,
  currentOfficialDetails,
  nextProgressionDetails,
  noNextPromotionDetailsError,
  empCareerProgressionHistory,
}) => {
  return (
    <Fragment>
      <div className="p-4">
        {noNextPromotionDetailsError ? (
          ""
        ) : (
          <div>
            <div className="flex items-center text-center mb-4">
              <div className="p-3 bg-green-600 text-white font-bold rounded-md border-8 border-green-300 border-r-0 border-l-0">
                {getFormattedDateInputDate(
                  nextProgressionDetails.nextPromotionDate
                )}
              </div>
              <img src={Line} className="h-6 w-20"></img>
              <div>
                <div className="flex">
                  <div className="p-1 bg-green-600 w-1/2 text-sm text-center rounded-t-md text-white">
                    next progression status
                  </div>
                </div>
                <div className="p-3 bg-white-300 border border-gray-300 rounded-md">
                  {empPersonalDetails.employeename} to be promoted to
                  <span className="italic font-bold text-blue-400 ml-1">
                    {currentOfficialDetails.empcurrentemptypename ===
                    "Regular" ? (
                      <span>Level - </span>
                    ) : (
                      ""
                    )}
                    {nextProgressionDetails.nextPromotionStatus}
                  </span>{" "}
                  on this date.
                </div>
              </div>
            </div>
            <div className="flex justify-center mb-4 animate-pulse">
              <svg
                width="43"
                height="63"
                viewBox="0 0 43 63"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
              >
                <rect
                  x="14"
                  y="15"
                  width="15"
                  height="38"
                  fill="#63d1ac"
                  className="text-green-500"
                />
                <path
                  d="M21.5 0L40.1195 23.25H2.88045L21.5 0Z"
                  fill="#63d1ac"
                />
                <rect x="14" y="55" width="15" height="3" fill="#63d1ac" />
                <rect x="14" y="60" width="15" height="3" fill="#63d1ac" />
              </svg>
            </div>
          </div>
        )}
        <div className="flex items-center mb-3">
          <div>
            <div className="p-3 bg-gray-600 text-white font-bold rounded-md border-8 border-gray-400 border-r-0 border-l-0">
              {currentOfficialDetails.datepromotedtocurrentstatus !== null &&
              currentOfficialDetails.datepromotedtocurrentstatus !== "" ? (
                <span>
                  {getFormattedDateInputDate(
                    currentOfficialDetails.datepromotedtocurrentstatus
                  )}
                </span>
              ) : (
                "-"
              )}
            </div>
          </div>
          <img src={Line} className="h-6 w-20"></img>
          <div className="">
            <div className="p-3 bg-white-300 border-gray-300 border rounded-md">
              <div>
                {empPersonalDetails.employeename} is currently designated as{" "}
                <span className="font-bold italic text-blue-400">
                  {currentOfficialDetails.empcurrentdesignationname}
                  {"\u00A0"}

                  <span className="not-italic text-gray-500">
                    {currentOfficialDetails.empcurrentlevelnumber !== null
                      ? `(Level - ${currentOfficialDetails.empcurrentlevelnumber})`
                      : ""}
                  </span>
                </span>{" "}
                in{" "}
                <span className="font-bold">
                  {currentOfficialDetails.empcurrentemptypename}
                </span>{" "}
                basis.
              </div>
            </div>
            <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-b-md text-white">
              current status
            </div>
          </div>
        </div>
        {empCareerProgressionHistory.map((x) => {
          return (
            <Fragment>
              <div className="flex justify-center my-3">
                <img
                  src={DownArrow}
                  className="h-9  transform rotate-180"
                ></img>
              </div>
              <div className="flex items-center">
                <div className="p-3 bg-gray-600 text-white font-bold rounded-md border-8 border-gray-400 border-r-0 border-l-0">
                  {getFormattedDateInputDate(x.progressiondate)}
                </div>
                <img src={Line} className="h-6 w-20"></img>
                <div className="">
                  <div className="p-3 bg-white-300 border-gray-300 border rounded-md">
                    <div>
                      {x.progressiontype === "Designation promotion" ? (
                        <Fragment>
                          {empPersonalDetails.employeename} was promoted to
                          <span className="font-bold italic text-blue-400 mx-2">
                            {x.designationname}
                          </span>{" "}
                          on this date.
                        </Fragment>
                      ) : x.progressiontype === "Level promotion" ? (
                        <Fragment>
                          {empPersonalDetails.employeename} was promoted to
                          <span className="font-bold italic text-blue-400 mx-2">
                            Level - {x.levelnumber}
                          </span>{" "}
                          on this date.
                        </Fragment>
                      ) : x.progressiontype === "Unit Transfer" ? (
                        <Fragment>
                          {empPersonalDetails.employeename} was transferred to
                          <span className="font-bold italic text-blue-400 mx-2">
                            {x.unitname} unit
                          </span>{" "}
                          on this date.
                        </Fragment>
                      ) : x.progressiontype === "Upgradation" ? (
                        <Fragment>
                          {empPersonalDetails.employeename} was upgraded to
                          <span className="font-bold italic text-blue-400 mx-2">
                            {x.emptypename}
                          </span>{" "}
                          on this date.
                        </Fragment>
                      ) : x.progressiontype === "Redesignation" ? (
                        <Fragment>
                          {empPersonalDetails.employeename} was redesignated to
                          <span className="font-bold italic text-blue-400 mx-2">
                            {x.designationname}
                          </span>{" "}
                          on this date.
                        </Fragment>
                      ) : x.progressiontype === "Section Change" ? (
                        <Fragment>
                          {empPersonalDetails.employeename} was shifted to
                          section -
                          <span className="font-bold italic text-blue-400 mx-2">
                            {x.sectionname}
                          </span>{" "}
                          on this date.
                        </Fragment>
                      ) : x.progressiontype === "Promotion" ? (
                        <Fragment>
                          {empPersonalDetails.employeename} was promoted to
                          <span className="font-bold italic text-blue-400 mx-2">
                            {x.designationname}
                            {"\u00A0"}
                            {x.levelid !== null && x.levelid !== "" ? (
                              <span className="text-gray-500 not-italic">
                                (Level - {x.levelnumber})
                              </span>
                            ) : (
                              ""
                            )}
                          </span>{" "}
                          in<b> {x.emptypename} </b>basis.
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {x.remarks !== null && x.remarks !== "" ? (
                    <Accordian title={"Remarks"} content={x.remarks} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
        <div className="flex justify-center my-3">
          <img src={DownArrow} className="h-9  transform rotate-180"></img>
        </div>
        <div className="flex items-center">
          <div className="p-3 bg-gray-600 text-white font-bold rounded-md border-8 border-gray-400 border-r-0 border-l-0">
            {getFormattedDateInputDate(appointmentDetails.empappointmentdate)}
          </div>
          <img src={Line} className="h-6 w-20"></img>
          <div className="">
            <div className="p-3 bg-white-300 border-gray-300 border rounded-md">
              <div>
                {empPersonalDetails.employeename} was appointed on this date as{" "}
                <span className="font-bold italic text-blue-400">
                  {appointmentDetails.empappointeddesignationname}
                </span>{" "}
                in{" "}
                <span className="font-bold">
                  {appointmentDetails.empappointedemptypename}
                </span>{" "}
                basis.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmployeeCareerTimeline;

import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../utilities/Loader";
import EmployeeCount from "../ReusableComponents/EmployeeCount";
import dashboard from "../../assets/dashboard.png";
import ViewStatisticsButton from "../ReusableComponents/ViewStatisticsButton";
import { Link } from "react-router-dom";

import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import NotificationBox from "../ReusableComponents/NotificationBox";

const HigherManagementDashboard = () => {
  const [empCountGangtok, setEmpCountGangtok] = useState(0);
  const [empCountJorethang, setEmpCountJorethang] = useState(0);
  const [totalEmpCount, setTotalEmpCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sectionWiseEmpCount, setSectionWiseEmpCount] = useState([]);
  const [statusWiseEmpCount, setStatusWiseEmpCount] = useState({});
  const [pendingPromotionEmpList, setPendingPromotionEmpList] = useState([]);
  const [pendingIncrementEmpList, setPendingIncrementEmpList] = useState([]);
  const [pendingRetirementEmpList, setPendingRetirementEmpList] = useState([]);

  const getDashboardDetails = async () => {
    let result1 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCountGangtok`
    );
    setEmpCountGangtok(result1.data);
    let result2 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCountJorethang`
    );
    setEmpCountJorethang(result2.data);
    let result3 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getTotalEmployeeCount`
    );
    setTotalEmpCount(result3.data);
    let result4 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionWiseEmployeeCount`
    );
    for (let i = 0; i < result4.data.length; i++) {
      result4.data[i].empcount = parseInt(result4.data[i].empcount);
    }
    setSectionWiseEmpCount(result4.data);
    let result5 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getStatusWiseEmployeeCount`
    );
    setStatusWiseEmpCount(result5.data);
    const result6 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getPromotionPendingEmpList`
    );
    setPendingPromotionEmpList(result6.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);
  return (
    <div className="p-6 h-full">
      {isLoading ? (
        <Loader message={"Configuring your dashboard"} />
      ) : (
        <div className="">
          <p className="font-bold text-xl flex items-center text-gray-700 pb-4">
            Alerts
          </p>
          <div className="grid grid-cols-4 gap-4 lg:gap-8 xl:gap-10 text-gray-800 mb-4">
            {pendingPromotionEmpList?.length > 0 && (
              <div className="col-span-1">
                <NotificationBox
                  count={pendingPromotionEmpList?.length}
                  label={"Employee Promotion Alert"}
                  description={
                    "List of employees whose promotions are close or due"
                  }
                  bgcolor="bg-blue-600"
                  path="/highermanagement/promotionPendingList"
                  fromPath="/highermanagement/highermanagementdashboard"
                />
              </div>
            )}
            {pendingIncrementEmpList?.length > 0 && (
              <div className="col-span-1">
                <NotificationBox
                  count={5}
                  label={"Salary Increment Alert"}
                  description={
                    "List of employees whose salary increments are close or due"
                  }
                  bgcolor="bg-green-600"
                  path="/highermanagement/SalaryIncrementPendingList"
                  fromPath="/highermanagement/highermanagementdashboard"
                />
              </div>
            )}
            {pendingRetirementEmpList?.length > 0 && (
              <div className="col-span-1">
                <NotificationBox
                  count={10}
                  label={"Employee Retirement Alert"}
                  description={
                    "List of employees whose retirement dates are close"
                  }
                  bgcolor="bg-yellow-600"
                  path="/highermanagement/RetirementList"
                  fromPath="/highermanagement/highermanagementdashboard"
                />
              </div>
            )}
          </div>
          <p className="font-bold text-xl flex items-center text-gray-700 py-6">
            Registered Employees
          </p>

          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1 bg-white p-4 flex flex-col justify-center">
              <div className="flex flex-col items-center border-b pb-3">
                <EmployeeCount
                  count={totalEmpCount}
                  label={"Total Employees"}
                />
              </div>
              <div className="grid grid-cols-2 pt-3">
                <div className="col-span-1 flex flex-col items-center border-r">
                  <EmployeeCount
                    count={empCountGangtok}
                    label={"Gangtok unit"}
                  />
                  <Link
                    to={{
                      pathname: "/highermanagement/gangtokunitstats",
                      state: {
                        empCountGangtok: empCountGangtok,
                      },
                    }}
                  >
                    <ViewStatisticsButton label={"View Statistics"} />
                  </Link>
                </div>
                <div className="col-span-1 flex flex-col items-center">
                  <EmployeeCount
                    count={empCountJorethang}
                    label={"Jorethang unit"}
                  />
                  <Link
                    to={{
                      pathname: "/highermanagement/jorethangunitstats",
                      state: {
                        empCountJorethang: empCountJorethang,
                      },
                    }}
                  >
                    <ViewStatisticsButton label={"View Statistics"} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-span-2 bg-white p-6">
              {statusWiseEmpCount.length > 0 && (
                <>
                  <div className="">
                    <p className="font-bold">Gangtok Unit</p>
                    <div className="grid grid-cols-4 mt-4">
                      {statusWiseEmpCount
                        .filter((emp) => emp.empcurrentunitname === "Gangtok")
                        .sort((a, b) =>
                          a.empcurrentemptypename > b.empcurrentemptypename
                            ? 1
                            : -1
                        )
                        .map((emptype, index) => (
                          <StatusCount
                            key={index}
                            count={emptype.empcount}
                            status={emptype.empcurrentemptypename}
                            bordercolor={"border-green-200"}
                            bgcolor={"bg-green-400"}
                          />
                        ))}
                    </div>
                  </div>
                  <div className="pt-6">
                    <p className="font-bold">Jorethang Unit</p>
                    <div className="grid grid-cols-4 mt-4">
                      {statusWiseEmpCount
                        .filter((emp) => emp.empcurrentunitname === "Jorethang")
                        .sort((a, b) =>
                          a.empcurrentemptypename > b.empcurrentemptypename
                            ? 1
                            : -1
                        )
                        .map((emptype, index) => (
                          <StatusCount
                            key={index}
                            count={emptype.empcount}
                            status={emptype.empcurrentemptypename}
                            bordercolor={"border-green-200"}
                            bgcolor={"bg-green-400"}
                          />
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="mt-4 p-4 rounded-lg bg-white border">
            <p className="font-medium text-gray-800">
              Section wise registered employees
            </p>
            <ResponsiveContainer width="100%" height={450}>
              <BarChart
                className="mt-8"
                data={sectionWiseEmpCount}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="sectionname" />
                {/* <YAxis /> */}
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="empcountgangtok"
                  fill="#1F73D8"
                  name="Gangtok Employees"
                  label={{
                    fill: "white",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                />
                <Bar
                  dataKey="empcountjorethang"
                  fill="#fb8c00"
                  name="Jorethang Employees"
                  label={{
                    fill: "white",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};
const StatusCount = ({ count, status, bgcolor, bordercolor }) => {
  return (
    <div className="flex flex-col justify-center items-center p-2 border">
      <div className="font-bold flex items-center">{count}</div>
      <p className="text-center mt-2 font-medium text-sm">{status}</p>
    </div>
  );
};

export default HigherManagementDashboard;

import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Loader from "../../../utilities/Loader";
import { getFormattedDateInputDate } from "../../../utilities/functions/UtilityFunctions";
import noImage from "../../../assets/noImage.jpg";
import nouser from "../../../assets/noUser.jpg";

import axios from "axios";
const AcrList = () => {
  const { state } = useLocation();

  const employeeid = state.employeeid;
  const employeename = state.employeename;
  const employeedesignation = state.employeedesignation;
  const employeephoto = state.employeephoto;
  const employeetype = state.employeetype;
  const unitname = state.unitname;
  const [acrList, setAcrList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getEmployeeAcrList = async (employeeid) => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeAcrList`,
      {
        params: {
          employeeid,
        },
      }
    );
    setAcrList(result.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getEmployeeAcrList(employeeid);
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between">
          <span className="font-medium text-2xl text-gray-700">ACR</span>
          <Link
            to={{
              pathname: "/highermanagement/employeedetails",
              state: {
                employeeid: employeeid,
                unitname: unitname,
              },
            }}
          >
            <button className="bg-gray-900 text-white py-2 px-4 rounded-md focus:outline-none rounded-sm shadow-md flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Back to Employee List </span>
            </button>
          </Link>
        </div>
        <div className="grid grid-cols-5 mt-9 gap-4">
          <div className="col-span-1 bg-white py-4 px-4 shadow rounded-md">
            <div className="rounded-md">
              <img
                src={
                  employeephoto === "" || employeephoto === null
                    ? nouser
                    : employeephoto
                }
                className="mx-auto w-full rounded-md mb-3"
              />
              <div className="flex justify-between">
                <div>
                  <p className="font-medium">{employeename}</p>
                  <p className="text-gray-500 text-sm">{employeedesignation}</p>
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold bg-green-100 text-green-800">
                    {employeetype}
                  </span>
                </div>
              </div>
            </div>{" "}
          </div>
          {isLoading ? (
            <Loader message={"fetching ACR list...."} />
          ) : (
            <Fragment>
              <div className="col-span-4">
                <div class="flex flex-col px-1">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div class="shadow overflow-hidden sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Review Period from
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Review Period to
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white divide-y divide-gray-200">
                            {acrList && acrList.length > 0 ? (
                              acrList.map((x, index) => (
                                <TableData
                                  key={index}
                                  item={x}
                                  employeename={employeename}
                                  employeedesignation={employeedesignation}
                                  employeephoto={employeephoto}
                                  employeetype={employeetype}
                                />
                              ))
                            ) : (
                              <tr className="text-center text-gray-700">
                                <td className="py-2 text-sm">
                                  No ACRs added as of now.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const TableData = ({
  item,
  employeename,
  employeedesignation,
  employeephoto,
  employeetype,
}) => {
  console.log(item);
  return (
    <tr>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getFormattedDateInputDate(item.fromdate)}
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getFormattedDateInputDate(item.todate)}
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <Link
          to={{
            pathname: "/highermanagement/acrdetails",
            state: {
              acrid: item.acrid,
              employeeid: item.employeeid,
              employeename: employeename,
              employeedesignation: employeedesignation,
              employeephoto: employeephoto,
              employeetype: employeetype,
            },
          }}
        >
          <p className="text-indigo-600 hover:indigo-900">View</p>
        </Link>
      </td>
    </tr>
  );
};

export default AcrList;

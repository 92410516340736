let getFirstAlphabets = (str) => {
  var matches = str.match(/\b(\w)/g);
  var acronym = matches.join("");
  return acronym;
};

const monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getFormattedDateInputDate = (date) => {
  let dd, mm, yy;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  //   mm = mm + 1;
  //   if (mm < 10) {
  //     mm = "0" + mm;
  //   }

  return `${dd} ${monthsArr[mm]} ${yy}`;
};

const getFormattedDateForMinMax = (date) => {
  let dd, mm, yy;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  mm = mm + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${yy}-${mm}-${dd}`;
};

const generateRandomAlphaNumericString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

module.exports = {
  getFirstAlphabets,
  getFormattedDateInputDate,
  getFormattedDateForMinMax,
  generateRandomAlphaNumericString,
};

import React from "react";
import axios from "axios";
import noUser from "../../../assets/noUser.jpg";

const EmployeeList = ({
  employeeList,
  setEmpPersonalDetailsObj,
  setEmpOfficialDetailsObj,
  setEmpCurrentOfficialDetailsObj,
  setEmpNextProgressionDetails,
  setEmpCareerTimelineList,
  setIsLoading,
  setIsList,
  setNextProgressionDate,
}) => {
  const handleViewDetails = async (e) => {
    setIsLoading(true);
    setIsList(false);
    const idToView = e.currentTarget.id;
    let result1 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeePerosonalDetailsById`,
      {
        params: {
          employeeId: idToView,
        },
      }
    );

    if (result1 !== null && result1 !== "") {
      setEmpPersonalDetailsObj(result1.data.rows[0]);
      let result2 = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeOfficialDetailsById`,
        {
          params: {
            employeeId: idToView,
          },
        }
      );

      if (result2 !== null && result2 !== "") {
        setEmpOfficialDetailsObj(result2.data.rows[0]);
        let result3 = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCurrentOfficialDetails`,
          {
            params: {
              employeeId: idToView,
            },
          }
        );
        if (result3 !== null && result3 !== "") {
          setEmpCurrentOfficialDetailsObj(result3.data);

          if (
            result3.data.emptypename.toLowerCase().includes("regular employee")
          ) {
            let result_regular = await axios.get(
              `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeNextProgressionDetails_regular`,
              {
                params: {
                  empCurrentLevelId: result3.data.emplevelid,
                },
              }
            );

            if (result_regular !== null && result_regular !== "") {
              setEmpNextProgressionDetails(result_regular.data);

              //set Next Promotion Date
              const datePromotedToCurrentStatus = new Date(
                result3.data.promotiondate
              );
              const nextPromotionDate = new Date(datePromotedToCurrentStatus);

              nextPromotionDate.setFullYear(
                parseInt(
                  datePromotedToCurrentStatus.getFullYear() +
                    parseInt(result_regular.data.noofyears)
                )
              );
              setNextProgressionDate(nextPromotionDate);
            }
          } else {
            let result_non_regular = await axios.get(
              `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeNextProgressionDetails`,
              {
                params: {
                  empCurrentEmpTypeId: result3.data.emptypeid,
                },
              }
            );
            if (result_non_regular !== null && result_non_regular !== "") {
              setEmpNextProgressionDetails(result_non_regular.data);
              //set Next Promotion Date
              const datePromotedToCurrentStatus = new Date(
                result3.data.promotiondate
              );
              const nextPromotionDate = new Date(datePromotedToCurrentStatus);

              nextPromotionDate.setFullYear(
                parseInt(
                  datePromotedToCurrentStatus.getFullYear() +
                    parseInt(result_non_regular.data.noofyears)
                )
              );
              setNextProgressionDate(nextPromotionDate);
            }
          }

          let result5 = await axios.get(
            `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCareerTimelineList`,
            {
              params: {
                employeeId: idToView,
              },
            }
          );
          if (result5 !== null && result5 !== "") {
            setEmpCareerTimelineList(result5.data);
          } else {
            console.log("ERROR : error fetching employee career timeline list");
          }
        } else {
          console.log(
            "ERROR : error fetching employee current official details"
          );
        }
      } else {
        console.log("ERROR : error fetching employee official details");
      }
    } else {
      console.log("ERROR : error fetching employee personal details");
    }
    setIsLoading(false);
  };
  return (
    <div className="bg-white rounded-lg w-full">
      {employeeList && employeeList.length > 0 ? (
        <div className="text-left">
          <div className="p-2 shadow-xs rounded-md mb-2 text-gray-600 grid grid-cols-8 font-medium border bg-blue-200 text-base">
            <div className="col-span-1">
              <p>#</p>
            </div>
            <div className="col-span-2">
              <p>Name</p>
            </div>
            <div className="col-span-2">
              <p>Designation</p>
            </div>
            <div className="col-span-2">
              <p>Employee type</p>
            </div>

            <div className="col-span-1">
              <p>Action</p>
            </div>
          </div>
          {employeeList.map((x) => {
            return (
              <div className="px-2 py-3 bg-white w-full text-sm rounded-md mb-1 text-gray-700 grid grid-cols-8 flex items-center border">
                <div className="pr-2 col-span-1">
                  {x.employeephoto ? (
                    <img
                      src={x.employeephoto}
                      className="w-16 h-16 rounded-full"
                      alt="user"
                    ></img>
                  ) : (
                    <img
                      src={noUser}
                      className="w-16 h-16 rounded-full"
                      alt="nouser"
                    ></img>
                  )}
                </div>
                <div className="col-span-2">
                  <p>{x.employeename}</p>
                </div>
                <div className="col-span-2">
                  <p>{x.empdesignationname} </p>
                </div>

                <div className="col-span-2">
                  <p>{x.emptypename}</p>
                </div>

                <div className="col-span-1">
                  <button
                    className="border rounded-md py-1 px-2 focus:outline-none"
                    onClick={handleViewDetails}
                    id={x.employeeid}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>No employees added as of now.</div>
      )}
    </div>
  );
};

export default EmployeeList;

import axios from "axios";
import React, { useState } from "react";
import nouser from "../../../assets/noUser.jpg";
import { useToasts } from "react-toast-notifications";
export default function AcrConformationModal({
  showModal,
  setShowModal,
  empList,
  setEmpList,
  handleCancel,
  fromMonth,
  toMonth,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useToasts();
  const removeEmployee = (empid) => {
    const arr = empList.map((x) => {
      if (x.employeeid === empid) {
        return { ...x, isselected: false };
      } else {
        return x;
      }
    });
    setEmpList(arr);
  };

  const handelSubmit = async () => {
    const emplist = empList.filter((x) => x.isselected === true);
    const empData = {
      fromMonth: fromMonth + "-01",
      toMonth: toMonth + "-01",
      empList: emplist,
      createdby: localStorage.getItem("role"),
    };
    if (emplist.length > 0) {
      setIsSubmitting(true);
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/forward_acr_to_sectionhead`,
          {
            data: empData,
          }
        );

        addToast("Employees has been successfully forwarded !", {
          appearance: "success",
        });
        setIsSubmitting(false);
        setShowModal(false);
        handleCancel();
      } catch (error) {
        addToast("Could not forward !", {
          appearance: "error",
        });
      }
    } else {
      alert("Need to add at least one employee !");
    }
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center text-left flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl font-bold border-primary text-gray-700">
                    Selected Employees
                  </h3>
                  <p className="text-sm text-gray-500 pt-1">
                    ACR From <span className="font-bold">{fromMonth}</span> to
                    <span className="font-bold">{toMonth}</span>
                  </p>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto h-full max-h-3xl">
                  {empList
                    ?.filter((x) => x.isselected === true)
                    .map((emp, index) => (
                      <div
                        className="flex items-center justify-between border-b"
                        key={index}
                      >
                        <div className="flex items-center">
                          <div class="flex-shrink-0 h-12 w-12">
                            {emp.employeephoto ? (
                              <img
                                class="h-12 w-12 rounded-full"
                                src={emp.employeephoto}
                                alt="user"
                              />
                            ) : (
                              <img
                                class="h-12 w-12 rounded-full"
                                src={nouser}
                                alt="nouser"
                              />
                            )}
                          </div>
                          <div class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                              {emp.employeename}
                            </div>
                            <div class="text-sm text-gray-500">
                              {emp.empdesignationname}
                            </div>
                          </div>
                          <div class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                              {emp.empsectionname}
                            </div>
                            <div class="text-sm text-gray-500">
                              {emp.emptypename}
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            className="text-sky-blue border border-gray-300 shadow py-2 px-3 text-xs rounded-md mr-4"
                            onClick={() => removeEmployee(emp.employeeid)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  <p className="pt-4 text-sky-blue-dark font-medium">
                    Selected employees will be forwarded to their respective
                    section head for ACR.
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end py-4 border-t border-solid border-blueGray-200 rounded-b px-5">
                  <button
                    className="text-sky-blue border border-gray-300 shadow py-3 px-4 text-sm rounded-md mr-4"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  {isSubmitting ? (
                    <button
                      type="button"
                      class="inline-flex items-center px-4 py-3 text-sm shadow rounded-md text-white bg-sky-blue-dark transition ease-in-out duration-150 cursor-not-allowed"
                      disabled=""
                    >
                      <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </button>
                  ) : (
                    <button
                      className="bg-sky-blue text-white shadow py-3 border border-sky-blue px-4 text-sm rounded-md"
                      onClick={handelSubmit}
                    >
                      Proceed
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";

const AddLevel = ({
  handleBackToList,
  actionType,
  levelObj,
  designationList,
}) => {
  const { register, handleSubmit, errors, reset, formState } = useForm();

  const levelNoList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [designationId, setDesignationId] = useState();
  const [levelNo, setLevelNo] = useState();
  const onSubmit = async (data) => {
    if (actionType === "add") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addLevel`,
        {
          data: {
            levelNo: data.levelNo,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("addlevel");
      }
    } else if (actionType == "edit") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/editLevelDetails`,
        {
          data: {
            levelId: levelObj.levelId,
            levelNo: data.levelNo,
          },
        }
      );

      if (result !== null && result !== "") {
        handleBackToList("editlevel");
      }
    }
  };
  const handleDesignationChange = (e) => {
    setDesignationId(e.target.value);
  };

  const handleLevelNoChange = (e) => {
    setLevelNo(e.target.value);
  };

  const handleCancel = () => {
    handleBackToList();
  };
  return (
    <Fragment>
      {actionType === "add" ? (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">Add Level </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            {/* <p>Designation</p>
            <select
              name="designationDropdown"
              ref={register({ required: false })}
              onChange={handleDesignationChange}
              className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                errors.designationDropdown ? "border-red-700" : ""
              }`}
              value={designationId}
            >
              <option value="">--select designation-- </option>
              {designationList &&
                designationList.map((x) => {
                  return (
                    <option value={x.designationid.toString()}>
                      {x.designationname}
                    </option>
                  );
                })}
            </select>
            {errors.designationDropdown && (
              <Error message="Designation is required" />
            )} */}
            <p className="mt-3">Level number :</p>
            <select
              ref={register({ required: true })}
              name="levelNo"
              onChange={handleLevelNoChange}
              className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                errors.levelNo ? "border-red-700" : ""
              }`}
              value={levelNo}
            >
              <option value="">--select Level number--</option>
              {levelNoList &&
                levelNoList.map((x) => {
                  return <option value={x}>Level {x}</option>;
                })}
            </select>
            {errors.levelNo && <Error message="Level number is required" />}
            {/* <p className="mt-3">Level name :</p>
            <input
              name="levelName"
              type="text"
              className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                errors.levelName ? "border-red-700" : ""
              }`}
              ref={register({ required: false })}
            />
            {errors.levelName && <Error message="Level name is required" />}
            <br></br> */}
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">Edit Level</p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            {/* <p>Designation</p>
            <select
              name="designationDropdown"
              ref={register({ required: true })}
              onChange={handleDesignationChange}
              className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                errors.designationDropdown ? "border-red-700" : ""
              }`}
              defaultValue={levelObj.designationId}
              value={designationId}
              disabled="true"
            >
              <option value="">--select designation-- </option>
              {designationList &&
                designationList.map((x) => {
                  return (
                    <option value={x.designationid.toString()}>
                      {x.designationname}
                    </option>
                  );
                })}
            </select>
            {errors.designationDropdown && (
              <Error message="Designation is required" />
            )} */}
            <p className="mt-3">Level number :</p>
            <select
              ref={register({ required: true })}
              name="levelNo"
              onChange={handleLevelNoChange}
              className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                errors.levelNo ? "border-red-700" : ""
              }`}
              value={levelNo}
              defaultValue={levelObj.levelNo}
            >
              <option value="">--select Level number--</option>
              {levelNoList &&
                levelNoList.map((x) => {
                  return <option value={x}>Level {x}</option>;
                })}
            </select>
            {/* {errors.levelNo && <Error message="Level number is required" />}
            <p className="mt-3">Level name :</p>
            <input
              name="levelName"
              type="text"
              className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                errors.levelName ? "border-red-700" : ""
              }`}
              ref={register({ required: false })}
              defaultValue={levelObj.levelName}
            />
            {errors.levelName && <Error message="Level name is required" />}
            <br></br> */}
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default AddLevel;

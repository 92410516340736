import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import nouser from "../../../assets/noUser.jpg";
import Error from "../../../utilities/Error";
import { ToastProvider, useToasts } from "react-toast-notifications";

export default function AddLeaveType({
  isVisible,
  setShowModal,
  selectedLeaveType,
  getLeaveTypeList,
  modalHeading,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      let result;
      if (modalHeading === "Add") {
        result = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/addLeaveTypeDetails`,
          {
            data: {
              leavetype: data.leavetype,
              createdby: localStorage.getItem("username"),
            },
          }
        );
      } else {
        result = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/updateLeaveTypeDetails`,
          {
            data: {
              leavetypeid: selectedLeaveType?.leavetypeid,
              leavetype: data.leavetype,
              updatedby: localStorage.getItem("username"),
            },
          }
        );
      }

      if (result.data.status === 200) {
        getLeaveTypeList();
        reset();
        if (modalHeading === "Add") {
          addToast("Leave type details successfully added.", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast("Leave type details successfully updated.", {
            appearance: "success",
            autoDismiss: true,
          });
        }
      } else {
        if (result.data.message.includes("unique_name")) {
          addToast("Cannot insert duplicate leave type !", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast("Something went wrong !", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      setShowModal(false);
      setIsSubmitting(false);
    } catch (error) {
      addToast("Something went wrong !", {
        appearance: "error",
        autoDismiss: true,
      });
      setIsSubmitting(false);
    }
  };
  return (
    <div>
      <ToastProvider autoDismiss={true} placement="top-center"></ToastProvider>
      {isVisible && (
        <div
          className="fixed z-20 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <div className="flex items-center">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-green-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                        </div>
                        <h3
                          className="text-lg leading-6 font-medium text-gray-900 pl-4"
                          id="modal-title"
                        >
                          {modalHeading} Leave Type
                        </h3>
                      </div>
                      <div className="mt-6">
                        <p className="mt-4 font-medium pb-1">Leave type name</p>
                        <input
                          name="leavetype"
                          placeholder="Enter leave type name here"
                          className={`border border-gray-300 rounded-lg w-full p-3 text-sm outline-none resize-none ${
                            errors.leavetype ? "border-red-600" : ""
                          }`}
                          ref={register({ required: true })}
                          defaultValue={selectedLeaveType?.leavetypename}
                        />
                        {errors.leavetype && (
                          <Error message="Leave type name is required" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={isSubmitting ? true : false}
                  >
                    {isSubmitting ? (
                      <Fragment>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Submitting</span>
                      </Fragment>
                    ) : (
                      modalHeading
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import Loader from "../../../utilities/Loader";
import GeneralDeleteModal from "../../../version2/ReusableComponents/GeneralDeleteModal";
import AddLeaveType from "./AddLeaveType";

export default function LeaveTypeList({
  setAddLeaveTypeVisible,
  leaveTypeList,
  setLeaveTypeList,
}) {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState();
  const [modalHeading, setModalHeading] = useState("");

  const handleEdit = (leavetypedetails) => {
    setShowModal(true);
    setSelectedLeaveType(leavetypedetails);
    setModalHeading("Update");
  };
  const handleDelete = (leavetypedetails) => {
    setShowDeleteModal(true);
    setSelectedLeaveType(leavetypedetails);
  };
  const onDelete = async () => {
    try {
      setIsLoading(true);
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteLeaveTypeDetails`,
        {
          data: {
            leavetypeid: selectedLeaveType?.leavetypeid,
          },
        }
      );
      if (result.data.status === 200) {
        getLeaveTypeList();
        addToast("Leave type details successfully deleted.", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        if (result.data.message.includes("foreign key")) {
          addToast("Cannot delete. Leave type has configurations !", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast("Something went wrong !", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      setShowDeleteModal(false);
      setIsLoading(false);
    } catch (error) {
      addToast("Something went wrong asdsa!", {
        appearance: "error",
        autoDismiss: true,
      });
      setIsLoading(false);
      setShowDeleteModal(false);
    }
  };
  const handleLeaveTypeAdd = () => {
    setShowModal(true);
    setModalHeading("Add");
  };
  const handleBackToList = () => {
    setAddLeaveTypeVisible(false);
  };
  const getLeaveTypeList = async () => {
    try {
      setIsLoading(true);
      let result;
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getLeaveTypeList`
      );
      setLeaveTypeList(result.data);
    } catch (error) {
      console.log("Error", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getLeaveTypeList();
  }, []);
  return (
    <div className="">
      <ToastProvider autoDismiss={true} placement="top-center"></ToastProvider>
      <div className="flex justify-between items-center">
        <span className="font-medium text-xl">Leave Type List</span>
        <button
          className="bg-gray-900 px-3 py-2 text-white focus:outline-none rounded-md flex items-center shadow-lg text-sm font-medium"
          onClick={handleBackToList}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span>Back to Leave Type Configuration</span>
        </button>
      </div>
      <AddLeaveType
        isVisible={showModal}
        setShowModal={setShowModal}
        selectedLeaveType={selectedLeaveType}
        getLeaveTypeList={getLeaveTypeList}
        modalHeading={modalHeading}
      />
      <GeneralDeleteModal
        type="Leave Type"
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        excuteFunction={onDelete}
      />
      <button
        className="text-sky-blue focus:outline-none rounded-md flex items-center text-sm font-medium mt-6"
        onClick={handleLeaveTypeAdd}
      >
        <span>Add Leave Type</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 ml-2"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isLoading ? (
        <Loader message="Fetching section list." />
      ) : (
        <div className="pb-6 pt-4">
          {leaveTypeList && leaveTypeList.length > 0 ? (
            <div className="flex flex-col px-1">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 text-sm">
                      <thead className="bg-gray-50 text-gray-700 ">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                          >
                            Leave Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-right font-medium uppercase tracking-wider"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {leaveTypeList.map((leave, index) => (
                          <tr key={index}>
                            <td className="px-6 py-2 whitespace-nowrap">
                              {leave.leavetypename}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap flex items-center justify-end">
                              <div onClick={() => handleEdit(leave)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5  cursor-pointer"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                              </div>
                              <div onClick={() => handleDelete(leave)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6 ml-4 cursor-pointer"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-6 bg-white text-center font-medium">
              No leave types list found !
            </div>
          )}
        </div>
      )}
    </div>
  );
}

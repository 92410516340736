import React, { Fragment, useContext, useState } from "react";
import AddSection from "../section-components/AddSection";
import SectionList from "../section-components/SectionList";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DeleteModal from "../../../utilities/DeleteModal";

const SectionConfiguration = () => {
  const [sectionList, setSectionList] = useState();
  const [isSectionList, setIsSectionList] = useState(true);
  const [isSectionAdd, setIsSectionAdd] = useState(false);
  const [sectionObj, setSectionObj] = useState({
    sectionid: "",
    sectionname: "",
  });
  const [deleteSectionObj, setDeleteSectionObj] = useState({
    sectionid: "",
  });
  const [actionType, setActionType] = useState("");
  const { addToast } = useToasts();

  const handleSectionAdd = () => {
    setIsSectionAdd(true);
    setIsSectionList(false);
    setActionType("add");
    console.log("ADD CLICK : ", actionType);
  };

  const handleSectionEdit = (e) => {
    setIsSectionAdd(true);
    setIsSectionList(false);
    setActionType("edit");
  };
  const handleBackToList = (calledFrom, deletedId) => {
    setIsSectionAdd(false);
    setIsSectionList(true);
    setActionType("");
    if (calledFrom === "addsection") {
      addToast("Section successfully added.", {
        appearance: "success",
      });
    }
    if (calledFrom === "editsection") {
      addToast("Section successfully updated.", {
        appearance: "success",
      });
    }
    if (calledFrom === "deletesection") {
      let newSectionList = sectionList.filter(function removeSection(x) {
        return parseInt(x.sectionid) !== parseInt(deletedId);
      });
      setSectionList(newSectionList);
      addToast("Section successfully deleted.", {
        appearance: "success",
      });
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <span className="font-medium text-xl">Section Configuration</span>
        {isSectionList ? (
          <div>
            <button
              className="bg-sky-blue px-3 py-2 text-white focus:outline-none rounded-md flex items-center shadow-lg text-sm font-medium"
              onClick={handleSectionAdd}
            >
              <span>Add Section</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div>
            <button
              className="bg-gray-900 px-3 py-2 text-white focus:outline-none rounded-md flex items-center shadow-lg text-sm font-medium"
              onClick={handleBackToList}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Back to List</span>
            </button>
          </div>
        )}
      </div>
      <div className="rounded-lg">
        {isSectionAdd && (
          <div>
            <ToastProvider autoDismiss={true} placement="top-center">
              <div className="flex justify-center">
                <AddSection
                  handleBackToList={handleBackToList}
                  actionType={actionType}
                  sectionObj={sectionObj}
                />
              </div>
            </ToastProvider>
          </div>
        )}
        {isSectionList && (
          <div>
            <DeleteModal
              id={deleteSectionObj.sectionid}
              type="section"
              handleBackToList={handleBackToList}
            />
            <SectionList
              handleSectionEdit={handleSectionEdit}
              setSectionObj={setSectionObj}
              setDeleteSectionObj={setDeleteSectionObj}
              sectionList={sectionList}
              setSectionList={setSectionList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionConfiguration;

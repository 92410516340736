import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import nouser from "../../../assets/noUser.jpg";

export default function PromotionPendingList() {
  const { state } = useLocation();
  const goBackPath = state.frompath;
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPromotionEmpList, setPendingPromotionEmpList] = useState([]);
  const getEmployeeList = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getPromotionPendingEmpList`
      );
      //  setEmpList(result.data);
      console.log("Result", result.data);
      setPendingPromotionEmpList(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <div className="p-6">
      <Link to={goBackPath}>
        <div className="flex items-center bg-black text-white w-28 justify-center py-1 rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <p className="text-sm">Go Back</p>
        </div>
      </Link>
      <p className="font-medium text-lg pl-1 my-4">Promotion Pending List</p>

      <div class="flex flex-col px-1">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Current Employee Type
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Next Employee Type
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Promotion Date
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  {pendingPromotionEmpList?.length > 0 && (
                    <>
                      {pendingPromotionEmpList.map((promotion) => (
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                              <div class="flex-shrink-0 h-12 w-12">
                                {promotion.employeephoto ? (
                                  <img
                                    class="h-12 w-12 rounded-full"
                                    src={promotion.employeephoto}
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    class="h-12 w-12 rounded-full"
                                    src={nouser}
                                    alt="nouser"
                                  />
                                )}
                              </div>
                              <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                  {promotion.employeename}
                                  {/* {!item.has_coi && (
                                    <p className="text-xs bg-yellow-500 w-min mb-0 px-1 rounded-lg text-white">
                                      No COI
                                    </p>
                                  )} */}
                                </div>
                                <div class="text-sm text-gray-700">
                                  {promotion.employeecontact}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                              {promotion.empcurrentemptypename}
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                              {promotion.toemployeetypename}
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                              {promotion.promotiondate}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

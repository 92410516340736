import React, { Fragment, useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { LoginContext } from "../context/LoginContext";
import { isEmpty } from "../utilities/IsEmpty";
import LoginForm from "./LoginForm";
import SystemAdminHome from "../version2/SystemAdmin/SystemAdminHome";
import SectionHeadHome from "../sectionHead-components/pages/SectionHeadHome";
import HigherManagementHome from "../version2/HigherManagement/HigherManagementHome";

export default function LoginComponent() {
  const { loginState, dispatchLogin } = useContext(LoginContext);
  useEffect(() => {
    if (localStorage.getItem("username") !== null) {
      var loggedInUser = {
        username: localStorage.getItem("userid"),
        usertype: localStorage.getItem("role"),
      };
      dispatchLogin({
        type: "SET_LOGGEDINUSER",
        payload: loggedInUser,
      });
    }
  }, []);

  return (
    <div className="h-screen bg-white flex justify-center items-center space-x-4">
      {localStorage.getItem("role") === null ? (
        <Fragment>
          <LoginForm />
        </Fragment>
      ) : localStorage.getItem("role") === "System Admin" ? (
        <Redirect to="admin/systemadmindashboard" exact />
      ) : localStorage.getItem("role") === "Higher Management" ? (
        <Redirect to="highermanagement/highermanagementdashboard" exact />
      ) : localStorage.getItem("role") === "Section Head" ? (
        <Redirect to="/sectionhead/sectionheaddashboard" exact />
      ) : localStorage.getItem("role") === "Accounts" ? (
        <Redirect to="/accounts/accountsdashboard" exact />
      ) : (
        ""
      )}
    </div>

    // OLD VERSION
    //   <div className="h-screen bg-white flex justify-center items-center space-x-4">
    //   {localStorage.getItem("role") === null ? (
    //     <Fragment>
    //       <LoginForm />
    //     </Fragment>
    //   ) : localStorage.getItem("role") === "System Admin" ? (
    //     <Redirect
    //       to="/systemadmindashboard"
    //       exact
    //       component={SystemAdminHome}
    //     />
    //   ) : localStorage.getItem("role") === "Higher Management" ? (
    //     <Redirect
    //       to="/controllingofficerdashboard"
    //       exact
    //       component={ControllingOfficerHome}
    //     />
    //   ) : localStorage.getItem("role") === "Section Head" ? (
    //     <Redirect
    //       to="/sectionhead/sectionheaddashboard"
    //       exact
    //       component={SectionHeadHome}
    //     />
    //   ) : (
    //     ""
    //   )}
    // </div>
  );
}

import axios from "axios";
import React, { Fragment, useState } from "react";
import { getFormattedDateInputDate } from "../../../../utilities/getFormattedDateInputDate";
import DeleteModal from "../../../Components/DeleteModal";
import { ToastProvider, useToasts } from "react-toast-notifications";

export default function ProgressionBox({
  empDetails,
  setEmpProgressionHistory,
  empProgressionHistory,
  setEmployeeYearList,
  empid,
  handleEditClick,
}) {
  const [showDelete, setShowDelete] = useState(false);
  const { addToast } = useToasts();
  const handleDelete = async () => {
    if (empDetails.progressiontype === "Increment") {
      const resultIncrement = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteIncrement`,
        {
          data: {
            incrementid: empDetails.id,
          },
        }
      );
    } else if (empDetails.progressiontype === "Suspension") {
      const resultSuspension = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteSuspension`,
        {
          data: {
            suspensionid: empDetails.id,
          },
        }
      );
    } else {
      const resultProgression = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteProgressionHistory`,
        {
          data: {
            progressionid: empDetails.id,
          },
        }
      );
    }
    const result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getemployeeyearsbyid`,
      { params: { empid: empid } }
    );
    console.log("Result", result.data);
    setEmployeeYearList(() => result.data);

    const newList = empProgressionHistory.filter((x) => x.id !== empDetails.id);
    setEmpProgressionHistory(newList);
    setShowDelete(false);
    addToast("successfully deleted.", {
      appearance: "success",
    });
  };
  return (
    <div
      className={`p-2 rounded border text-sm relative ${
        empDetails.progressiontype === "Current status"
          ? "bg-green-300"
          : "bg-white"
      }`}
    >
      <ToastProvider autoDismiss={true} placement="top-center"></ToastProvider>
      <DeleteModal
        open={showDelete}
        setOpen={setShowDelete}
        message="Are you sure you want to delete ?"
        onDelete={handleDelete}
      />
      <p className="text-gray-700 font-medium">
        {empDetails.progressiontype === "Designation promotion" ? (
          <Fragment>
            Promoted to
            <span className="font-bold italic text-gray-800 mx-2">
              {empDetails.designationname}
            </span>
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Level promotion" ? (
          <Fragment>
            Promoted to
            <span className="font-bold italic text-gray-800 mx-2">
              Level - {empDetails.levelnumber}
            </span>{" "}
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Unit Transfer" ? (
          <Fragment>
            Transferred to
            <span className="font-bold italic text-gray-800 mx-2">
              {empDetails.unitname} unit
            </span>{" "}
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Upgradation" ? (
          <Fragment>
            Upgraded to
            <span className="font-bold italic text-gray-800 mx-2">
              {empDetails.emptypename}
            </span>{" "}
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Redesignation" ? (
          <Fragment>
            Redesignated to
            <span className="font-bold italic text-gray-800 mx-2">
              {empDetails.designationname}
            </span>{" "}
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Section Change" ? (
          <Fragment>
            Shifted to section -
            <span className="font-bold italic text-gray-800 mx-2">
              {empDetails.sectionname}
            </span>{" "}
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Promotion" ? (
          <Fragment>
            Promoted to
            <span className="font-bold italic text-gray-800 mx-2">
              {empDetails.designationname}
              {"\u00A0"}
              {empDetails.levelid !== null && empDetails.levelid !== "" ? (
                <span className="text-gray-500 not-italic">
                  (Level - {empDetails.levelnumber})
                </span>
              ) : (
                ""
              )}
            </span>{" "}
            in<b> {empDetails.emptypename} </b>basis.
          </Fragment>
        ) : empDetails.progressiontype === "Increment" ? (
          <Fragment>
            <span className="font-bold italic">Salary Increment</span>{" "}
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Suspension" ? (
          <Fragment>
            <span className="font-bold italic">Suspended</span> &nbsp;form
            &nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.datefrom)}
            </span>
            &nbsp;&nbsp;to&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.dateto)}
            </span>
          </Fragment>
        ) : empDetails.progressiontype === "Current status" ? (
          <Fragment>
            Currently designated as{" "}
            <span className="font-bold italic text-gray-800">
              {empDetails.designationname}
            </span>
            &nbsp;
            <span className="text-gray-800">
              {empDetails.levelnumber !== null &&
                `Level - (${empDetails.levelnumber})`}
            </span>
            &nbsp;&nbsp;on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {empDetails.emptypename}
            </span>{" "}
            basis
          </Fragment>
        ) : empDetails.progressiontype === "Appoinment" ? (
          <Fragment>
            Appointed as{" "}
            <span className="font-bold italic text-gray-800">
              {empDetails.designationname}
            </span>{" "}
            on&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {getFormattedDateInputDate(empDetails.progressiondate)}
            </span>{" "}
            &nbsp;in&nbsp;&nbsp;
            <span className="font-bold italic text-gray-800">
              {empDetails.emptypename}
            </span>{" "}
            basis.
          </Fragment>
        ) : (
          ""
        )}
      </p>
      {empDetails.remarks && (
        <p className="p-2 bg-blue-100 text-blue-500 mt-2">
          <i>{empDetails.remarks}</i>
        </p>
      )}
      {empDetails.progressiontype === "Current status" && (
        <div className="p-2 bg-green-500 text-white rounded-t-md absolute -top-8 left-0">
          <p className="text-xs">Current Status</p>
        </div>
      )}

      {empDetails.progressiontype !== "Current status" &&
      empDetails.progressiontype !== "Appoinment" ? (
        <div className="pt-2 flex justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-600 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => handleEditClick(empDetails)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 ml-3 text-gray-600 cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => setShowDelete(true)}
          >
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

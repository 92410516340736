import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../utilities/Loader";
import nouser from "../../../assets/noUser.jpg";
import AcrConformationModal from "./AcrConformationModal";
import { ToastProvider } from "react-toast-notifications";
import EmployeeDetailsModal from "./EmployeeDetailsModal";

const EmployeeListJorethang = () => {
  const [empList, setEmpList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [viewCheckbox, setViewCheckbox] = useState(false);
  const [viewMonthSelect, setViewMonthSelect] = useState(false);
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState();
  const getEmployeeList = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeListWithCurrentDetailsJorethang`
    );
    const newArray = [];
    for (var i = 0; i < result.data.length; i++) {
      newArray.push({
        empdesignationname: result.data[i].empdesignationname,
        employeeid: result.data[i].employeeid,
        employeename: result.data[i].employeename,
        employeephoto: result.data[i].employeephoto,
        empsectionname: result.data[i].empsectionname,
        emptypename: result.data[i].emptypename,
        empunitname: result.data[i].empunitname,
        isselected: false,
      });
    }
    setEmpList(newArray);
    setIsLoading(false);
  };
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  // const handleMonthChange = (e) => {
  //   setSelectedMonth(e.target.value);
  //   if (e.target.value !== "") {
  //     setViewCheckbox(true);
  //   } else {
  //     setViewCheckbox(false);
  //   }
  // };
  const handelCheck = (e) => {
    const arr = empList.map((x) => {
      if (x.employeeid === e.target.value) {
        return { ...x, isselected: e.target.checked };
      } else {
        return x;
      }
    });
    setEmpList(arr);
  };
  const handleCancel = () => {
    setViewMonthSelect(false);
    setViewCheckbox(false);
    const arr = empList.map((x) => {
      if (x.isselected) {
        return { ...x, isselected: false };
      } else {
        return x;
      }
    });
    setEmpList(arr);
  };
  const handleFromMonthChange = (e) => {
    setFromMonth(e.target.value);
    if (e.target.value !== "" && toMonth !== "") {
      setViewCheckbox(true);
    } else {
      setViewCheckbox(false);
    }
  };
  const handleToMonthChange = (e) => {
    setToMonth(e.target.value);
    if (e.target.value !== "" && fromMonth !== "") {
      setViewCheckbox(true);
    } else {
      setViewCheckbox(false);
    }
  };
  const showDetails = (empid) => {
    const empDetails = empList.filter((x) => x.employeeid === empid);
    setEmployeeDetails(empDetails);
    setShowDetailsModal(true);
  };
  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between pl-3">
          <span className="font-medium text-2xl text-gray-700 pl-1">
            Jorethang Employees
          </span>
        </div>
        <div className="rounded-lg p-3">
          {isLoading ? (
            <Loader message={"fetching employees...."} />
          ) : (
            <Fragment>
              <div className="flex items-center justify-between py-4 sticky top-14 z-99 bg-gray-200 px-1">
                <div class="flex items-center text-sm w-96 rounded-lg border border-gray-300 bg-gray-900">
                  <input
                    class="rounded-l-md w-full px-4 py-3 text-gray-800 focus:outline-none"
                    id="search"
                    type="text"
                    placeholder="Search employee name"
                    onChange={handleSearchTextChange}
                    value={searchText}
                    autoComplete="off"
                    autoFocus
                  ></input>
                  <div class="py-2 px-3 bg-gray-900">
                    {!searchText ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-white cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-white font-bold cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={() => {
                          setSearchText("");
                        }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <div>
                  {viewMonthSelect ? (
                    <div className="flex items-center">
                      {/* <select
                        className="px-4 py-2 border border-gray-300 focus:outline-none w-full mr-4 bg-white text-gray-700"
                        onChange={(e) => handleMonthChange(e)}
                      >
                        <option value="">Please select month</option>
                        <option value="January">January</option>
                        <option value="June">June</option>
                      </select> */}
                      <div className="flex items-center">
                        <p>From</p>
                        <input
                          type="month"
                          className="w-full mx-3 p-2 focus:outline-none"
                          onChange={(e) => handleFromMonthChange(e)}
                        />
                        <p>To</p>
                        <input
                          type="month"
                          className="w-full mx-3 p-2 focus:outline-none"
                          onChange={(e) => handleToMonthChange(e)}
                        />
                      </div>
                      {empList.filter((x) => x.isselected === true).length >
                      0 ? (
                        <button
                          className="bg-sky-blue text-white shadow py-3 border border-sky-blue px-4 text-sm rounded-md mr-4"
                          onClick={() => setShowModal(true)}
                        >
                          Proceed
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="text-sky-blue border border-gray-300 shadow py-3 px-4 text-sm rounded-md bg-white"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button
                      className="bg-sky-blue text-white shadow py-3 border border-sky-blue px-4 text-sm rounded-md"
                      onClick={() => setViewMonthSelect(true)}
                    >
                      Forward ACRs
                    </button>
                  )}
                </div>
              </div>
              <div class="flex flex-col px-1">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Designation
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Employee Type
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Unit
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">View</span>
                            </th>
                            {viewCheckbox && (
                              <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Select</span>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          {empList && empList.length > 0 ? (
                            <Fragment>
                              {searchText === "" ? (
                                <Fragment>
                                  {empList.map((emp, index) => (
                                    <TableData
                                      key={index}
                                      item={emp}
                                      viewCheckbox={viewCheckbox}
                                      handelCheck={handelCheck}
                                      showDetails={showDetails}
                                    />
                                  ))}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {empList
                                    .filter((x) =>
                                      x.employeename
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    )
                                    .map((emp, index) => (
                                      <TableData
                                        key={index}
                                        item={emp}
                                        viewCheckbox={viewCheckbox}
                                        handelCheck={handelCheck}
                                        showDetails={showDetails}
                                      />
                                    ))}
                                </Fragment>
                              )}
                            </Fragment>
                          ) : (
                            <div>No employess found !</div>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <ToastProvider>
        <AcrConformationModal
          showModal={showModal}
          setShowModal={setShowModal}
          empList={empList}
          setEmpList={setEmpList}
          handleCancel={handleCancel}
          fromMonth={fromMonth}
          toMonth={toMonth}
        />
      </ToastProvider>
      <EmployeeDetailsModal
        showModal={showDetailsModal}
        setShowModal={setShowDetailsModal}
        employeeDetails={employeeDetails}
      />
    </Fragment>
  );
};

const TableData = ({ item, viewCheckbox, handelCheck, showDetails }) => {
  return (
    <tr>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="flex items-center">
          <div class="flex-shrink-0 h-12 w-12">
            {item.employeephoto ? (
              <img
                class="h-12 w-12 rounded-full"
                src={item.employeephoto}
                alt="user"
              />
            ) : (
              <img class="h-12 w-12 rounded-full" src={nouser} alt="nouser" />
            )}
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {item.employeename}
            </div>
            {/* <div class="text-sm text-gray-500">jane.cooper@example.com</div> */}
          </div>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">{item.empdesignationname}</div>
        <div class="text-sm text-gray-500">{item.empsectionname}</div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {item.emptypename}
        </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {item.empunitname}
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <a
          href="#"
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => showDetails(item.employeeid)}
        >
          View
        </a>
      </td>
      {viewCheckbox && (
        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <input
            type="checkbox"
            onChange={(e) => handelCheck(e)}
            value={item.employeeid}
            checked={item.isselected}
          />
        </td>
      )}
    </tr>
  );
};

export default EmployeeListJorethang;

import axios from "axios";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { toggleDeleteModal } from "../modal-functions/ModalFunctions";

const DeleteModal = ({ id, type, previewName, handleBackToList }) => {
  const { handleSubmit, formState } = useForm();
  const onSubmit = async () => {
    if (type === "section") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteSection`,
        {
          data: {
            sectionid: id,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("deletesection", id);
      }
    } else if (type === "designation") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteDesignation`,
        {
          data: {
            designationId: id,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("deletedesignation", id);
      }
    } else if (type === "employeetype") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteEmployeeType`,
        {
          data: {
            employeeTypeId: id,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("deleteemptype", id);
      }
    } else if (type === "level") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteLevel`,
        {
          data: {
            levelId: id,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("deletelevel", id);
      }
    } else if (type === "promotionpattern") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deletePromotionPattern`,
        {
          data: {
            promotionPatternId: id,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("deletepromotionpattern", id);
      }
    } else if (type === "levelpattern") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteLevelPattern`,
        {
          data: {
            levelPatternId: id,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("deletelevelpattern", id);
      }
    }
    toggleDeleteModal();
  };
  return (
    <div
      className={`opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center modal-delete`}
    >
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 z-40"></div>
      <div className="bg-white rounded-lg z-50 w-11/12 top-20 max-h-11/12 overflow-y-hidden max-w-sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full rounded-lg p-6 text-left">
            <div className="flex justify-end">
              <div onClick={toggleDeleteModal}></div>
            </div>
            {/* body-start */}
            <div className="grid grid-cols-10">
              <div className="col-span-10 flex flex-col pl-2">
                <p className="font-medium pb-2 flex items-center justify-start">
                  Remove {type} :
                </p>
                <p className="text-sm text-gray-700">
                  Are you sure you want to delete this {type} ?
                </p>
              </div>
            </div>

            <Fragment>
              <div className="pt-4 flex space-x-4">
                <button
                  className="w-1/2 px-4 py-2 focus:outline-none border text-center font-medium rounded-lg text-sm"
                  onClick={() => {
                    toggleDeleteModal();
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className={`w-1/2 px-4 py-2 focus:outline-none text-white shadow-md bg-red-500 rounded-lg font-medium text-sm flex justify-center`}
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting ? (
                    <Fragment>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Processing</span>
                    </Fragment>
                  ) : (
                    <span>Delete</span>
                  )}
                </button>
              </div>
            </Fragment>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteModal;

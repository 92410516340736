import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { toggleDeleteModal } from "../../../modal-functions/ModalFunctions";
import Loader from "../../../utilities/Loader";

const DesignationList = ({
  handleDesignationEdit,
  setDesignationObj,
  setDeleteDesignationObj,
  designationList,
  setDesignationList,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleEdit = async (e) => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getDesignationDetailsById`,
      {
        params: {
          designationId: e.currentTarget.id,
        },
      }
    );

    setDesignationObj({
      designationId: result.data.rows[0].designationid,
      designationName: result.data.rows[0].designationname,
      designationHeirarchy: result.data.rows[0].designationheirarchy,
    });

    handleDesignationEdit();
  };

  const handleDelete = async (e) => {
    toggleDeleteModal();
    setDeleteDesignationObj({
      designationId: e.currentTarget.id,
    });
  };

  const getDesignationList = async () => {
    console.log("inside get designation list method");
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getDesignationList`
    );
    setDesignationList(result.data);
    setIsLoading(false);
  };
  useEffect(() => {
    getDesignationList();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <Loader message="Fetching designation list." />
      ) : (
        <div className="bg-white rounded-lg w-1/2 p-3">
          <p className="text-lg text-left font-medium mb-3">Designation List</p>
          {designationList && designationList.length > 0 ? (
            <div className="text-left">
              <div className="p-3 flex font-medium items-center bg-blue-200 text-gray-600 text-white shadow-md rounded-lg  mb-2 grid grid-cols-3 gap-3">
                <div className="col-span-2">
                  Designation name
                  <small className="ml-2 font-medium text-gray-100"></small>
                </div>
                <div className="col-span-1 flex justify-end">Action</div>
              </div>
              {designationList.map((x) => {
                return (
                  <div className="p-3 flex items-center shadow-md rounded-lg border-l-4 border-blue-300 border-b-none mb-2 grid grid-cols-3 gap-3">
                    <div className="col-span-2">
                      {x.designationname}{" "}
                      {x.designationheirarchy && (
                        <span className="ml-2 font-medium text-theme-green">
                          ({x.designationheirarchy})
                        </span>
                      )}
                    </div>
                    <div className="col-span-1 flex justify-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5  cursor-pointer"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        id={x.designationid}
                        onClick={handleEdit}
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-9 cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          id={x.designationid}
                          onClick={handleDelete}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No designations added as of now.</div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default DesignationList;

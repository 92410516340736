import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../utilities/Loader";
import nouser from "../../../assets/noUser.jpg";
import AddSuspension from "./AddSuspension";
import AddSalaryIncrement from "./AddSalaryIncrement";
import AddEmpProgressionHistory from "./AddEmpProgressionHistory";
import { Menu, Transition } from "@headlessui/react";
import * as XLSX from "xlsx/xlsx.mjs";
import SetInActive from "./SetInActive";
import Filters from "./Filters";

const EmployeeListGangtok = () => {
  const [empList, setEmpList] = useState([]);
  const [empListFiltered, setEmpListFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isSuspensionVisible, setIsSuspensionVisible] = useState(false);
  const [isSalaryIncrementVisible, setIsSalaryIncrementVisible] =
    useState(false);
  const [isProgressionVisible, setIsProgressionVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [suspensionDetails, setSuspensionDetails] = useState();
  const [isInActiveVisible, setIsInActiveVisible] = useState(false);
  const [inActiveStatus, setInActiveStatus] = useState(false);

  const getEmployeeList = async () => {
    try {
      setIsLoading(true);
      let result;
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeListWithCurrentDetailsGangtok`
      );

      setEmpList(result.data.filter((employee) => employee.isactive));
      setEmpListFiltered(result.data.filter((employee) => employee.isactive));
    } catch (error) {
      console.log("Error", error);
    }

    setIsLoading(false);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const spliceTime = (datetime) => {
    let dateString = datetime;
    if (datetime !== null) {
      dateString = dateString.split("T")[0];
      return dateString;
    } else {
      return "";
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleOnExport = () => {
    let empArray = [];

    for (let i = 0; i < empList.length; i++) {
      let empobj = {
        "Sl No": i + 1,
        Name: empList[i].employeename,
        Contact: empList[i].employeecontact,
        "Date of Birth": formatDate(empList[i].employeedateofbirth),
        Religion: empList[i].employeereligion,
        Address: empList[i].employeeaddress,
        "Education Qualification": empList[i].educationqualifiation,
        "Current Designation": empList[i].empdesignationname,
        "Current Employee Type": empList[i].emptypename,
        "Current Section": empList[i].empsectionname,
        "Current Unit": empList[i].empunitname,
        "Appointed Date": formatDate(empList[i].empappointmentdate),
        "Appointed Designation": empList[i].empappointeddesignationname,
        "Appointed Employee Type": empList[i].empappointedemptypename,
        "Appointed Section": empList[i].empappointedsectionname,
        "Appointed Unit": empList[i].empappointedunitname,
        "Appointment Type": empList[i].empappointmenttype,
      };
      empArray.push(empobj);
    }
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(empArray);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "GangtokEmployee.xlsx");
  };

  // const onChangeFilters = (filterdetails) => {
  //   console.log("Filter details", filterdetails);
  // };

  const handleLeavesClick = (empdetails) => {
    console.log("Emp details", empdetails);
  };

  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <AddSuspension
          isVisible={isSuspensionVisible}
          setIsSuspensionVisible={setIsSuspensionVisible}
          selectedEmployee={selectedEmployee}
          suspensionDetails={suspensionDetails}
        />
        <AddSalaryIncrement
          isVisible={isSalaryIncrementVisible}
          setIsSalaryIncrementVisible={setIsSalaryIncrementVisible}
          selectedEmployee={selectedEmployee}
        />
        <AddEmpProgressionHistory
          isVisible={isProgressionVisible}
          setIsProgressionVisible={setIsProgressionVisible}
          selectedEmployee={selectedEmployee}
        />
        <SetInActive
          isVisible={isInActiveVisible}
          selectedEmployee={selectedEmployee}
          setIsInActiveVisible={setIsInActiveVisible}
          inActiveStatus={inActiveStatus}
          getEmployeeList={getEmployeeList}
        />
        <div className="flex justify-between">
          <span className="font-medium text-xl text-gray-700 pl-1">
            Gangtok Employees
          </span>
          <div className="flex">
            <button
              className="bg-green-500 px-3 py-2 text-white focus:outline-none rounded-md flex items-center shadow-lg mr-4 text-sm font-medium"
              onClick={handleOnExport}
            >
              <span>Export to Excel</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </button>
            <Link
              to={{
                pathname: "/admin/addemployee",
                state: {
                  unitname: "Gangtok",
                },
              }}
            >
              <button className="bg-sky-blue px-3 py-2 text-white focus:outline-none rounded-md flex items-center shadow-lg text-sm font-medium">
                <span>Register Employee</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
        <div className="rounded-lg">
          {isLoading ? (
            <Loader message={"fetching employees...."} />
          ) : (
            <Fragment>
              <div className="z-10 flex items-center gap-4 py-4 sticky top-14 z-99 bg-gray-100 px-1">
                <div class="flex items-center text-sm w-96 rounded-lg border border-gray-300 bg-gray-900">
                  <input
                    class="rounded-l-md w-full p-2.5 text-gray-800 focus:outline-none"
                    id="search"
                    type="text"
                    placeholder="Search employee name"
                    onChange={handleSearchTextChange}
                    value={searchText}
                    autoComplete="off"
                    autoFocus
                  ></input>
                  <div class="py-2 px-3 bg-gray-900">
                    {!searchText ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-white cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-white font-bold cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={() => {
                          setSearchText("");
                        }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                {/* <Filters /> */}
              </div>

              <div class="flex flex-col px-1">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow border-b border-gray-200 sm:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Designation
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Employee Type
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Is Active
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          {empList && empList.length > 0 ? (
                            <Fragment>
                              {searchText === "" ? (
                                <Fragment>
                                  {empList.map((emp, index) => (
                                    <TableData
                                      key={index}
                                      item={emp}
                                      setIsSuspensionVisible={
                                        setIsSuspensionVisible
                                      }
                                      setIsSalaryIncrementVisible={
                                        setIsSalaryIncrementVisible
                                      }
                                      setSelectedEmployee={setSelectedEmployee}
                                      setSuspensionDetails={
                                        setSuspensionDetails
                                      }
                                      setIsProgressionVisible={
                                        setIsProgressionVisible
                                      }
                                      setIsInActiveVisible={
                                        setIsInActiveVisible
                                      }
                                      setInActiveStatus={setInActiveStatus}
                                      getEmployeeList={getEmployeeList}
                                    />
                                  ))}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {empList
                                    .filter((x) =>
                                      x.employeename
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    )
                                    .map((emp, index) => (
                                      <TableData
                                        key={index}
                                        item={emp}
                                        setIsSuspensionVisible={
                                          setIsSuspensionVisible
                                        }
                                        setIsSalaryIncrementVisible={
                                          setIsSalaryIncrementVisible
                                        }
                                        setSelectedEmployee={
                                          setSelectedEmployee
                                        }
                                        setSuspensionDetails={
                                          setSuspensionDetails
                                        }
                                        setIsProgressionVisible={
                                          setIsProgressionVisible
                                        }
                                        setIsInActiveVisible={
                                          setIsInActiveVisible
                                        }
                                        setInActiveStatus={setInActiveStatus}
                                        getEmployeeList={getEmployeeList}
                                      />
                                    ))}
                                </Fragment>
                              )}
                            </Fragment>
                          ) : (
                            <tr col={5}>No employees found !</tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const TableData = ({
  item,
  setIsSuspensionVisible,
  setIsSalaryIncrementVisible,
  setSelectedEmployee,
  setSuspensionDetails,
  setIsProgressionVisible,
  setIsInActiveVisible,
  setInActiveStatus,
  getEmployeeList,
}) => {
  const suspensionClickHandler = async () => {
    setIsSuspensionVisible(true);
    setSelectedEmployee(item);
    const result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/checkSuspension`,
      {
        params: { empid: item.employeeid },
      }
    );
    setSuspensionDetails(() => result.data);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <tr>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="flex items-center">
          <div class="flex-shrink-0 h-12 w-12">
            {item.employeephoto ? (
              <img
                class="h-12 w-12 rounded-full"
                src={item.employeephoto}
                alt="user"
              />
            ) : (
              <img class="h-12 w-12 rounded-full" src={nouser} alt="nouser" />
            )}
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {item.employeename}
              {!item.has_coi && (
                <p className="text-xs bg-yellow-500 w-min mb-0 px-1 rounded-lg text-white">
                  No COI
                </p>
              )}
            </div>
            {/* <div class="text-sm text-gray-500">jane.cooper@example.com</div> */}
          </div>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">{item.empdesignationname}</div>
        <div class="text-sm text-gray-500">{item.empsectionname}</div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {item.emptypename}
        </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        {item.isactive ? (
          <div className="bg-green-500 rounded-full w-3 h-3"></div>
        ) : (
          <div className="bg-red-500 rounded-full w-3 h-3"></div>
        )}
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <div className="flex justify-end">
          <Menu as="div" className="relative block text-left">
            <div>
              <Menu.Button className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <p>Manage</p>
                </div>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={{
                          pathname: "/admin/editemployee",
                          state: {
                            employeeid: item.employeeid,
                            unitname: "Gangtok",
                          },
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fillRule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p>Edit</p>
                        </div>
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={{
                          pathname: "/admin/viewempprogressionhistory",
                          state: {
                            employeeid: item.employeeid,
                            unitname: "Gangtok",
                            empDetails: item,
                          },
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path
                              fillRule="evenodd"
                              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p>View Progression</p>
                        </div>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setIsProgressionVisible(true);
                          setSelectedEmployee(item);
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                          </svg>
                          <p>Add Progression</p>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setIsSalaryIncrementVisible(true);
                          setSelectedEmployee(item);
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p>Add Increment</p>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={suspensionClickHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p>Add Suspension</p>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          setIsInActiveVisible(true);
                          setSelectedEmployee(item);
                          setInActiveStatus(!item.isactive);
                          getEmployeeList();
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                            />
                          </svg>
                          {item.isactive ? (
                            <p>Set Inactive</p>
                          ) : (
                            <p>Set Active</p>
                          )}
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={{
                          pathname: "/admin/employeeleaves",
                          state: {
                            employeedetails: item,
                            unitname: "Gangtok",
                          },
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                            />
                          </svg>

                          <p>Leaves</p>
                        </div>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </td>
    </tr>
  );
};
export default EmployeeListGangtok;

import axios from "axios";
import React, { useState } from "react";
import Modal from "../../ReusableComponents/Modal";
import nouser from "../../../assets/noUser.jpg";

export default function LevelWiseReport() {
  const [empList, setEmpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clickedDesignationDetails, setClickedDesignationDetails] = useState();
  const getEmployeeList = async () => {
    if (unitName !== "") {
      try {
        setIsLoading(true);
        const result = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/getLevelWiseReport`,
          { params: { unitname: unitName } }
        );
        console.log("Emp List", result.data);
        setSelectedUnit(unitName);
        setEmpList(result.data);
      } catch (error) {
        console.log("ERROR", error);
      }
    } else {
      alert("Please select a unit");
    }
    setIsLoading(false);
  };

  const onClickDesignation = (designation) => {
    setIsModalVisible(true);
    setClickedDesignationDetails(designation);
    console.log(designation);
  };
  return (
    <div className="p-6">
      <Modal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        heading={selectedUnit}
      >
        {clickedDesignationDetails !== undefined && (
          <>
            <p>
              Designation :{" "}
              <span className="font-medium">
                {clickedDesignationDetails.empcurrentdesignationname}
              </span>
            </p>
            <div>
              {clickedDesignationDetails.employeedetails?.length > 0 && (
                <>
                  {clickedDesignationDetails.employeedetails.map((employee) => (
                    <div className="flex items-center mt-4 border-b pb-2">
                      <div class="flex-shrink-0 h-12 w-12">
                        {employee.employeephoto ? (
                          <img
                            class="h-12 w-12 rounded-full"
                            src={employee.employeephoto}
                            alt="user"
                          />
                        ) : (
                          <img
                            class="h-12 w-12 rounded-full"
                            src={nouser}
                            alt="nouser"
                          />
                        )}
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {employee.employeename}
                          {/* {!item.has_coi && (
                            <p className="text-xs bg-yellow-500 w-min mb-0 px-1 rounded-lg text-white">
                              No COI
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </Modal>
      <p className="font-medium text-xl text-gray-700 pl-1 mb-4">
        Level Wise Report
      </p>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="my-4 px-4 py-2 rounded-md border bg-white w-60">
            <select
              className="w-full focus:outline-none"
              onChange={(e) => {
                setUnitName(e.target.value);
                setEmpList([]);
              }}
            >
              <option value="">Select</option>
              <option value="Gangtok">Gangtok</option>
              <option value="Jorethang">Jorethang</option>
            </select>
          </div>
          <button
            className="ml-4 py-2 px-4 bg-sky-blue text-white rounded-sm shadow-md"
            onClick={getEmployeeList}
          >
            View Report
          </button>
        </div>
      </div>
      <div>
        {empList?.length > 0 ? (
          <>
            <div className="bg-white p-4 text-lg font-bold text-center border-b">
              {selectedUnit} Report
            </div>
            {empList.map((emp) => (
              <div className="bg-white mb-2 p-4 flex gap-4">
                <p className="border-r pr-3 w-20">Level {emp.levelnumber}</p>
                <div className="w-full">
                  <p className="font-bold pb-2">Designations:</p>
                  {emp.designationdetails?.length > 0 && (
                    <div className="grid grid-cols-3 gap-2">
                      {emp.designationdetails.map((designation) => (
                        <div
                          className="col-span-1 cursor-pointer"
                          onClick={() => onClickDesignation(designation)}
                        >
                          <p className=" hover:text-blue-600 px-1">
                            {designation.empcurrentdesignationname} (
                            <span className="font-medium">
                              {designation.employeedetails.length})
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="bg-white p-4 text-center font-medium">
            No Results Found!
          </div>
        )}
      </div>
    </div>
  );
}

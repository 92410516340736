import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../utilities/Loader";
import { useLocation } from "react-router";
import noImage from "../../../assets/noImage.jpg";
import { getFormattedDateInputDate } from "../../../utilities/functions/UtilityFunctions";

const AcrDetails = () => {
  const { state } = useLocation();
  console.log("fool : ", state);
  const [isLoading, setIsLoading] = useState(true);
  const acrid = state.acrid;
  const employeeid = state.employeeid;
  const employeename = state.employeename;
  const employeedesignation = state.employeedesignation;
  const employeephoto = state.employeephoto;
  const employeetype = state.employeetype;
  const [acrDetails, setAcrDetails] = useState({});

  const getAcrDetails = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getAcrDetails`,
      {
        params: {
          acrid,
          employeeid,
        },
      }
    );
    setAcrDetails(result.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getAcrDetails();
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <Loader message={"fetching ACR details..."} />
      ) : (
        <div className="p-6">
          <div className="flex justify-between">
            <span className="font-medium text-2xl text-gray-700">
              ACR Details
            </span>
            <Link
              to={{
                pathname: "/sectionhead/acrlist",
                state: {
                  employeeid: employeeid,
                  employeename: employeename,
                  employeedesignation: employeedesignation,
                  employeephoto: employeephoto,
                  employeetype: employeetype,
                },
              }}
            >
              <button className="bg-gray-900 text-white py-2 px-4 rounded-md focus:outline-none rounded-sm shadow-md flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Back to ACR list </span>
              </button>
            </Link>
          </div>
          <div className="mt-8">
            <p className="text-center font-bold">
              THE SIKKIM CO-OPERATIVE MILK PRODUCERS' LIMITED
            </p>
            <p className="text-center">TADONG</p>
            <p className="font-medium underline text-center mt-3">
              INDIVIDUAL ASSESSMENT FORM
            </p>
            <div className="bg-white shadow grid grid-cols-5 mt-6 gap-6">
              <div className="col-span-1 p-4 flex items-center">
                <div>
                  <img
                    src={
                      employeephoto === "" || employeephoto === null
                        ? noImage
                        : employeephoto
                    }
                    className="w-full mx-auto"
                  />
                  <div className="flex justify-between pt-2 px-1">
                    <div>
                      <p className="">{employeename}</p>
                      <p className="text-gray-500">{employeedesignation}</p>
                    </div>
                    <p className="text-gray-500 text-green-500 font-bold">
                      {employeetype}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-4 p-4 flex items-center">
                <div className="w-full">
                  <div className="flex space-x-10">
                    <div className="flex justify-center items-center">
                      <p className="font-medium">From period :&nbsp;</p>
                      <p className="">
                        {getFormattedDateInputDate(acrDetails.fromdate)}
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="font-medium">To period :&nbsp;</p>
                      <p className="">
                        {getFormattedDateInputDate(acrDetails.todate)}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mt-4">
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">Name:</p>
                      <p className="text-gray-800">{employeename}</p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">
                        Type of employement:
                      </p>
                      <p className="text-gray-800">
                        {acrDetails.employmenttype}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">
                        Date of appointment:
                      </p>
                      <p className="text-gray-800">
                        {getFormattedDateInputDate(
                          acrDetails.dateofappointment
                        )}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">
                        Present designation:
                      </p>
                      <p className="text-gray-800">{acrDetails.designation}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mt-4">
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">
                        Present pay scale/salary:
                      </p>
                      <p className="text-gray-800">
                        {acrDetails.presentpayscale}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">
                        Recruitment type:
                      </p>
                      <p className="text-gray-800">
                        {acrDetails.recruitmenttype}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">
                        Redesignation ? <small>(If any on date):</small>
                      </p>
                      <p className="text-gray-800">
                        {acrDetails.redesignationdate !== null &&
                        acrDetails.redesignationdate !== ""
                          ? getFormattedDateInputDate(
                              acrDetails.redesignationdate
                            )
                          : "-"}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="font-medium text-gray-800">
                        Total no. of years in service
                      </p>
                      <p className="text-gray-800">
                        {acrDetails.totalyearsinservice}
                      </p>
                    </div>
                  </div>
                  {acrDetails.employmenttype !== "MR" ? (
                    <Fragment>
                      <div className="grid grid-cols-4 gap-4 mt-4">
                        <div className="col-span-1">
                          <p className="font-medium text-gray-800">
                            Previous increment date:
                          </p>
                          <p className="text-gray-800">
                            {getFormattedDateInputDate(
                              acrDetails.previousincrementdate
                            )}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="font-medium text-gray-800">
                            Present increment date:
                          </p>
                          <p className="text-gray-800">
                            {getFormattedDateInputDate(
                              acrDetails.presentincrementdate
                            )}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="font-medium text-gray-800">
                            Date of due increment{" "}
                          </p>

                          <p className="text-gray-800">
                            {getFormattedDateInputDate(
                              acrDetails.dateofdueincrement
                            )}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="font-medium text-gray-800">
                            Level of rating in number:
                          </p>
                          <p className="text-gray-800">
                            {acrDetails.levelofrating}
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-4 gap-4 mt-4">
                        <div className="col-span-1">
                          <p className="font-medium text-gray-800">
                            No. of promotions till date:
                          </p>
                          <p className="text-gray-800">
                            {acrDetails.noofpromotions}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="mt-8 bg-white shadow">
              <p className="mb-4 font-medium text-sky-blue p-3 bg-white border-b">
                1. What was your total attendance for the year ?{" "}
                <span className="italic text-sm">(In numbers)</span>
              </p>
              <div className="p-4"></div>
            </div>
            <div className="mt-8 bg-white shadow">
              <p className="mb-4 font-medium text-sky-blue p-3 bg-white border-b">
                2. What is the nature of your job role in the organisation ?{" "}
                <p className="italic text-sm">
                  (This includes your daily routine in the organisation)
                </p>{" "}
              </p>
              <div className="p-4">
                <div className="p-1  w-1/4 text-sm text-center rounded-t-md text-sky-blue border border-sky-blue">
                  Employee input
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.natureofjob}
                </div>
                <div className="p-1 bg-sky-blue w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                  Controller remarks
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.natureofjob_controller}
                </div>
              </div>
            </div>

            <div className="mt-8 bg-white shdaow">
              <p className="mb-4 font-medium text-sky-blue p-3 bg-white border-b">
                3. What is knowledge about the job role that you currently hold
                ?{" "}
                <p className="italic text-sm">
                  ( This includes : Knowledge/execution of work, Ability to
                  learn, Quality of work delivered & Creativity at workplace to
                  make tasks convenient ){" "}
                </p>{" "}
              </p>
              <div className="p-4">
                <div className="p-1 bg-white w-1/4 text-sm text-center rounded-t-md text-sky-blue border border-sky-blue">
                  Employee input
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.knowledgeofjob}
                </div>
                <div className="p-1 bg-sky-blue w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                  Controller remarks
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.knowledgeofjob_controller}
                </div>
              </div>
            </div>

            <div className="mt-8 bg-white shadow">
              <p className="mb-4 font-medium text-sky-blue p-3 bg-white border-b">
                4. What are the duties that you have performed in the last year
                ? ?{" "}
              </p>
              <div className="p-4">
                <div className="p-1 bg-white w-1/4 text-sm text-center rounded-t-md text-sky-blue border border-sky-blue">
                  Employee input
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.dutiesperformed}
                </div>
                <div className="p-1 bg-sky-blue w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                  Controller remarks
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.dutiesperformed_controller}
                </div>
              </div>
            </div>

            <div className="mt-8 shadow bg-white">
              <p className="mb-4 font-medium text-sky-blue p-3 bg-white border-b">
                5. What value have you added to the organisation by applying
                your innovative idea and how has the company benefitted ? ? ?{" "}
                <p className="italic text-sm">
                  ( This includes extra work to make your job role valuable or
                  any idea that was profitable for the organisation )
                </p>{" "}
              </p>
              <div className="p-4">
                <div className="p-1 bg-white w-1/4 text-sm text-center rounded-t-md text-sky-blue border border-sky-blue">
                  Employee input
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.valuesadded}
                </div>
                <div className="p-1 bg-sky-blue w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                  Controller remarks
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.valuesadded_controller}
                </div>
              </div>
            </div>

            <div className="mt-8 bg-white shadow">
              <p className="mb-4 font-medium text-sky-blue p-3 bg-white border-b">
                6. How well are you aware of Office etiquettes ?
                <p className="italic text-sm">
                  ( This includes : Punctuality, Attendance, Integrity, Personal
                  appearance, dealing with colleagues, public or seniors )
                </p>{" "}
              </p>
              <div className="p-4">
                <div className="p-1 bg-white w-1/4 text-sm text-center rounded-t-md text-sky-blue border border-sky-blue">
                  Employee input
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.officeettiquetes}
                </div>
                <div className="p-1 bg-sky-blue w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                  Controller remarks
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.officeettiquetes_controller}
                </div>
              </div>
            </div>

            <div className="mt-8 bg-white shadow">
              <p className="mb-4 font-medium text-sky-blue p-3 bg-white border-b">
                7. how good is your communication ?{" "}
                <p className="italic text-sm">
                  ( This includes : Punctuality, Attendance, Integrity, Personal
                  appearance, dealing with colleagues, public or seniors )
                </p>{" "}
              </p>
              <div className="p-4">
                <div className="p-1 bg-white w-1/4 text-sm text-center rounded-t-md text-sky-blue border border-sky-blue">
                  Employee input
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.communication}
                </div>
                <div className="p-1 bg-sky-blue w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                  Controller remarks
                </div>{" "}
                <div className="border bg-white rounded-b-lg p-6 border-gray-300">
                  {acrDetails.communication_controller}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AcrDetails;

const toggleDeleteModal = () => {
  const body = document.querySelector("body");
  const modal = document.querySelector(".modal-delete");
  modal.classList.toggle("opacity-0");
  modal.classList.toggle("z-40");
  modal.classList.toggle("pointer-events-none");
  body.classList.toggle("modal-active");
};

const toggleGeneralDeleteModal = () => {
  const body = document.querySelector("body");
  const modal = document.querySelector(".general-deletemodal");
  modal.classList.toggle("opacity-0");
  modal.classList.toggle("z-40");
  modal.classList.toggle("pointer-events-none");
  body.classList.toggle("modal-active");
};

const toggleLogoutModal = () => {
  const body = document.querySelector("body");
  const modal = document.querySelector(".logoutModal");
  modal.classList.toggle("opacity-0");
  modal.classList.toggle("z-40");
  modal.classList.toggle("pointer-events-none");
  body.classList.toggle("modal-active");
};

const toggleAcrDetailsModal = () => {
  const body = document.querySelector("body");
  const modal = document.querySelector(".acrDetailsModal");
  modal.classList.toggle("opacity-0");
  modal.classList.toggle("z-40");
  modal.classList.toggle("pointer-events-none");
  body.classList.toggle("modal-active");
};

const toggleHandleEmployeeModal = () => {
  const body = document.querySelector("body");
  const modal = document.querySelector(".handleEmployeeModal");
  modal.classList.toggle("opacity-0");
  modal.classList.toggle("z-40");
  modal.classList.toggle("pointer-events-none");
  body.classList.toggle("modal-active");
};

const togglePromoteEmployeeModal = () => {
  const body = document.querySelector("body");
  const modal = document.querySelector(".promoteEmployeeModal");
  modal.classList.toggle("opacity-0");
  modal.classList.toggle("z-40");
  modal.classList.toggle("pointer-events-none");
  body.classList.toggle("modal-active");
};

export {
  toggleDeleteModal,
  toggleLogoutModal,
  toggleAcrDetailsModal,
  toggleHandleEmployeeModal,
  togglePromoteEmployeeModal,
  toggleGeneralDeleteModal,
};

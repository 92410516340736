import React, { Component, Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";

const PromoteEmployee = ({
  togglePromoteEmployeeModal,
  employeeid,
  getEmployeeDetails,
}) => {
  const { register, handleSubmit, errors, formState, reset } = useForm();
  const [designationList, setDesignationList] = useState();
  const [levelList, setLevelList] = useState();
  const [sectionList, setSectionList] = useState();
  const [employeeTypeList, setEmployeeTypeList] = useState();
  const [designationId, setDesignationId] = useState();
  const [levelId, setLevelId] = useState();
  const [sectionId, setSectionId] = useState();
  const [empTypeId, setEmpTypeId] = useState();
  const unitList = ["Gangtok", "Jorethang"];
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getConfigurations = async () => {
    let designationList;
    let levelList;
    let sectionList;
    let empTypeList;

    designationList = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getDesignationList`
    );
    setDesignationList(designationList.data);

    levelList = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getLevelList`
    );
    setLevelList(levelList.data);

    sectionList = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionList`
    );
    setSectionList(sectionList.data);

    empTypeList = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeList`
    );
    setEmployeeTypeList(empTypeList.data);
  };

  const handleClose = () => {
    reset();
    togglePromoteEmployeeModal();
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    // update employee current official details
    const result = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/promoteEmployee`,
      {
        data: {
          empId: employeeid,
          empCurrentEmpTypeId: data.empTypeId,
          empCurrentLevelId: data.empLevelId,
          empCurrentDesignationId: data.empDesignationId,
          empPromotedToCurrentDate: data.empPromotedToCurrentDate,
          empCurrentSectionId: data.empSectionId,
          empCurrentUnit: data.empUnit,
        },
      }
    );
    if (result.data) {
      //add progression to emloyee career progression history
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeCareerProgressionHistoryAfterPromotion`,
        {
          data: {
            empId: employeeid,
            progressionType: "Promotion",
            designationId: data.empDesignationId,
            levelid: data.empLevelId,
            empTypeId: data.empTypeId,
            sectionid: data.empSectionId,
            unitname: data.empUnit,
            progressionDate: data.empPromotedToCurrentDate,
          },
        }
      );
      if (result.data) {
        reset();
        getEmployeeDetails();
        setIsSubmitting(false);
        togglePromoteEmployeeModal();
      }
    }
  };

  useEffect(() => {
    getConfigurations();
  }, []);
  return (
    <Fragment>
      <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center promoteEmployeeModal">
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 z-40"></div>
        <div className="bg-white rounded-lg z-50 w-2/4 top-20 max-h-11/12 overflow-y-hidden">
          <div className="w-full rounded-lg p-6">
            {" "}
            <div className="flex justify-between">
              <span className="text-xl font-medium">Promote Employee</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width="20"
                height="20"
                className="cursor-pointer"
                onClick={handleClose}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="text-left">
              <div className="grid grid-cols-3 gap-10 mt-8">
                <div className="col-span-1">
                  <p>Designation </p>
                  <div>
                    <select
                      name="empDesignationId"
                      ref={register({ required: true })}
                      onChange={(e) => {
                        setDesignationId(e.target.value);
                      }}
                      className={`border border-gray-500 rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                        errors.empDesignationId ? "border-red-700" : ""
                      }`}
                      value={designationId}
                    >
                      <option value="">--select designation--</option>

                      {designationList &&
                        designationList.map((x) => {
                          return (
                            <option value={x.designationid}>
                              {x.designationname}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  {errors.empDesignationId && (
                    <Error message="Designation is required" />
                  )}
                </div>
                <div className="col-span-1">
                  <p>Level</p>
                  <div>
                    <select
                      name="empLevelId"
                      ref={register({ required: false })}
                      onChange={(e) => {
                        setLevelId(e.target.value);
                      }}
                      className={`border border-gray-500 rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                        errors.empLevelId ? "border-red-700" : ""
                      }`}
                      value={levelId}
                    >
                      <option value="">--select level-- </option>
                      {levelList &&
                        levelList.map((x) => {
                          return (
                            <option value={x.levelid}>
                              {" "}
                              Level {x.levelnumber}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  {errors.empLevelId && <Error message="Level is required" />}
                </div>
                <div className="col-span-1">
                  <p>Unit</p>
                  <select
                    name="empUnit"
                    ref={register({ required: true })}
                    className={`border border-gray-500 rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                      errors.empUnit ? "border-red-700" : ""
                    }`}
                  >
                    <option value="">--select unit-- </option>
                    {unitList &&
                      unitList.map((x) => {
                        return <option value={x}> {x}</option>;
                      })}
                  </select>
                  {errors.empUnit && (
                    <Error message="Current unit is required" />
                  )}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-10 mt-6">
                <div className="col-span-1">
                  <p>Employee Type</p>
                  <div>
                    <select
                      name="empTypeId"
                      ref={register({ required: true })}
                      onChange={(e) => setEmpTypeId(e.target.value)}
                      className={`border border-gray-500 rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                        errors.empTypeId ? "border-red-700" : ""
                      }`}
                      value={empTypeId}
                    >
                      <option value="">--select employee type-- </option>
                      {employeeTypeList &&
                        employeeTypeList.map((x) => {
                          return (
                            <option value={x.employeetypeid}>
                              {x.employeetypename}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  {errors.empTypeId && (
                    <Error message="Employee type is required" />
                  )}
                </div>

                <div className="col-span-1">
                  <p>Section</p>
                  <select
                    name="empSectionId"
                    ref={register({ required: true })}
                    onChange={(e) => {
                      setSectionId(e.target.value);
                    }}
                    className={`border border-gray-500 rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                      errors.empSectionId ? "border-red-700" : ""
                    }`}
                    value={sectionId}
                  >
                    <option value="">--select section-- </option>
                    {sectionList &&
                      sectionList.map((x) => {
                        return (
                          <option value={x.sectionid}>{x.sectionname}</option>
                        );
                      })}
                  </select>
                  {errors.empSectionId && (
                    <Error message="Current section is required" />
                  )}
                </div>

                <div className="col-span-1">
                  <p>Date of promotion</p>
                  <input
                    name="empPromotedToCurrentDate"
                    type="date"
                    className={`border border-gray-500 rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                      errors.empPromotedToCurrentDate ? "border-red-700" : ""
                    }`}
                    ref={register({ required: true })}
                  />
                  {errors.empPromotedToCurrentDate && (
                    <Error message="Promotion date is required" />
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="mt-6 focus:outline-none rounded-sm py-2 px-4 rounded-md bg-sky-blue text-white flex items-center"
                onClick={handleSubmit}
                disabled={isSubmitting ? true : false}
              >
                {isSubmitting ? (
                  <Fragment>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Submitting</span>
                  </Fragment>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PromoteEmployee;

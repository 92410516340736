import React, { useState, useEffect } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DeleteModal from "../../../utilities/DeleteModal";
import AddPromotionPattern from "./AddPromotionPattern";
import axios from "axios";
import PromotionPatternList from "./PromotionPatternList";

const PromotionPatternConfiguration = () => {
  const [promotionPatternList, setPromotionPatternList] = useState();
  const [empTypeList, setEmpTypeList] = useState();
  const [isList, setIsList] = useState(true);
  const [isPromotionPatterAdd, setIsPromotionPatterAdd] = useState(false);
  const [promotionPatternObj, setPromotionPatternObj] = useState({
    patternId: "",
    fromEmpTypeId: "",
    toEmpTypeId: "",
    noOfYears: "",
  });
  const [deletePromotionPatternObj, setDeletePromotionPatternObj] = useState({
    patternId: "",
  });
  const [actionType, setActionType] = useState("");
  const { addToast } = useToasts();

  const handlePatternAdd = () => {
    setIsPromotionPatterAdd(true);
    setIsList(false);
    setActionType("add");
  };

  const handlePatternEdit = (e) => {
    setIsPromotionPatterAdd(true);
    setIsList(false);
    setActionType("edit");
  };
  const handleBackToList = (calledFrom, deletedId) => {
    setIsPromotionPatterAdd(false);
    setIsList(true);
    setActionType("");
    if (calledFrom === "addpromotionpattern") {
      addToast("Promotion pattern successfully added.", {
        appearance: "success",
      });
    }
    if (calledFrom === "editpromotionpattern") {
      addToast("Promotion pattern successfully updated.", {
        appearance: "success",
      });
    }
    if (calledFrom === "deletepromotionpattern") {
      let newPromotionPatternList = promotionPatternList.filter(
        function removePattern(x) {
          return parseInt(x.promotionpatternid) !== parseInt(deletedId);
        }
      );
      setPromotionPatternList(newPromotionPatternList);
      addToast("Promotion pattern successfully deleted.", {
        appearance: "success",
      });
    }
  };

  useEffect(() => {
    let result;
    async function getEmployeeTypeList() {
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeList`
      );
      setEmpTypeList(result.data);
    }
    getEmployeeTypeList();
  }, []);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <span className="font-medium text-xl">
          Promotion Pattern Configuration
        </span>
        {isList ? (
          <div>
            <button
              className="bg-theme-green p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handlePatternAdd}
            >
              <span>Add Promotion Pattern</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div>
            <button
              className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handleBackToList}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Back to List</span>
            </button>
          </div>
        )}
      </div>
      <div className="rounded-lg">
        {isPromotionPatterAdd && (
          <div>
            <ToastProvider autoDismiss={true} placement="top-center">
              <div className="flex justify-center">
                <AddPromotionPattern
                  handleBackToList={handleBackToList}
                  actionType={actionType}
                  promotionPatternObj={promotionPatternObj}
                  empTypeList={empTypeList}
                />
              </div>
            </ToastProvider>
          </div>
        )}
        {isList && (
          <div>
            <DeleteModal
              id={deletePromotionPatternObj.patternId}
              type="promotionpattern"
              handleBackToList={handleBackToList}
            />
            <PromotionPatternList
              handlePatternEdit={handlePatternEdit}
              setPromotionPatternObj={setPromotionPatternObj}
              setDeletePromotionPatternObj={setDeletePromotionPatternObj}
              promotionPatternList={promotionPatternList}
              setPromotionPatternList={setPromotionPatternList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotionPatternConfiguration;

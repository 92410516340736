import React from "react";
import { Link } from "react-router-dom";

export default function RetirementList() {
  return (
    <div className="p-6">
      <Link to="/admin/systemadmindashboard">
        <div className="flex items-center bg-black text-white w-28 justify-center py-1 rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <p className="text-sm">Go Back</p>
        </div>
      </Link>
      <p className="font-medium text-lg pl-1 my-4">Retirement List</p>
    </div>
  );
}

import axios from "axios";
import React, { Fragment, useState } from "react";
import Error from "../../utilities/Error";
import { ToastProvider, useToasts } from "react-toast-notifications";

const bcrypt = require("bcryptjs");

export default function ChangePassword() {
  const [hasMatchPasswordError, setHasMatchPasswordError] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formHasError, setFormHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    currentPassword: false,
    newpassword: false,
    confirmpassword: false,
  });
  const { addToast } = useToasts();
  const reset = () => {
    setConfirmPassword("");
    setNewPassword("");
    setCurrentPassword("");
    setErrors({
      ...errors,
      currentPassword: false,
      newpassword: false,
      confirmpassword: false,
    });
  };

  const validateForm = () => {
    let currentpassword = false,
      newpassword = false,
      confirmpassword = false;
    if (currentPassword === "") {
      currentpassword = true;
    }
    if (newPassword === "") {
      newpassword = true;
    }
    if (confirmPassword === "") {
      confirmpassword = true;
    }
    if (currentpassword || newpassword || confirmpassword) {
      setErrors({
        ...errors,
        currentpassword: currentpassword,
        newpassword: newpassword,
        confirmpassword: confirmpassword,
      });
      return false;
    } else {
      setErrors({
        ...errors,
        currentpassword: currentpassword,
        newpassword: newpassword,
        confirmpassword: confirmpassword,
      });
      return true;
    }
  };

  const onSubmit = async () => {
    let isFormValid = validateForm();
    console.log(isFormValid);
    if (isFormValid) {
      setIsSubmitting(true);
      var userResult = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getloginuser`,
        { params: { userName: localStorage.getItem("username") } }
      );
      let isValid = false;
      if (userResult.data.length !== 0) {
        isValid = bcrypt.compareSync(
          currentPassword,
          userResult.data[0].passwordhash
        );
      }
      if (isValid && !hasMatchPasswordError) {
        const hashedPassword = bcrypt.hashSync(
          newPassword,
          bcrypt.genSaltSync()
        );
        const result = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/updateUserPassword`,
          {
            data: {
              username: localStorage.getItem("username"),
              passwordhash: hashedPassword,
            },
          }
        );
        if (result) {
          reset();
          addToast("Password Changed Successfully", {
            appearance: "success",
          });
          setIsSubmitting(false);
        }
      } else {
        setFormHasError(true);
        setIsSubmitting(false);
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="p-5">
      <ToastProvider autoDismiss={true} placement="top-center"></ToastProvider>
      <p className="text-2xl font-medium text-gray-700">Change Password</p>
      <div className="p-8 bg-white mt-7 w-1/2">
        <form>
          <div className="flex flex-col justify-between gap-4">
            <div className="flex flex-col">
              <input
                type="password"
                name="currentpassword"
                onChange={(e) => {
                  setFormHasError(false);
                  if (e.target.value === null || e.target.value === "") {
                    setCurrentPassword("");
                    setErrors({ ...errors, currentpassword: true });
                  } else {
                    setCurrentPassword(e.target.value);
                    setErrors({ ...errors, currentpassword: false });
                  }
                }}
                autoComplete="off"
                className="py-2 rounded-md px-4 focus:outline-none text-gray-900 border border-gray-300 text-sm"
                placeholder="Enter Current Password"
                value={currentPassword}
              />
              {errors.currentpassword && (
                <Error message={"Current Password is required"} />
              )}
              {formHasError && (
                <Error message={"Current Password does not match"} />
              )}
              <input
                type="password"
                name="newpassword"
                onChange={(e) => {
                  setNewPassword(() => e.target.value);
                  if (e.target.value === "" || e.target.value === null) {
                    setErrors({ ...errors, newpassword: true });
                  } else {
                    setErrors({ ...errors, newpassword: false });
                  }
                  if (confirmPassword !== "") {
                    if (confirmPassword !== e.target.value) {
                      setHasMatchPasswordError(true);
                    } else {
                      setHasMatchPasswordError(false);
                    }
                  }
                }}
                onBlur={() => {
                  if (confirmPassword !== "") {
                    if (confirmPassword !== newPassword) {
                      setHasMatchPasswordError(true);
                    } else {
                      setHasMatchPasswordError(false);
                    }
                  }
                }}
                autoComplete="off"
                className="py-2 rounded-md px-4 focus:outline-none text-gray-900 border border-gray-300 mt-2 text-sm"
                placeholder="Enter New Password"
                value={newPassword}
              />
              {errors.newpassword && (
                <Error message={"New Password is required"} />
              )}
              <input
                type="password"
                name="confirmpassword"
                onChange={(e) => {
                  if (e.target.value === "" || e.target.value === null) {
                    setErrors({ ...errors, confirmpassword: true });
                    setConfirmPassword("");
                  } else {
                    setErrors({ ...errors, confirmpassword: false });
                    setConfirmPassword(() => e.target.value);
                    if (e.target.value !== newPassword) {
                      setHasMatchPasswordError(true);
                    } else {
                      setHasMatchPasswordError(false);
                    }
                  }
                }}
                onBlur={() => {
                  if (!errors.confirmpassword) {
                    if (confirmPassword !== newPassword) {
                      setHasMatchPasswordError(true);
                    } else {
                      setHasMatchPasswordError(false);
                    }
                  }
                }}
                autoComplete="off"
                className="py-2 rounded-md px-4 focus:outline-none text-gray-900 border border-gray-300 mt-2 text-sm"
                placeholder="Confirm Password"
                value={confirmPassword}
              />
              {errors.confirmpassword && (
                <Error message={"Confirm Password is required"} />
              )}
              {hasMatchPasswordError && (
                <Error
                  message={"Confirm Password and New Password does not match"}
                />
              )}
            </div>
          </div>
          <div className="flex justify-end mt-3 w-full">
            {/* <button
              type="button"
              className="px-4 py-2 focus:outline-none text-gray-700 shadow-md rounded-md font-bold text-sm flex bg-gray-300"
            >
              <Link to="/admin/dashboard" onClick={reset}>
                Cancel
              </Link>
            </button> */}
            <button
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              type="button"
              onClick={onSubmit}
              disabled={isSubmitting ? true : false}
            >
              {isSubmitting ? (
                <Fragment>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Processing</span>
                </Fragment>
              ) : (
                "Change Password"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const SectionHeadDashboard = () => {
  const [empCount, setEmpCount] = useState(0);
  useEffect(async () => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCountSendForACR`,
      {
        params: {
          unit: localStorage.getItem("unit"),
          sectionid: localStorage.getItem("sectionid"),
        },
      }
    );
    setEmpCount(result.data[0].empcount);
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <p className="font-medium text-2xl flex items-center text-gray-700">
          Dashboard
        </p>
        {empCount > 0 && (
          <div className="grid grid-cols-4">
            <Link to="/sectionhead/acrforwardedempList">
              <div className="p-3 bg-green-500 shadow rounded mt-4 cursor-pointer">
                <p className="text-white">
                  <span className="font-bold text-xl text-gray-600 bg-yellow-200 px-2 rounded-full">
                    {empCount}
                  </span>
                  <br />
                  Employees has been sent by Accounts for ACR.
                </p>
              </div>
            </Link>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SectionHeadDashboard;

import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Loader from "../../../utilities/Loader";
import axios from "axios";
import noUser from "../../../assets/noUser.jpg";
import noImage from "../../../assets/noImage.jpg";
import { getFormattedDateInputDate } from "../../../utilities/functions/UtilityFunctions";
import calender from "../../../assets/timetable.png";
import EmployeeCareerTimeline from "./EmployeeCareerTimeline";
import {
  toggleHandleEmployeeModal,
  togglePromoteEmployeeModal,
} from "../../../modal-functions/ModalFunctions";
import HandleEmployee from "./HandleEmployee";
import PromoteEmployee from "./PromoteEmployee";

const EmployeeDetails = () => {
  const { state } = useLocation();
  const employee_id = state.employeeid;
  const unitname = state.unitname;
  const [isLoading, setIsLoading] = useState(true);
  const [empPersonalDetails, setEmpPersonalDetails] = useState({});
  const [empAppointmentDetails, setEmpAppointmentDetails] = useState({});
  const [empCareerProgressionHistory, setEmpCareerProgressionHistory] =
    useState([]);
  const [noNextPromotionDetailsError, setNoNextPromotionDetailsError] =
    useState(false);
  const [nextPromotionDetails, setNextPromotionDetails] = useState({
    nextPromotionDate: "",
    nextPromotionStatus: "",
  });
  const [empCurrentOfficialDetails, setEmpCurrentOfficialDetails] = useState(
    {}
  );
  const getEmployeeDetails = async () => {
    let result1 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeePersonalDetails`,
      {
        params: {
          employeeId: employee_id,
        },
      }
    );
    setEmpPersonalDetails(result1.data);
    let result2 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeAppointmentDetails`,
      {
        params: {
          employeeId: employee_id,
        },
      }
    );
    setEmpAppointmentDetails(result2.data);
    let result3 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCurrentOfficialDetails`,
      {
        params: {
          employeeId: employee_id,
        },
      }
    );
    setEmpCurrentOfficialDetails(result3.data);
    let result4 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCareerProgressionHistory`,
      {
        params: {
          employeeId: employee_id,
        },
      }
    );
    setEmpCareerProgressionHistory(result4.data);

    if (result3.data.empcurrentemptypename === "Regular") {
      if (
        result2.data.empappointedlevelid !== null &&
        result3.data.empcurrentlevelid !== null &&
        result3.data.datepromotedtocurrentstatus !== null
      ) {
        //Get next promotion details for regular employees
        let nofyearstopromotion_regular = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeNextPromotionDetails_regular`,
          {
            params: {
              employeeapptype: result2.data.empappointmenttype,
              employapplevelid: result2.data.empappointedlevelid,
              employeecurlevelid: result3.data.empcurrentlevelid,
            },
          }
        );
        setNextPromotionDetails({
          nextPromotionDate: new Date(
            new Date(
              new Date(result3.data.datepromotedtocurrentstatus).setFullYear(
                new Date(
                  result3.data.datepromotedtocurrentstatus
                ).getFullYear() +
                  parseInt(nofyearstopromotion_regular.data.noofyears)
              )
            ).setMonth(
              new Date(result3.data.datepromotedtocurrentstatus).getMonth() + 1
            )
          ),
          nextPromotionStatus: nofyearstopromotion_regular.data.tolevelnumber,
        });
      } else {
        setNoNextPromotionDetailsError(true);
        console.log(
          "Cannot build promotion pattern for the regular as certain fields are missing."
        );
      }
    } else if (result3.data.empcurrentemptypename === "Consolidated") {
      if (
        result2.data.empappointmenttype !== null &&
        result3.data.empcurrentemptypeid !== null &&
        result3.data.datepromotedtocurrentstatus !== null
      ) {
        //Get next promotion details for consolidated employees
        let nofyearstopromotion_consolidated = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeNextPromotionDetails_consolidated`,
          {
            params: {
              employeeapptype: result2.data.empappointmenttype,
              empCurrEmpTypeId: result3.data.empcurrentemptypeid,
            },
          }
        );
        setNextPromotionDetails({
          nextPromotionDate: new Date(
            new Date(
              new Date(result3.data.datepromotedtocurrentstatus).setFullYear(
                new Date(
                  result3.data.datepromotedtocurrentstatus
                ).getFullYear() +
                  parseInt(nofyearstopromotion_consolidated.data.noofyears)
              )
            ).setMonth(
              new Date(result3.data.datepromotedtocurrentstatus).getMonth() + 1
            )
          ),
          nextPromotionStatus:
            nofyearstopromotion_consolidated.data.toemployeetypename,
        });
      } else {
        setNoNextPromotionDetailsError(true);

        console.log(
          "Cannot build promotion pattern for the consolidated employee as certain fields are missing."
        );
      }
    } else {
      if (
        result3.data.empcurrentemptypeid !== null &&
        result3.data.datepromotedtocurrentstatus !== null
      ) {
        //Get next promotion details for MR employees'
        let nofyearstopromotion_mr = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeNextPromotionDetails_mr`,
          {
            params: {
              empCurrEmpTypeId: result3.data.empcurrentemptypeid,
            },
          }
        );
        setNextPromotionDetails({
          nextPromotionDate: new Date(
            new Date(
              new Date(result3.data.datepromotedtocurrentstatus).setFullYear(
                new Date(
                  result3.data.datepromotedtocurrentstatus
                ).getFullYear() +
                  parseInt(nofyearstopromotion_mr.data.noofyears)
              )
            ).setMonth(
              new Date(result3.data.datepromotedtocurrentstatus).getMonth() + 1
            )
          ),
          nextPromotionStatus: nofyearstopromotion_mr.data.toemployeetypename,
        });
      } else {
        setNoNextPromotionDetailsError(true);
        console.log(
          "Cannot build promotion pattern for the consolidated employee as certain fields are missing."
        );
      }
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getEmployeeDetails();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-end">
          {unitname === "Gangtok" ? (
            <Fragment>
              {" "}
              <Link to="/highermanagement/employeelistgangtok">
                <button className="bg-gray-900 text-white py-2 px-4 rounded-md focus:outline-none rounded-sm shadow-md flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List </span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          ) : (
            <Fragment>
              <Link to="/highermanagement/employeelistjorethang">
                <button className="bg-gray-900 text-white py-2 px-4 rounded-md focus:outline-none rounded-sm shadow-md flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List</span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          )}
        </div>
        {isLoading ? (
          <Loader message={"fetching employee details...."} />
        ) : (
          <Fragment>
            <div className="grid grid-cols-3">
              <div className="col-span-1 px-4 py-8 flex items-center justify-center border-r border-b">
                <div className="flex items-center justify-center flex-col">
                  <div className="flex flex-col items-center mb-3">
                    {empPersonalDetails.employeephoto ? (
                      <img
                        src={empPersonalDetails.employeephoto}
                        className="h-52 w-46 rounded-md empPassPhoto"
                        alt="employee"
                      ></img>
                    ) : (
                      <img
                        src={noUser}
                        className="h-40 w-40 rounded-full"
                        alt="nouser"
                      ></img>
                    )}
                  </div>
                  <div className="pl-3 text-gray-500 text-base text-center">
                    <p className="text-gray-800 font-medium my-2 text-xl">
                      {empPersonalDetails.employeename}{" "}
                    </p>
                    <p className="flex justify-center mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                      </svg>

                      <span className="ml-1">
                        {empPersonalDetails.employeeaddress}
                      </span>
                    </p>
                    {empPersonalDetails.employeecontact ? (
                      <p className="flex justify-center mb-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                        </svg>
                        <span className="ml-2">
                          {empPersonalDetails.employeecontact}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                    {empAppointmentDetails.has_coi ? (
                      <p className="flex text-green-700 font-medium text-sm justify-center items-center">
                        <span>Employee has COI</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 ml-2"
                          viewBox="0 0 20 20"
                          fill="green"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </p>
                    ) : (
                      <p className="flex text-red-700 font-medium text-sm justify-center">
                        <span>Employee does not have COI</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 ml-2"
                          viewBox="0 0 20 20"
                          fill="#ff8c00"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </p>
                    )}
                    {empPersonalDetails.employeereligion !== null &&
                    empPersonalDetails.employeereligion !== "" ? (
                      <div className="mt-6 text-xs">
                        <span className="px-3 py-1 rounded-sm bg-green-200 text-green-700">
                          Religion -
                          <span className="font-medium">
                            {" "}
                            {empPersonalDetails.employeereligion}
                          </span>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="bg-white p-5 rounded-md mt-10">
                    <div className="flex">
                      <div className="flex flex-col items-center px-4">
                        {empAppointmentDetails.coi_document ? (
                          <Fragment>
                            <a
                              download
                              href={empAppointmentDetails.coi_document}
                              target="_blank"
                            >
                              <img
                                src={empAppointmentDetails.coi_document}
                                className="h-16 rounded-md border-2 border-gray-300 mb-2"
                              ></img>
                            </a>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5"
                              viewBox="0 0 20 20"
                              fill="green"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <img
                              src={noImage}
                              className="h-16 rounded-md border-2 border-gray-300 mb-2"
                            ></img>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5"
                              viewBox="0 0 20 20"
                              fill="gray"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </Fragment>
                        )}
                        <label className="text-xs font-medium pt-2">COI</label>
                      </div>
                      <div className="flex flex-col items-center px-4">
                        {empAppointmentDetails.dob_document ? (
                          <Fragment>
                            <a
                              download
                              href={empAppointmentDetails.dob_document}
                              target="_blank"
                            >
                              <img
                                src={empAppointmentDetails.dob_document}
                                className="h-16 rounded-md border-2 border-gray-300 mb-2"
                              ></img>
                            </a>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5"
                              viewBox="0 0 20 20"
                              fill="green"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <img
                              src={noImage}
                              className="h-16 rounded-md border-2 border-gray-300 mb-2"
                            ></img>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5"
                              viewBox="0 0 20 20"
                              fill="gray"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </Fragment>
                        )}
                        <label className="text-xs font-medium pt-2">
                          Birth Certificate
                        </label>
                      </div>
                      <div className="flex flex-col items-center px-4">
                        {empAppointmentDetails.votercard_document ? (
                          <Fragment>
                            <a
                              download
                              href={empAppointmentDetails.votercard_document}
                              target="_blank"
                            >
                              <img
                                src={empAppointmentDetails.votercard_document}
                                className="h-16 rounded-md border-2 border-gray-300 mb-2"
                              ></img>
                            </a>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5"
                              viewBox="0 0 20 20"
                              fill="green"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <img
                              src={noImage}
                              className="h-16 rounded-md border-2 border-gray-300 mb-2"
                            ></img>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5"
                              viewBox="0 0 20 20"
                              fill="gray"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </Fragment>
                        )}
                        <label className="text-xs font-medium pt-2">
                          Voter Card
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8">
                    <Link
                      to={{
                        pathname: "/highermanagement/acrlist",
                        state: {
                          employeeid: empPersonalDetails.employeeid,
                          employeename: empPersonalDetails.employeename,
                          employeedesignation:
                            empCurrentOfficialDetails.empcurrentdesignationname,
                          employeephoto: empPersonalDetails.employeephoto,
                          employeetype:
                            empCurrentOfficialDetails.empcurrentemptypename,
                          unitname:
                            empCurrentOfficialDetails.empcurrentunitname,
                        },
                      }}
                    >
                      {" "}
                      <button
                        type="button"
                        className="px-5 py-2 text-white transition ease-in flex items-center bg-sky-blue rounded-md"
                      >
                        View ACRs{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 ml-2"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path
                            fill-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-span-2 px-4 pb-6 text-gray-600">
                <p className="text-lg font-medium mb-3 px-2 text-teal-blue">
                  Current Official Details
                </p>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1 bg-blue-100 py-2 px-4 border-r-2 rounded-r">
                    <p className="text-sm">Current employee type</p>
                    <p className="font-bold">
                      {empCurrentOfficialDetails.empcurrentemptypename}
                    </p>
                  </div>
                  <div className="col-span-1 bg-blue-100 py-2 px-4 border-r-2">
                    <p className="text-sm">Current designation</p>
                    <p className="font-bold">
                      {empCurrentOfficialDetails.empcurrentdesignationname}
                    </p>
                  </div>
                  <div className="col-span-1 bg-blue-100 py-2 px-4 border-r-2">
                    <p className="text-sm">Current Level</p>
                    <p className="font-bold">
                      {empCurrentOfficialDetails.empcurrentlevelnumber ===
                        null ||
                      empCurrentOfficialDetails.empcurrentlevelnumber === "" ? (
                        <span>-</span>
                      ) : (
                        `Level - ${empCurrentOfficialDetails.empcurrentlevelnumber}`
                      )}
                    </p>
                  </div>
                  <div className="col-span-1 bg-blue-100 py-2 px-4 border-r-2">
                    <p className="text-sm">Current Unit</p>
                    <p className="font-bold">
                      {empCurrentOfficialDetails.empcurrentunitname}
                    </p>
                  </div>
                  <div className="col-span-1 bg-blue-100 py-2 px-4 border-r-2">
                    <p className="text-sm">Current Section</p>
                    <p className="font-bold">
                      {empCurrentOfficialDetails.empcurrentsectionname}
                    </p>
                  </div>
                  <div className="col-span-1 bg-blue-100 py-2 px-4 border-r-2">
                    <p className="text-sm">Promotion date</p>
                    <p className="font-bold flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      {empCurrentOfficialDetails.datepromotedtocurrentstatus ===
                        null ||
                      empCurrentOfficialDetails.datepromotedtocurrentstatus ===
                        "" ? (
                        <span>-</span>
                      ) : (
                        <span>
                          {" "}
                          {getFormattedDateInputDate(
                            empCurrentOfficialDetails.datepromotedtocurrentstatus
                          )}
                        </span>
                      )}
                    </p>
                  </div>

                  {empCurrentOfficialDetails.empcurrentemptypename ===
                  "Regular" ? (
                    <div className="col-span-1 bg-blue-100 py-2 px-4 border-r-2">
                      <p className="text-sm">Date of Regularization</p>
                      {empCurrentOfficialDetails.dateofregularization !==
                        null &&
                      empCurrentOfficialDetails.dateofregularization !== "" ? (
                        <p className="font-bold">
                          {getFormattedDateInputDate(
                            empCurrentOfficialDetails.dateofregularization
                          )}
                        </p>
                      ) : (
                        <span>-</span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <p className="text-lg text-teal-blue font-medium mb-3 mt-10 pt-4 px-2 border-t">
                  Appointment Details
                </p>
                <div className="px-8 py-6 border bg-white rounded-xl">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-1">
                      <p className="text-sm text-gray-800">Appointment type</p>
                      <p className="font-bold text-gray-600">
                        {empAppointmentDetails.empappointmenttype}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm text-gray-800">Appointed on</p>
                      <p className="font-bold text-gray-600 flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 mr-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>

                        {empAppointmentDetails.empappointmentdate === null ||
                        empAppointmentDetails.empappointmentdate === "" ? (
                          <span>-</span>
                        ) : (
                          <span>
                            {" "}
                            {getFormattedDateInputDate(
                              empAppointmentDetails.empappointmentdate
                            )}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm text-gray-800">
                        Appointed as employee type
                      </p>
                      <p className="font-bold text-gray-600">
                        {empAppointmentDetails.empappointedemptypename}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm text-gray-800">
                        Appointed in designation
                      </p>
                      <p className="font-bold text-gray-600">
                        {empAppointmentDetails.empappointeddesignationname ===
                          "" ||
                        empAppointmentDetails.empappointeddesignationname ===
                          null ? (
                          <span>-</span>
                        ) : (
                          empAppointmentDetails.empappointeddesignationname
                        )}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm text-gray-800">
                        Appointed in level
                      </p>
                      <p className="font-bold text-gray-600">
                        {empAppointmentDetails.empappointedlevelnumber === "" ||
                        empAppointmentDetails.empappointedlevelnumber ===
                          null ? (
                          <span>-</span>
                        ) : (
                          `Level - ${empAppointmentDetails.empappointedlevelnumber}`
                        )}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm text-gray-800">
                        Appointed in section
                      </p>
                      <p className="font-bold text-gray-600">
                        {empAppointmentDetails.empappointedsectionname}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="text-sm text-gray-800">Appointed in unit</p>
                      <p className="font-bold text-gray-600">
                        {empAppointmentDetails.empappointedunitname}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="col-span-1 px-4 border-r py-2">
                <div
                  className="p-5 text-sm mt-2 shadow-lg text-white rounded-lg shadow-lg"
                  style={{ backgroundColor: "#5f9ea0" }}
                >
                  <div className="flex justify-between">
                    <div className="text-lg font-bold mb-4 flex items-center">
                      <span class="animate-ping w-3 h-3 rounded-full bg-white opacity-90 mr-3"></span>

                      <span className="">Next Progression Details</span>
                    </div>
                    <img src={calender} className="h-12 w-12"></img>
                  </div>
                  {noNextPromotionDetailsError ? (
                    <span>
                      ***Promotion pattern could not be built as certain fields
                      are missing.
                    </span>
                  ) : (
                    <div>
                      <p className="mb-4 flex items-center">
                        <span className="text"> Should be promoted to </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                        {empCurrentOfficialDetails.empcurrentemptypename ===
                        "Regular" ? (
                          <span className="font-medium text-lg ml-2">
                            Level - {nextPromotionDetails.nextPromotionStatus}
                          </span>
                        ) : empCurrentOfficialDetails.empcurrentemptypename ===
                          "Consolidated" ? (
                          <span className="font-medium text-lg ml-2 rounded-sm">
                            {nextPromotionDetails.nextPromotionStatus} Employee
                          </span>
                        ) : (
                          <span className="font-medium text-lg ml-2">
                            {nextPromotionDetails.nextPromotionStatus} Employee
                          </span>
                        )}
                      </p>
                      <p className="mb-6 flex items-center">
                        Tentative Promotion Date{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                        <span className="font-medium text-lg ml-2">
                          {getFormattedDateInputDate(
                            nextPromotionDetails.nextPromotionDate
                          )}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="flex justify-between space-x-10">
                    <button
                      type="button"
                      onClick={toggleHandleEmployeeModal}
                      className="p-4 bg-white transform transition  hover:scale-105 w-full white rounded-md text-lg font-medium mt-5 text-gray-700 flex items-center justify-between"
                    >
                      Handle Employee
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={togglePromoteEmployeeModal}
                      className="p-4 bg-white w-full white rounded-md text-lg font-medium mt-5 text-white bg-gray-800 transform transition hover:scale-105 flex justify-between items-center"
                    >
                      Promote Employee
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-span-2 px-4">
                <div className="bg-white">
                  <p className="text-lg text-teal-blue font-medium mb-10 pt-4 px-2 border-t">
                    Employee Career Timeline
                  </p>
                  <EmployeeCareerTimeline
                    empPersonalDetails={empPersonalDetails}
                    appointmentDetails={empAppointmentDetails}
                    currentOfficialDetails={empCurrentOfficialDetails}
                    nextProgressionDetails={nextPromotionDetails}
                    noNextPromotionDetailsError={noNextPromotionDetailsError}
                    empCareerProgressionHistory={empCareerProgressionHistory}
                  />
                </div>
              </div>
            </div>
            <HandleEmployee
              toggleHandleEmployeeModal={toggleHandleEmployeeModal}
              employeeid={empPersonalDetails.employeeid}
              getEmployeeDetails={getEmployeeDetails}
            />

            <PromoteEmployee
              togglePromoteEmployeeModal={togglePromoteEmployeeModal}
              employeeid={empPersonalDetails.employeeid}
              getEmployeeDetails={getEmployeeDetails}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default EmployeeDetails;

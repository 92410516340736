import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";

const AddSection = ({ handleBackToList, actionType, sectionObj }) => {
  const { register, handleSubmit, errors, reset, formState } = useForm();

  const onSubmit = async (data) => {
    if (actionType === "add") {
      // const result = await axios.post("http://localhost:3001/addSection", {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addSection`,
        {
          data: {
            sectionName: data.sectionName,
          },
        }
      );
      if (result != null && result != "") {
        handleBackToList("addsection");
      }
    } else if (actionType === "edit") {
      const result = await axios.post(
        // "http://localhost:3001/editSectionDetails",
        `${process.env.REACT_APP_ENDPOINT_URL}/editSectionDetails`,
        {
          data: {
            sectionid: sectionObj.sectionid,
            sectionname: data.sectionName,
          },
        }
      );

      if (result != null && result != "") {
        handleBackToList("editsection");
      }
    }
  };

  const handleCancel = () => {
    handleBackToList();
  };
  return (
    <Fragment>
      {actionType === "add" ? (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">Add Section </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <p>Section name :</p>
            <input
              name="sectionName"
              type="text"
              className={`border rounded-lg w-3/4 py-1.5 px-3 text-sm outline-none ${
                errors.sectionName ? "border-red-700" : ""
              }`}
              ref={register({ required: true })}
            />
            {errors.sectionName && <Error message="Section name is required" />}
            <br></br>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">
            Edit Section {sectionObj.sectionid}
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <input
              type="hidden"
              name="sectionid"
              defaultValue={sectionObj.sectionid}
            />
            <p>Section name :</p>
            <input
              name="sectionName"
              type="text"
              defaultValue={sectionObj.sectionname}
              className={`border rounded-lg w-3/4 py-1.5 px-3 text-sm outline-none ${
                errors.sectionName ? "border-red-700" : ""
              }`}
              ref={register({ required: true })}
            />
            {errors.sectionName && <Error message="Section name is required" />}
            <br></br>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default AddSection;

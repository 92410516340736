import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import nouser from "../../../assets/noUser.jpg";
import Error from "../../../utilities/Error";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { getFormattedDateForMinMax } from "../../../utilities/functions/UtilityFunctions";

export default function EditSuspension({
  isVisible,
  setIsSuspensionVisible,
  selectedEmployee,
  empUpdateData,
  setEmpProgressionHistory,
  empid,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateSuspension`,
      {
        data: {
          suspensionid: empUpdateData.id,
          datefrom: data.SuspendFrom,
          dateto: data.SuspendTo,
          remarks: data.Remarks,
          updatedby: localStorage.getItem("username"),
        },
      }
    );
    if (result.data) {
      let resultProgression = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCareerProgressionHistoryV2`,
        {
          params: {
            employeeId: empid,
          },
        }
      );
      setEmpProgressionHistory(resultProgression.data);
      setIsSubmitting(false);
      setIsSuspensionVisible(false);
      addToast("Suspension details successfully updated.", {
        appearance: "success",
      });
    } else {
      console.log("ERROR : Could not add increment details.");
    }
  };
  return (
    <div>
      <ToastProvider autoDismiss={true} placement="top-center"></ToastProvider>
      {isVisible && (
        <div
          className="fixed z-20 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <div className="flex items-center">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-red-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <h3
                          className="text-lg leading-6 font-medium text-gray-900 pl-4"
                          id="modal-title"
                        >
                          Suspension
                        </h3>
                      </div>
                      <div class="flex items-center mt-6 justify-center border rounded py-3">
                        <div class="flex-shrink-0 h-20 w-20">
                          {selectedEmployee.employeephoto ? (
                            <img
                              class="h-20 w-20 rounded-full"
                              src={selectedEmployee.employeephoto}
                              alt="user"
                            />
                          ) : (
                            <img
                              class="h-20 w-20 rounded-full"
                              src={nouser}
                              alt="nouser"
                            />
                          )}
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {selectedEmployee.employeename}
                          </div>
                          <div class="text-sm text-gray-500">
                            {selectedEmployee.empdesignationname}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        <p className="font-medium">Suspend from</p>
                        <input
                          name="SuspendFrom"
                          type="date"
                          className={`border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                            errors.SuspendFrom ? "border-red-300" : ""
                          }`}
                          ref={register({ required: true })}
                          defaultValue={getFormattedDateForMinMax(
                            empUpdateData.datefrom
                          )}
                        />
                        {errors.SuspendFrom && (
                          <Error message="Suspend from date  is required" />
                        )}
                        <p className="font-medium mt-4">Suspend to</p>
                        <input
                          name="SuspendTo"
                          type="date"
                          className={`border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                            errors.SuspendTo ? "border-red-300" : ""
                          }`}
                          ref={register({ required: true })}
                          defaultValue={getFormattedDateForMinMax(
                            empUpdateData.dateto
                          )}
                        />
                        {errors.SuspendTo && (
                          <Error message="Suspend to date  is required" />
                        )}
                        <p className="mt-4 font-medium">Remarks</p>
                        <textarea
                          name="Remarks"
                          placeholder="Write remarks here"
                          className={`border border-gray-300 rounded-lg w-full p-3 text-sm outline-none resize-none ${
                            errors.Remarks ? "border-red-300" : ""
                          }`}
                          ref={register({ required: true })}
                          rows={5}
                          defaultValue={empUpdateData.remarks}
                        />
                        {errors.Remarks && (
                          <Error message="Remarks is required" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={isSubmitting ? true : false}
                  >
                    {isSubmitting ? (
                      <Fragment>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Updating</span>
                      </Fragment>
                    ) : (
                      "Update"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setIsSuspensionVisible(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

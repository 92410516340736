import React from "react";

const Loader = ({ message }) => {
  return (
    <div className="modal pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 z-40"></div>
      <div className="bg-white rounded-lg z-50 w-11/12 top-20 max-h-11/12 overflow-y-hidden max-w-sm p-3 flex flex-col items-center justify-center shadow-lg">
        <div className="flex items-center">
          <p>
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className=""
                cx="12"
                cy="12"
                r="10"
                stroke="green"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </p>
          <span>{message}</span>
        </div>
        <p>Please wait...</p>
      </div>
    </div>
  );
};

export default Loader;

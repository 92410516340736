import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../utilities/Loader";
import { useLocation } from "react-router";
import noImage from "../../../assets/noImage.jpg";
import { getFormattedDateInputDate } from "../../../utilities/functions/UtilityFunctions";

const AcrDetails = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const acrid = state.acrid;
  const employeeid = state.employeeid;
  const employeename = state.employeename;
  const employeedesignation = state.employeedesignation;
  const employeephoto = state.employeephoto;
  const employeetype = state.employeetype;
  const [acrDetails, setAcrDetails] = useState({});

  const getAcrDetails = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getAcrDetails`,
      {
        params: {
          acrid,
          employeeid,
        },
      }
    );
    setAcrDetails(result.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getAcrDetails();
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <Loader message={"fetching ACR details..."} />
      ) : (
        <div className="p-6">
          <div className="flex justify-between">
            <span className="font-medium text-2xl">ACR Details </span>
            <Link
              to={{
                pathname: "/highermanagement/acrlist",
                state: {
                  employeeid: employeeid,
                  employeename: employeename,
                  employeedesignation: employeedesignation,
                  employeephoto: employeephoto,
                  employeetype: employeetype,
                },
              }}
            >
              {" "}
              <button className="bg-gray-300 p-2 text-gray-600 focus:outline-none rounded-sm shadow-md flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Back to ACR list </span>
              </button>
            </Link>
          </div>
          <div className="p-6 bg-white mt-8">
            <p className="text-center font-bold">
              THE SIKKIM CO-OPERATIVE MILK PRODUCERS' LIMITED
            </p>
            <p className="text-center">TADONG</p>
            <p className="font-medium underline text-center mt-3">
              INDIVIDUAL ASSESSMENT FORM
            </p>
            <div className="mt-4">
              <img
                src={
                  employeephoto === "" || employeephoto === null
                    ? noImage
                    : employeephoto
                }
                className="h-52 w-46 mx-auto"
              />
              <p className="text-center mt-4">{employeename}</p>
              <p className="text-center text-gray-500">{employeedesignation}</p>
              <p className="text-center text-gray-500 text-green-500 font-bold">
                {employeetype}
              </p>
            </div>
            <div className="flex justify-center space-x-10 mt-8">
              <div className="flex justify-center items-center">
                <p>From period -</p>
                <p className="font-bold text-lg">
                  {getFormattedDateInputDate(acrDetails.fromdate)}
                </p>
              </div>
              <div className="flex justify-center items-center">
                <p>To period -</p>
                <p className="font-bold text-lg">
                  {getFormattedDateInputDate(acrDetails.todate)}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-10 mt-8">
              <div className="col-span-1">
                <p className="text-gray-600">Name:</p>
                <p className="font-medium">{employeename}</p>
              </div>
              <div className="col-span-1">
                <p className="text-gray-600">Type of employement:</p>
                <p className="font-medium">{acrDetails.employmenttype}</p>
              </div>
              <div className="col-span-1">
                <p className="text-gray-600">Date of appointment:</p>
                <p className="font-medium">
                  {getFormattedDateInputDate(acrDetails.dateofappointment)}
                </p>
              </div>
              <div className="col-span-1">
                <p className="text-gray-600">Present designation:</p>
                <p className="font-medium">{acrDetails.designation}</p>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-10 mt-8">
              <div className="col-span-1">
                <p className="text-gray-600">Present pay scale/salary:</p>
                <p className="font-medium">{acrDetails.presentpayscale}</p>
              </div>
              <div className="col-span-1">
                <p className="text-gray-600">Recruitment type:</p>
                <p className="font-medium">{acrDetails.recruitmenttype}</p>
              </div>
              <div className="col-span-1">
                <p className="text-gray-600">
                  Redesignation ? <small>(If any on date):</small>
                </p>
                <p className="font-medium">
                  {acrDetails.redesignationdate !== null &&
                  acrDetails.redesignationdate !== ""
                    ? getFormattedDateInputDate(acrDetails.redesignationdate)
                    : "-"}
                </p>
              </div>
              <div className="col-span-1">
                <p className="text-gray-600">Total no. of years in service</p>
                <p className="font-medium">{acrDetails.totalyearsinservice}</p>
              </div>
            </div>
            {acrDetails.employmenttype !== "MR" ? (
              <Fragment>
                <div className="grid grid-cols-4 gap-10 mt-8">
                  <div className="col-span-1">
                    <p className="text-gray-600">Previous increment date:</p>
                    <p className="font-medium">
                      {getFormattedDateInputDate(
                        acrDetails.previousincrementdate
                      )}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <p className="text-gray-600">Present increment date:</p>
                    <p className="font-medium">
                      {getFormattedDateInputDate(
                        acrDetails.presentincrementdate
                      )}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <p className="text-gray-600">Date of due increment </p>
                    <p className="font-medium">
                      <p className="font-medium">
                        {getFormattedDateInputDate(
                          acrDetails.dateofdueincrement
                        )}
                      </p>
                    </p>
                  </div>
                  <div className="col-span-1">
                    <p className="text-gray-600">Level of rating in number:</p>
                    <p className="font-medium">{acrDetails.levelofrating}</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-10 mt-8">
                  <div className="col-span-1">
                    <p className="text-gray-600">
                      No. of promotions till date:
                    </p>
                    <p className="font-medium">{acrDetails.noofpromotions}</p>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
            <div className="mt-8 border p-6">
              <p
                className="mb-4 font-medium text-white p-3"
                style={{ backgroundColor: "cadetblue" }}
              >
                1. What was your total attendance for the year ?{" "}
                <span className="italic text-sm">(In numbers)</span>
              </p>
            </div>
            <div className="mt-8 border p-6">
              <p
                className="mb-4 font-medium text-white p-3"
                style={{ backgroundColor: "cadetblue" }}
              >
                2. What is the nature of your job role in the organisation ?{" "}
                <p className="italic text-sm">
                  (This includes your daily routine in the organisation)
                </p>{" "}
              </p>
              <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-t-md text-white">
                Employee input
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.natureofjob}
              </div>
              <div className="p-1 bg-purple-400 w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                Controller remarks
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.natureofjob_controller}
              </div>
            </div>

            <div className="mt-8 border p-6">
              <p
                className="mb-4 font-medium text-white p-3"
                style={{ backgroundColor: "cadetblue" }}
              >
                3. What is knowledge about the job role that you currently hold
                ?{" "}
                <p className="italic text-sm">
                  ( This includes : Knowledge/execution of work, Ability to
                  learn, Quality of work delivered & Creativity at workplace to
                  make tasks convenient ){" "}
                </p>{" "}
              </p>
              <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-t-md text-white">
                Employee input
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.knowledgeofjob}
              </div>
              <div className="p-1 bg-purple-400 w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                Controller remarks
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.knowledgeofjob_controller}
              </div>
            </div>

            <div className="mt-8 border p-6">
              <p
                className="mb-4 font-medium text-white p-3"
                style={{ backgroundColor: "cadetblue" }}
              >
                4. What are the duties that you have performed in the last year
                ? ?{" "}
              </p>
              <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-t-md text-white">
                Employee input
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.dutiesperformed}
              </div>
              <div className="p-1 bg-purple-400 w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                Controller remarks
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.dutiesperformed_controller}
              </div>
            </div>

            <div className="mt-8 border p-6">
              <p
                className="mb-4 font-medium text-white p-3"
                style={{ backgroundColor: "cadetblue" }}
              >
                5. What value have you added to the organisation by applying
                your innovative idea and how has the company benefitted ? ? ?{" "}
                <p className="italic text-sm">
                  ( This includes extra work to make your job role valuable or
                  any idea that was profitable for the organisation )
                </p>{" "}
              </p>
              <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-t-md text-white">
                Employee input
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.valuesadded}
              </div>
              <div className="p-1 bg-purple-400 w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                Controller remarks
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.valuesadded_controller}
              </div>
            </div>

            <div className="mt-8 border p-6">
              <p
                className="mb-4 font-medium text-white p-3"
                style={{ backgroundColor: "cadetblue" }}
              >
                6. How well are you aware of Office etiquettes ?
                <p className="italic text-sm">
                  ( This includes : Punctuality, Attendance, Integrity, Personal
                  appearance, dealing with colleagues, public or seniors )
                </p>{" "}
              </p>
              <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-t-md text-white">
                Employee input
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.officeettiquetes}
              </div>
              <div className="p-1 bg-purple-400 w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                Controller remarks
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.officeettiquetes_controller}
              </div>
            </div>

            <div className="mt-8 border p-6">
              <p
                className="mb-4 font-medium text-white p-3"
                style={{ backgroundColor: "cadetblue" }}
              >
                7. how good is your communication ?{" "}
                <p className="italic text-sm">
                  ( This includes : Punctuality, Attendance, Integrity, Personal
                  appearance, dealing with colleagues, public or seniors )
                </p>{" "}
              </p>
              <div className="p-1 bg-gray-600 w-1/4 text-sm text-center rounded-t-md text-white">
                Employee input
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.communication}
              </div>
              <div className="p-1 bg-purple-400 w-1/4 text-sm text-center rounded-t-md text-white mt-6">
                Controller remarks
              </div>{" "}
              <div className="border bg-white rounded-b-lg p-6 border-gray-400">
                {acrDetails.communication_controller}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AcrDetails;

import { Fragment } from "react";
import { Link } from "react-router-dom";

const NotificationBox = ({
  count,
  label,
  bgcolor,
  description,
  path,
  fromPath,
}) => {
  return (
    <div
      className={`rounded-md ${bgcolor} px-6 py-4 border text-white cursor-pointer transform transition duration-500 hover:scale-110`}
    >
      <p className="font-bold text-xs bg-red-600 w-7 h-7 flex items-center justify-center rounded-full mb-3">
        {count}
      </p>
      <p className="font-bold text-lg pb-1">{label}</p>
      <p className="text-sm">{description}</p>

      <div className="border border-white w-min rounded-md px-5 py-2 mt-4">
        <Link
          to={{
            pathname: path,
            state: {
              frompath: fromPath,
            },
          }}
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default NotificationBox;

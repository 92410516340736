import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import nouser from "../../../../assets/noUser.jpg";
import LeaveHistoryList from "./LeaveHistoryList";

export default function EmployeeLeaveConfig() {
  const { state } = useLocation();
  const { addToast } = useToasts();
  const employeedetails = state.employeedetails;
  const unitname = state.unitname;
  const [leaveDetailList, setLeaveDetailList] = useState([]);
  const [isCurrentLeaveDetailLoading, setIsCurrentLeaveDetailLoading] =
    useState(false);

  const getLeaveDetails = async () => {
    try {
      setIsCurrentLeaveDetailLoading(true);
      let result;
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getEmpCurrentLeaveDetails`,
        { params: { employeeid: employeedetails?.employeeid } }
      );
      setLeaveDetailList(result.data);
    } catch (error) {
      addToast("Something went wrong !", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setIsCurrentLeaveDetailLoading(false);
  };

  useEffect(() => {
    getLeaveDetails();
  }, []);

  return (
    <div className="p-6">
      <div className="flex justify-between">
        <span className="font-medium text-xl">Employee Leaves</span>
        {unitname === "Gangtok" ? (
          <Fragment>
            <Link to="/admin/employeelistgangtok" className="mb-5">
              <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Back to Employee List </span>
              </button>
            </Link>
          </Fragment>
        ) : (
          <Fragment>
            <Link to="/admin/employeelistjorethang" className="mb-5">
              <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Back to Employee List</span>
              </button>
            </Link>
          </Fragment>
        )}
      </div>
      <div className="mt-6">
        <div className="grid grid-cols-2 gap-6 bg-white p-4">
          <div className="col-span-1 border-r flex items-center">
            <div class="flex items-center">
              <div class="flex-shrink-0 h-28 w-28">
                {employeedetails?.employeephoto ? (
                  <img
                    class="h-28 w-28 rounded-md"
                    src={employeedetails?.employeephoto}
                    alt="user"
                  />
                ) : (
                  <img class="h-28 w-28 rounded-md" src={nouser} alt="nouser" />
                )}
              </div>
              <div class="px-4">
                <div class="text-sm font-bold text-gray-900 pb-1">
                  {employeedetails?.employeename} (
                  {employeedetails?.empdesignationname})
                </div>
                <div class="text-sm text-gray-800">
                  Employee type : {employeedetails?.emptypename} (
                  {employeedetails?.empunitname})
                </div>
                <div class="text-sm text-gray-800">
                  Contact : {employeedetails?.employeecontact}
                </div>
                <div class="text-sm text-gray-800">
                  Has COI :{" "}
                  {employeedetails?.has_coi ? (
                    <span className="text-green-600">Yes</span>
                  ) : (
                    <span className="text-red-600">No</span>
                  )}
                </div>
                <div class="text-sm text-gray-800">
                  {employeedetails?.isactive ? (
                    <div className="flex items-center">
                      <div className="bg-green-500 rounded-full w-3 h-3 mr-1"></div>
                      Active
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="bg-red-500 rounded-full w-3 h-3 mr-1"></div>
                      InActive
                    </div>
                  )}
                </div>
                {employeedetails?.isactive && (
                  <div className="mt-2 text-sm bg-red-100 text-red-600 w-full py-1 px-2 text-center">
                    Currently on Leave
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-1">
            {leaveDetailList?.length > 0 ? (
              <>
                <div class="text-sm font-bold text-gray-900 pb-2">
                  Current Leave Status
                </div>
                <div className="flex flex-col px-1">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200 text-xs">
                          <thead className="bg-gray-50 text-gray-700 ">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                              >
                                Leave Type
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                              >
                                Leave Remaining
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {leaveDetailList.map((leave, index) => (
                              <tr key={index}>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  {leave.leavetypename}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  {leave.totalleaves} Days
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="p-6 bg-gray-50 text-center font-medium text-gray-500 border text-sm w-full">
                No leave details found !
              </div>
            )}
          </div>
        </div>
      </div>
      <LeaveHistoryList employeedetails={employeedetails} />
    </div>
  );
}

import React, { Fragment, useContext, useState } from "react";
import { LoginContext } from "../context/LoginContext";
import { toggleLogoutModal } from "../modal-functions/ModalFunctions";

export default function LogoutComponent() {
  const [isLogoutPressed, setIsLogoutPressed] = useState(false);
  const { dispatchLogin } = useContext(LoginContext);
  const handleLogoutClick = () => {
    setIsLogoutPressed(true);
    localStorage.removeItem("userid");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    localStorage.removeItem("empid");
    dispatchLogin({
      type: "REMOVE_LOGGEDINUSER",
    });
    toggleLogoutModal();
    setIsLogoutPressed(false);
    window.location.replace("/");
  };
  return (
    <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center logoutModal">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 z-40"></div>
      <div className="bg-white rounded-lg z-50 w-11/12 top-20 max-h-11/12 overflow-y-hidden max-w-sm">
        <div className="w-full rounded-lg p-6">
          <div className="flex justify-between">
            <p className="font-bold text-base">Confirmation Requested</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width="20"
              height="20"
              className="cursor-pointer"
              onClick={toggleLogoutModal}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="py-3">
            <p className="text-justify text-sm">
              Are you sure you want to logout ?
            </p>
          </div>
          <div className="pt-4 flex space-x-4">
            <button
              className="w-1/2 px-4 py-2 focus:outline-none text-center text-gray-600 bg-gray-200 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm shadow-md"
              onClick={toggleLogoutModal}
              type="button"
            >
              Cancel
            </button>
            <button
              className={`w-1/2 px-4 py-2 focus:outline-none text-white shadow-md bg-gradient-to-r from-blue-500 to-blue-400 rounded-lg hover:bg-blue-600 font-bold text-sm flex justify-center`}
              type="button"
              onClick={handleLogoutClick}
              disabled={isLogoutPressed ? true : false}
            >
              {isLogoutPressed ? (
                <Fragment>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Logging you out...</span>
                </Fragment>
              ) : (
                "Logout"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { createContext, useReducer } from "react";

export const LoginContext = createContext();

const initState = {
  loggedInUser: {},
};

const reducer = (loginState, action) => {
  switch (action.type) {
    case "SET_LOGGEDINUSER":
      return {
        ...loginState,
        loggedInUser: action.payload,
      };
    case "REMOVE_LOGGEDINUSER":
      return {
        loggedInUser: {},
      };
    default:
      throw new Error();
  }
};

export const LoginProvider = (props) => {
  const [loginState, dispatchLogin] = useReducer(reducer, initState);
  return (
    <LoginContext.Provider value={{ loginState, dispatchLogin }}>
      {props.children}
    </LoginContext.Provider>
  );
};

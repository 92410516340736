import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../../utilities/Loader";
import nouser from "../../../assets/noUser.jpg";
import axios from "axios";
import { Link } from "react-router-dom";

export default function AcrForwardedEmpList() {
  const [isLoading, setIsLoading] = useState(true);
  const [empList, setEmpList] = useState([]);
  const getEmployeeList = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeListSendForACR`,
      {
        params: {
          unit: localStorage.getItem("unit"),
          sectionid: localStorage.getItem("sectionid"),
        },
      }
    );
    setEmpList(result.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <p className="font-medium text-2xl text-gray-700">
          Employees forwarded for ACR
        </p>
        <div className="rounded-lg mt-4">
          {isLoading ? (
            <Loader message={"fetching employees...."} />
          ) : (
            <Fragment>
              <div className="flex flex-col px-1">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Designation
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Employee Type
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Unit
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">View</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {empList && empList.length > 0 ? (
                            <Fragment>
                              {empList.map((emp, index) => (
                                <TableData key={index} item={emp} />
                              ))}
                            </Fragment>
                          ) : (
                            <tr>
                              <td className="p-3 text-gray-600">
                                No employess found !
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const TableData = ({ item }) => {
  console.log(item);
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-12 w-12">
            {item.employeephoto ? (
              <img
                className="h-12 w-12 rounded-full"
                src={item.employeephoto}
                alt="user"
              />
            ) : (
              <img
                className="h-12 w-12 rounded-full"
                src={nouser}
                alt="nouser"
              />
            )}
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {item.employeename}
            </div>
            {/* <div className="text-sm text-gray-500">jane.cooper@example.com</div> */}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {item.empcurrentdesignationname}
        </div>
        <div className="text-sm text-gray-500">
          {item.empcurrentsectionname}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {item.empcurrentemptypename}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {item.empcurrentunitname}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link
          to={{
            pathname: "/sectionhead/addacr",
            state: {
              employeeid: item.employeeid,
              employeename: item.employeename,
              employeedesignation: item.empcurrentdesignationname,
              employeephoto: item.employeephoto,
              employeetype: item.empcurrentemptypename,
              fromdate: item.frommonth,
              todate: item.tomonth,
              acrtransid: item.acrtransid,
            },
          }}
        >
          <button
            type="button"
            className="px-3 py-2 text-white text-sm rounded-md bg-sky-blue shadow-md"
            id={item.employeeid}
          >
            Add ACR
          </button>
        </Link>
      </td>
    </tr>
  );
};

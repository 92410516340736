import React from "react";
import nouser from "../../../assets/noUser.jpg";

export default function EmployeeDetailsModal({
  showModal,
  setShowModal,
  employeeDetails,
}) {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center text-left flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="p-5 rounded-t">
                  <h3 className="text-xl font-bold border-primary text-gray-700">
                    Employee Details
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 h-full max-h-3xl grid grid-cols-3 gap-4">
                  <div className="col-span-1">
                    {employeeDetails[0].employeephoto ? (
                      <img
                        className="h-20 w-20 rounded-full mx-auto"
                        src={employeeDetails[0].employeephoto}
                        alt="user"
                      />
                    ) : (
                      <img
                        className="h-20 w-20 rounded-full mx-auto"
                        src={nouser}
                        alt="nouser"
                      />
                    )}
                    <div className="text-center">
                      <p className="text-gray-800 font-medium pt-2">
                        {employeeDetails[0].employeename}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2 border-l px-4 flex items-center">
                    <div>
                      <div className="flex pb-1">
                        <p className="text-sm font-medium pr-3">
                          Designation :
                        </p>
                        <p className="text-sm">
                          {employeeDetails[0].empdesignationname}
                        </p>
                      </div>
                      <div className="flex pb-1">
                        <p className="text-sm font-medium pr-3">Section :</p>
                        <p className="text-sm">
                          {employeeDetails[0].empsectionname}
                        </p>
                      </div>
                      <div className="flex pb-1">
                        <p className="text-sm font-medium pr-3">
                          Employee type :
                        </p>
                        <p className="text-sm">
                          {employeeDetails[0].emptypename}
                        </p>
                      </div>
                      <div className="flex pb-1">
                        <p className="text-sm font-medium pr-3">Unit :</p>
                        <p className="text-sm">
                          {employeeDetails[0].empunitname}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end py-4 border-t border-solid border-blueGray-200 rounded-b px-5">
                  <button
                    className="text-sky-blue border border-gray-300 shadow py-3 px-4 text-sm rounded-md"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import LeaveTypeConfigurationList from "./LeaveTypeConfigurationList";
import LeaveTypeList from "./LeaveTypeList";

export default function LeaveTypeConfiguration() {
  const [addLeaveTypeVisible, setAddLeaveTypeVisible] = useState(false);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [employeeTypeList, setEmployeeTypeList] = useState([]);
  const handleLeaveTypeAdd = () => {
    setAddLeaveTypeVisible(true);
  };
  const getLeaveTypeList = async () => {
    try {
      let result;
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getLeaveTypeList`
      );
      setLeaveTypeList(result.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const getEmployeeTypeList = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeList`
    );
    setEmployeeTypeList(result.data);
  };
  useEffect(() => {
    getLeaveTypeList();
    getEmployeeTypeList();
  }, []);
  return (
    <div className="p-6">
      {addLeaveTypeVisible ? (
        <LeaveTypeList
          setAddLeaveTypeVisible={setAddLeaveTypeVisible}
          leaveTypeList={leaveTypeList}
          setLeaveTypeList={setLeaveTypeList}
        />
      ) : (
        <LeaveTypeConfigurationList
          handleLeaveTypeAdd={handleLeaveTypeAdd}
          leaveTypeList={leaveTypeList}
          employeeTypeList={employeeTypeList}
        />
      )}
    </div>
  );
}

import { Fragment } from "react";

const ViewStatisticsButton = ({ label }) => {
  return (
    <Fragment>
      <button className="mt-2 px-3 py-2 text-white rounded-md flex items-center justify-between hover:scale-105 transform transition ease-in m-2 text-sm bg-gray-900">
        {label}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 ml-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
        </svg>
      </button>
    </Fragment>
  );
};

export default ViewStatisticsButton;

import { React, useState } from "react";
import { Link } from "react-router-dom";
import Man from "../../assets/billgates.jpg";
import { getFirstAlphabets } from "../../utilities/functions/UtilityFunctions";
function Sidebar() {
  const [activeSideItem, setActiveSideItem] = useState("dashboard");
  const sideItemClick = (item) => {
    setActiveSideItem(item);
  };
  const [showSubConfigs, setShowSubConfigs] = useState(false);

  const hanldeConfigurationsCLick = () => {
    if (showSubConfigs) {
      setShowSubConfigs(false);
    } else {
      setShowSubConfigs(true);
    }
  };
  return (
    <div className="w-72 text-left h-screen sticky top-0 px-4 bg-gray-900">
      <div className="flex mt-6 mb-10 text-sm text-white align-items justify-">
        <div className="flex items-center">
          <div className="w-12 h-12 bg-white rounded-md flex items-center justify-center">
            <p className="font-medium text-gray-700 text-xl">
              {getFirstAlphabets(localStorage.getItem("role"))}
            </p>
          </div>
          <div className="ml-2">
            <p className="text-xs">Welcome,</p>
            <p className="text-xs font-medium text-gray-400">
              {localStorage.getItem("role")}
            </p>
          </div>
        </div>
      </div>
      <Link to="/highermanagement/highermanagementdashboard">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out ${
            activeSideItem === "dashboard" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("dashboard")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "dashboard"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "dashboard"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Dashboard
            </p>
          </div>
        </p>
      </Link>
      <Link to="/highermanagement/employeelistgangtok">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out ${
            activeSideItem === "gangtokemployees" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("gangtokemployees")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "gangtokemployees"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "gangtokemployees"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Gangtok Employees
            </p>
          </div>
        </p>
      </Link>
      <Link to="/highermanagement/employeelistjorethang">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out ${
            activeSideItem === "jorethangemployees" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("jorethangemployees")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "jorethangemployees"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "jorethangemployees"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Jorethang Employees
            </p>
          </div>
        </p>
      </Link>
      {/* <p
        className="w-auto cursor-pointer group rounded-sm py-2 px-2 text-white"
        onClick={hanldeConfigurationsCLick}
      >
        <div className="flex items-center">
          <div
            className={`p-1 rounded-md mr-3 ${
              activeSideItem === "configuration" ? "bg-gray-800" : ""
            } group-hover:bg-gray-800 group-hover:text-white `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <p
            className={`${
              activeSideItem === "dashboard" ? "text-white opacity-100" : ""
            } group-hover:text-white flex items-center`}
          >
            Configurations
            {showSubConfigs ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </p>
        </div>
      </p>
      {showSubConfigs && (
        <div className="ml-14 text-sm text-white">
          <p
            className={`p-3 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
              activeSideItem === "section" &&
              "bg-gray-800 border-l-2 border-purple-300"
            }`}
            onClick={() => sideItemClick("section")}
          >
            <Link to="/sectionconfiguration">Section</Link>
          </p>
          <p
            className={`p-3 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
              activeSideItem === "designation" &&
              "bg-gray-800 border-l-2 border-purple-300"
            }`}
            onClick={() => sideItemClick("designation")}
          >
            <Link to="/designationconfiguration">Designation</Link>
          </p>
          <p
            className={`p-3 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
              activeSideItem === "employeetype" &&
              "bg-gray-800 border-l-2 border-purple-300"
            }`}
            onClick={() => sideItemClick("employeetype")}
          >
            <Link to="/employeetypeconfiguration">Employee Type</Link>
          </p>
          <p
            className={`p-3 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
              activeSideItem === "level" &&
              "bg-gray-800 border-l-2 border-purple-300"
            }`}
            onClick={() => sideItemClick("level")}
          >
            <Link to="/levelconfiguration">Level</Link>
          </p>
          <p
            className={`p-3 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
              activeSideItem === "promotionpattern" &&
              "bg-gray-800 border-l-2 border-purple-300"
            }`}
            onClick={() => sideItemClick("promotionpattern")}
          >
            <Link to="/promotionpatternconfiguration">Promotion Pattern</Link>
          </p>
          <p
            className={`p-3 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
              activeSideItem === "levelpattern" &&
              "bg-gray-800 border-l-2 border-purple-300"
            }`}
            onClick={() => sideItemClick("levelpattern")}
          >
            <Link to="/levelpatternconfiguration">Level Pattern</Link>
          </p>
        </div>
      )}  */}
    </div>
  );
}

export default Sidebar;

import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { toggleDeleteModal } from "../../../modal-functions/ModalFunctions";
import Loader from "../../../utilities/Loader";

const SectionList = ({
  handleSectionEdit,
  setSectionObj,
  setDeleteSectionObj,
  sectionList,
  setSectionList,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = async (e) => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionDetailsById`,
      {
        params: {
          sectionid: e.currentTarget.id,
        },
      }
    );

    setSectionObj({
      sectionid: result.data.rows[0].sectionid,
      sectionname: result.data.rows[0].sectionname,
    });

    handleSectionEdit();
  };

  const handleDelete = async (e) => {
    toggleDeleteModal();
    setDeleteSectionObj({
      sectionid: e.currentTarget.id,
    });
  };

  const getSectionList = async () => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionList`
    );
    console.log(result.data);
    setSectionList(result.data);
    setIsLoading(false);
  };
  useEffect(() => {
    getSectionList();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <Loader message="Fetching section list." />
      ) : (
        <div className="">
          {sectionList && sectionList.length > 0 ? (
            <div className="flex flex-col px-1">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 text-sm">
                      <thead className="bg-gray-50 text-gray-700 ">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                          >
                            Section name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-right font-medium uppercase tracking-wider"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {sectionList.map((section) => (
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              {section.sectionname}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap flex items-center justify-end">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 cursor-pointer"
                                onClick={handleEdit}
                                id={section.sectionid}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 cursor-pointer ml-3"
                                id={section.sectionid}
                                onClick={handleDelete}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-6 bg-white text-center font-medium">
              No sections configured.
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default SectionList;

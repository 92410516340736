import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastProvider, useToasts } from "react-toast-notifications";
import axios from "axios";
import Error from "../../../utilities/Error";
import nouser from "../../../assets/noUser.jpg";

const AddEmpProgressionHistory = ({
  isVisible,
  selectedEmployee,
  setIsProgressionVisible,
}) => {
  const [promotionHistoryList, setPromotionHistoryList] = useState([]);
  const [error, setError] = useState(false);
  const [designationList, setDesignationList] = useState();
  const [levelList, setLevelList] = useState();
  const [sectionList, setSectionList] = useState();
  const [employeeTypeList, setEmployeeTypeList] = useState();
  const [progressionType, setProgressionType] = useState();
  const [progressionTo, setProgressionTo] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const progressionTypes = [
    "Section Change",
    "Unit Transfer",
    "Upgradation",
    "Designation promotion",
    "Level promotion",
    "Redesignation",
  ];
  const unitList = ["Gangtok", "Jorethang"];
  const { addToast } = useToasts();
  const { register, handleSubmit, errors, formState, reset } = useForm();
  const handleProgressionTypeChange = (e) => {
    setProgressionType(e.target.value);
  };

  const handleProgressionToChange = (e) => {
    setProgressionTo(e.target.value);
  };

  async function getDesignationList() {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getDesignationList`
    );
    setDesignationList(result.data);
  }
  async function getLevelList() {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getLevelList`
    );
    setLevelList(result.data);
  }
  async function getSectionList() {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionList`
    );
    setSectionList(result.data);
    console.log("Section", result.data);
  }
  async function getEmployeeTypeList() {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeList`
    );
    setEmployeeTypeList(result.data);
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeCareerProgressionHistory`,
      {
        data: {
          empId: selectedEmployee.employeeid,
          progressionType: progressionType,
          progressionTo: data.progressionTo,
          progressionDate: data.progressionDate,
          remarks: data.remarks,
        },
      }
    );
    if (result.data) {
      setIsSubmitting(false);
      setIsProgressionVisible(false);
      reset();
      setProgressionType("");
      addToast("Career Progression successfully added.", {
        appearance: "success",
      });
    } else {
      console.log("ERROR : Could not add career progression history.");
    }
  };

  useEffect(() => {
    getDesignationList();
    getLevelList();
    getSectionList();
    getEmployeeTypeList();
  }, []);
  return (
    <Fragment>
      {isVisible && (
        <div
          className="fixed z-20 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <ToastProvider
            autoDismiss={true}
            placement="top-center"
          ></ToastProvider>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <div className="flex items-center">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-yellow-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                          </svg>
                        </div>
                        <h3
                          className="text-lg leading-6 font-medium text-gray-900 pl-4"
                          id="modal-title"
                        >
                          Progression History
                        </h3>
                      </div>
                      <div class="flex items-center mt-6 justify-center border rounded py-3">
                        <div class="flex-shrink-0 h-20 w-20">
                          {selectedEmployee.employeephoto ? (
                            <img
                              class="h-20 w-20 rounded-full"
                              src={selectedEmployee.employeephoto}
                              alt="user"
                            />
                          ) : (
                            <img
                              class="h-20 w-20 rounded-full"
                              src={nouser}
                              alt="nouser"
                            />
                          )}
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {selectedEmployee.employeename}
                          </div>
                          <div class="text-sm text-gray-500">
                            {selectedEmployee.empdesignationname}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        <p className="font-medium">Progression Type</p>
                        <select
                          name="progressionType"
                          ref={register({ required: true })}
                          className="border border-gray-300 rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                          onChange={handleProgressionTypeChange}
                          value={progressionType}
                        >
                          <option value="">--select progression type-- </option>
                          {progressionTypes &&
                            progressionTypes.map((x) => {
                              return <option value={x}>{x}</option>;
                            })}
                        </select>
                        {errors.progressionType && (
                          <Error message="Progression type is required" />
                        )}

                        <p className="font-medium mt-4">Progression To</p>
                        <select
                          name="progressionTo"
                          ref={register({ required: true })}
                          onChange={handleProgressionToChange}
                          className="border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                          value={progressionTo}
                        >
                          <option value="">--select progression to-- </option>
                          {progressionType === "Unit Transfer" ? (
                            <Fragment>
                              {unitList &&
                                unitList.map((x) => {
                                  return <option value={x}>{x}</option>;
                                })}{" "}
                            </Fragment>
                          ) : progressionType === "Upgradation" ? (
                            <Fragment>
                              {employeeTypeList &&
                                employeeTypeList.map((x) => {
                                  return (
                                    <option value={x.employeetypeid}>
                                      {x.employeetypename}
                                    </option>
                                  );
                                })}
                            </Fragment>
                          ) : progressionType === "Designation promotion" ? (
                            <Fragment>
                              {designationList &&
                                designationList.map((x) => {
                                  return (
                                    <option value={x.designationid}>
                                      {x.designationname}
                                    </option>
                                  );
                                })}
                            </Fragment>
                          ) : progressionType === "Level promotion" ? (
                            <Fragment>
                              {levelList &&
                                levelList.map((x) => {
                                  return (
                                    <option value={x.levelid}>
                                      {" "}
                                      Level {x.levelnumber}
                                    </option>
                                  );
                                })}
                            </Fragment>
                          ) : progressionType === "Redesignation" ? (
                            <Fragment>
                              {designationList &&
                                designationList.map((x) => {
                                  return (
                                    <option value={x.designationid}>
                                      {x.designationname}
                                    </option>
                                  );
                                })}
                            </Fragment>
                          ) : progressionType === "Section Change" ? (
                            <Fragment>
                              {sectionList &&
                                sectionList.map((x) => {
                                  return (
                                    <option value={x.sectionid}>
                                      {x.sectionname}
                                    </option>
                                  );
                                })}
                            </Fragment>
                          ) : (
                            ""
                          )}
                        </select>
                        {errors.progressionTo && (
                          <Error message="Progression to is required" />
                        )}
                        <p className="font-medium mt-4">Progression Date</p>
                        <input
                          type="date"
                          ref={register({ required: true })}
                          className="border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                          name="progressionDate"
                        />
                        {errors.progressionDate && (
                          <Error message="Progression date is required" />
                        )}
                        <p className="font-medium mt-4">Remarks (If any)</p>
                        <textarea
                          type="text"
                          className="border border-gray-300 rounded-lg w-full h-20  py-1.5 px-3 text-sm outline-none resize-none"
                          placeholder="Remarks"
                          autoComplete="off"
                          ref={register({ required: false })}
                          name="remarks"
                          rows={5}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={isSubmitting ? true : false}
                  >
                    {isSubmitting ? (
                      <Fragment>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Submitting</span>
                      </Fragment>
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setIsProgressionVisible(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* <div className="p-6">
        <div className="flex justify-between items-center">
          <span className="font-medium text-xl text-gray-700">
            Add Employee Career Progression History
          </span>
          {unitname === "Gangtok" ? (
            <Fragment>
              <Link to="/admin/employeelistgangtok">
                <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center shadow-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List </span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          ) : (
            <Fragment>
              <Link to="/admin/employeelistjorethang">
                <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center shadow-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List</span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          )}
        </div>
        <div className="mt-9">
          <div className="grid grid-cols-5 gap-10">
            <div className="col-span-1">
              <div className="px-6 rounded-md">
                <img
                  src={
                    employeephoto === "" || employeephoto === null
                      ? noImage
                      : employeephoto
                  }
                  className="mx-auto h-52 w-46 rounded-md mb-3"
                />
                <p className="text-lg font-medium text-center">
                  {employeename}
                </p>
                <p className="text-center text-gray-500">
                  {employeedesignation}
                </p>
                <p className="text-center text-gray-500 text-green-500 font-bold">
                  {emptype}
                </p>
              </div>
            </div>
            <div className="col-span-4">
              <ToastProvider autoDismiss={true} placement="top-center">
                <Fragment>
                  <div className="p-4 bg-white shadow">
                    <div className="grid grid-cols-6 gap-10 p-3 rounded-md text-gray-700 font-medium">
                      <div className="col-span-2">Progression type</div>
                      <div className="col-span-2">Progression to</div>
                      <div className="col-span-2">Date</div>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="text-left"
                    >
                      <div className="grid grid-cols-6 gap-10 flex items-center mb-3">
                        <div className="col-span-2">
                          <select
                            name="progressionType"
                            ref={register({ required: true })}
                            className="border border-gray-300 rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                            onChange={handleProgressionTypeChange}
                            value={progressionType}
                          >
                            <option value="">
                              --select progression type--{" "}
                            </option>
                            {progressionTypes &&
                              progressionTypes.map((x) => {
                                return <option value={x}>{x}</option>;
                              })}
                          </select>
                          {errors.progressionType && (
                            <Error message="Progression type is required" />
                          )}
                        </div>
                        <div className="col-span-2">
                          {" "}
                          <select
                            name="progressionTo"
                            ref={register({ required: true })}
                            onChange={handleProgressionToChange}
                            className="border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                            value={progressionTo}
                          >
                            <option value="">--select progression to-- </option>
                            {progressionType === "Unit Transfer" ? (
                              <Fragment>
                                {unitList &&
                                  unitList.map((x) => {
                                    return <option value={x}>{x}</option>;
                                  })}{" "}
                              </Fragment>
                            ) : progressionType === "Upgradation" ? (
                              <Fragment>
                                {employeeTypeList &&
                                  employeeTypeList.map((x) => {
                                    return (
                                      <option value={x.employeetypeid}>
                                        {x.employeetypename}
                                      </option>
                                    );
                                  })}
                              </Fragment>
                            ) : progressionType === "Designation promotion" ? (
                              <Fragment>
                                {designationList &&
                                  designationList.map((x) => {
                                    return (
                                      <option value={x.designationid}>
                                        {x.designationname}
                                      </option>
                                    );
                                  })}
                              </Fragment>
                            ) : progressionType === "Level promotion" ? (
                              <Fragment>
                                {levelList &&
                                  levelList.map((x) => {
                                    return (
                                      <option value={x.levelid}>
                                        {" "}
                                        Level {x.levelnumber}
                                      </option>
                                    );
                                  })}
                              </Fragment>
                            ) : progressionType === "Redesignation" ? (
                              <Fragment>
                                {designationList &&
                                  designationList.map((x) => {
                                    return (
                                      <option value={x.designationid}>
                                        {x.designationname}
                                      </option>
                                    );
                                  })}
                              </Fragment>
                            ) : progressionType === "Section Transfer" ? (
                              <Fragment>
                                {sectionList &&
                                  sectionList.map((x) => {
                                    return (
                                      <option value={x.sectionid}>
                                        {x.sectionname}
                                      </option>
                                    );
                                  })}
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </select>
                          {errors.progressionTo && (
                            <Error message="Progression to is required" />
                          )}
                        </div>

                        <div className="col-span-2">
                          <input
                            type="date"
                            ref={register({ required: true })}
                            className="border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                            name="progressionDate"
                          />
                          {errors.progressionDate && (
                            <Error message="Progression date is required" />
                          )}
                        </div>
                      </div>
                      <div className="text-gray-700 font-medium p-3 rounded-md mt-4">
                        Remarks (if any)
                      </div>
                      <div>
                        <textarea
                          type="text"
                          className="border border-gray-300 rounded-lg w-full h-20  py-1.5 px-3 text-sm outline-none"
                          placeholder="Remarks"
                          autoComplete="off"
                          ref={register({ required: false })}
                          name="remarks"
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="mt-6 focus:outline-none rounded-sm py-2 px-6 bg-sky-blue text-white flex items-center shadow-lg"
                        onClick={handleSubmit}
                        disabled={isSubmitting ? true : false}
                      >
                        {isSubmitting ? (
                          <Fragment>
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            <span>Submitting</span>
                          </Fragment>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </form>
                  </div>
                </Fragment>
              </ToastProvider>
            </div>
          </div>
          <EmployeeProgressionHistory empid={state.employeeid} />
        </div>
      </div> */}
    </Fragment>
  );
};

export default AddEmpProgressionHistory;

import React, { Fragment, useEffect } from "react";
import Error from "../../../utilities/Error";

function PerformanceAssessment({
  scoreObject,
  setScoreObject,
  totalScore,
  setTotalScore,
  register,
  errors,
}) {
  let total = 0;
  const calculateTotal = (e) => {
    let changedValue = parseInt(e.target.value || 0);

    switch (e.target.id) {
      case "kow":
        total = parseInt(totalScore) - parseInt(scoreObject.kow);
        setScoreObject({
          ...scoreObject,
          kow: e.target.value,
        });
        break;
      case "sb":
        total = parseInt(totalScore) - parseInt(scoreObject.sb);
        setScoreObject({
          ...scoreObject,
          sb: e.target.value,
        });
        break;

      case "atl":
        total = parseInt(totalScore) - parseInt(scoreObject.atl);
        setScoreObject({
          ...scoreObject,
          atl: e.target.value,
        });
        break;

      case "punc":
        total = parseInt(totalScore) - parseInt(scoreObject.punc);
        setScoreObject({
          ...scoreObject,
          punc: e.target.value,
        });
        break;
      case "quanwork":
        total = parseInt(totalScore) - parseInt(scoreObject.quanwork);
        setScoreObject({
          ...scoreObject,
          quanwork: e.target.value,
        });
        break;

      case "qualwork":
        total = parseInt(totalScore) - parseInt(scoreObject.qualwork);
        setScoreObject({
          ...scoreObject,
          qualwork: e.target.value,
        });
        break;

      case "integrity":
        total = parseInt(totalScore) - parseInt(scoreObject.integrity);
        setScoreObject({
          ...scoreObject,
          integrity: e.target.value,
        });
        break;

      case "leadership":
        total = parseInt(totalScore) - parseInt(scoreObject.leadership);
        setScoreObject({
          ...scoreObject,
          leadership: e.target.value,
        });
        break;

      case "integrity":
        total = parseInt(totalScore) - parseInt(scoreObject.integrity);
        setScoreObject({
          ...scoreObject,
          integrity: e.target.value,
        });
        break;

      case "cs":
        total = parseInt(totalScore) - parseInt(scoreObject.cs);
        setScoreObject({
          ...scoreObject,
          cs: e.target.value,
        });
        break;

      case "creativity":
        total = parseInt(totalScore) - parseInt(scoreObject.creativity);
        setScoreObject({
          ...scoreObject,
          creativity: e.target.value,
        });
        break;

      case "dependibility":
        total = parseInt(totalScore) - parseInt(scoreObject.dependibility);
        setScoreObject({
          ...scoreObject,
          dependibility: e.target.value,
        });
        break;

      case "pa":
        total = parseInt(totalScore) - parseInt(scoreObject.pa);
        setScoreObject({
          ...scoreObject,
          pa: e.target.value,
        });
        break;

      case "coop":
        total = parseInt(totalScore) - parseInt(scoreObject.coop);
        setScoreObject({
          ...scoreObject,
          coop: e.target.value,
        });
        break;

      case "initiative":
        total = parseInt(totalScore) - parseInt(scoreObject.initiative);
        setScoreObject({
          ...scoreObject,
          initiative: e.target.value,
        });
        break;
    }
    total = parseInt(total) + parseInt(changedValue);
    setTotalScore(total);
  };

  return (
    <div className="border rounded-md">
      <div className="grid grid-cols-4 border-b font-bold">
        <div className="py-2 text-center border-r">
          <p>Particulars</p>
        </div>
        <div className="py-2 text-center border-r">
          <p>Score</p>
        </div>
        <div className="py-2 text-center border-r">
          <p>Particulars</p>
        </div>
        <div className="py-2 text-center">
          <p>Score</p>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 flex items-center p-2 pb-4">
        <div className="p-2">
          <p>Knowledge of work (10)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="kow"
            id="kow"
            min={0}
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full kow ${
              errors.kow && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            max={10}
          />
          {errors.kow && <Error message="Knowledge of work is required" />}
        </div>
        <div className="p-2">
          <p>Social Behaviour (7)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="sb"
            id="sb"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.sb && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={7}
          />
          {errors.sb && <Error message="Social behaviour is required" />}
        </div>

        <div className="p-2">
          <p>Ability to learn (8)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="atl"
            id="atl"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.atl && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={8}
          />
          {errors.atl && <Error message="Ability to learn is required" />}
        </div>

        <div className="p-2">
          <p>Punctuality (7)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="punc"
            id="punc"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.punc && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={7}
          />
          {errors.punc && <Error message="Punctuality is required" />}
        </div>

        <div className="p-2">
          <p>Quantity of work (8)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="quanwork"
            id="quanwork"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.quanwork && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={8}
          />
          {errors.quanwork && <Error message="Quantity of work is required" />}
        </div>

        <div className="p-2">
          <p>Quality of work (8)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="qualwork"
            id="qualwork"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.qualwork && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={8}
          />
          {errors.qualwork && <Error message="Quality of work is required" />}
        </div>

        <div className="p-2">
          <p>Integrity (7)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="integrity"
            id="integrity"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.integrity && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={7}
          />
          {errors.integrity && <Error message="Integrity is required" />}
        </div>

        <div className="p-2">
          <p>Leadership (6)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="leadership"
            id="leadership"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.leadership && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={6}
          />
          {errors.leadership && <Error message="Leadership is required" />}
        </div>

        <div className="p-2">
          <p>Communication skills (8)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="cs"
            id="cs"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.cs && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={8}
          />
          {errors.cs && <Error message="Communication skills is required" />}
        </div>

        <div className="p-2">
          <p>Creativity (6)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="creativity"
            id="creativity"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.creativity && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={6}
          />
          {errors.creativity && <Error message="Creativity is required" />}
        </div>

        <div className="p-2">
          <p>Dependability (7)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="dependibility"
            id="dependibility"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.dependibility && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={7}
          />
          {errors.dependibility && (
            <Error message="Dependibility is required" />
          )}
        </div>

        <div className="p-2">
          <p>Personal Appearance (5)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="pa"
            id="pa"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.pa && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={5}
          />
          {errors.pa && <Error message="Personal appearance is required" />}
        </div>

        <div className="p-2">
          <p>Co-operation (6)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="coop"
            id="coop"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.coop && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={6}
          />
          {errors.coop && <Error message="Co-operation is required" />}
        </div>

        <div className="p-2">
          <p>Initiative (7)</p>
        </div>
        <div className="p-2">
          <input
            type="number"
            name="initiative"
            id="initiative"
            onChange={calculateTotal}
            ref={register({ required: true })}
            className={`p-2 border rounded-md focus:outline-none w-full ${
              errors.initiative && "border-red-300"
            }`}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min={0}
            max={7}
          />
          {errors.initiative && <Error message="Initiative is required" />}
        </div>
        <p className="pl-2 font-bold pt-4">Total</p>
        <p className="pl-2 font-bold pt-4">{totalScore}</p>
      </div>
    </div>
  );
}

export default PerformanceAssessment;

import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Error from "../../../utilities/Error";
import { useForm } from "react-hook-form";
import PerformanceAssessment from "./PerformanceAssessment";
import { getFormattedDateInputDate } from "../../../utilities/functions/UtilityFunctions";

const AddACR = () => {
  const { state } = useLocation();

  const [totalScore, setTotalScore] = useState(0);
  const [empAppointmentDate, setEmpAppointmentDate] = useState("");
  const unitname = state.unitname;
  const employeeid = state.employeeid;
  const employeename = state.employeename;
  const employeetype = state.employeetype;
  const employeedesignation = state.employeedesignation;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scoreObject, setScoreObject] = useState({
    kow: 0,
    sb: 0,
    atl: 0,
    punc: 0,
    quanwork: 0,
    qualwork: 0,
    integrity: 0,
    leadership: 0,
    cs: 0,
    creativity: 0,
    dependibility: 0,
    pa: 0,
    coop: 0,
    initiative: 0,
  });
  const { register, handleSubmit, errors, formState } = useForm();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const scoreObjectJson = JSON.stringify(scoreObject);
    const result = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeACR`,
      {
        data: {
          empid: employeeid,
          fromdate: data.fromdate,
          todate: data.todate,
          mrwage: data.mrwage,
          dateupgradedtoconsolidatedpay: data.dateupgradeconsolidatedpay,
          presentconsolidatedpay: data.presentconsolidatedpay,
          previousconsolidatedpay: data.previousconsolidatedpay,
          rateofincrement: data.rateofincrement,
          conspayafterincrement: data.conspayafterincrement,
          presentpayscale: data.presentpayscale,
          presentbasicpay: data.presentbasicpay,
          duerateincrement: data.duerateincrement,
          ratinglevel: data.ratinglevel,
          noincrementallowed: data.noincrementallowed,
          rateofincrementallowed: data.rateofincrementallowed,
          futurebasicpay: data.futurebasicpay,
          previncrementdate: data.previncrementdate,
          presentincrementdate: data.presentincrementdate,
          sugestionforimprovement: data.sugestionforimprovement,
          totalscore: totalScore,
          createdby: localStorage.getItem("username"),
          score_object: scoreObjectJson,
          designation: employeedesignation,
          employeetype: employeetype,
          appointmentdate: empAppointmentDate,
        },
      }
    );
    console.log("ROCKSTAR : ", result.data);
    if (result.data) {
      if (unitname === "Gangtok") {
        window.location.replace(
          `${process.env.REACT_APP_REDIRECT_URL}admin/employeelistgangtok`
        );
      } else {
        window.location.replace(
          `${process.env.REACT_APP_REDIRECT_URL}admin/employeelistjorethang`
        );
      }
    } else {
      console.log("could not add acr, some error occured !!");
    }
  };

  const getEmpDateOfAppointment = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeDateOfAppointment`,
      {
        params: {
          employeeId: employeeid,
        },
      }
    );
    setEmpAppointmentDate(result.data);
  };

  useEffect(() => {
    getEmpDateOfAppointment();
  }, []);

  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between">
          <span className="font-medium text-xl">Add ACR</span>
          {unitname === "Gangtok" ? (
            <Fragment>
              {" "}
              <Link to="/admin/employeelistgangtok">
                <button className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List </span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          ) : (
            <Fragment>
              <Link to="/admin/employeelistjorethang">
                <button className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List</span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          )}
        </div>{" "}
        <div>
          <div className="px-4 py-10 bg-white rounded-md w-4/5 m-auto">
            <p className="text-lg text-gray-700 text-center font-medium mb-8">
              ANNUAL ASSESSMENT FORM (
              {employeetype === "Consolidated Employee" ? (
                <span>For Consolidated Staff</span>
              ) : employeetype === "Regular Employee" ? (
                <span>For Regular Staff</span>
              ) : (
                <span>For Muster Roll staff</span>
              )}
              )
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-11/12 m-auto grid grid-cols-3 py-4 gap-6 text-sm text-gray-600">
                <div>
                  <label className="pl-2 font-bold">From</label>
                  <input
                    type="date"
                    className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
                      errors.fromdate && "border-red-300"
                    }`}
                    name="fromdate"
                    ref={register({ required: true })}
                  />
                  {errors.fromdate && <Error message="Date is required" />}
                </div>
                <div>
                  <label className="pl-2 font-bold">To</label>
                  <input
                    type="date"
                    className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
                      errors.todate && "border-red-300"
                    }`}
                    name="todate"
                    ref={register({ required: true })}
                  />
                  {errors.todate && <Error message="Date is required" />}
                </div>
                <Title title="Particulars of the Employee" />
                <div>
                  <label className="pl-2 font-bold">Name</label>
                  <input
                    type="text"
                    className="p-2 border rounded-md focus:outline-none w-full mt-2 bg-gray-300 opacity-60"
                    value={employeename}
                    disabled={true}
                  />
                </div>
                <div>
                  <label className="pl-2 font-bold">Designation</label>
                  <input
                    type="text"
                    className="p-2 border rounded-md focus:outline-none w-full mt-2 bg-gray-300 opacity-60"
                    value={employeedesignation}
                    disabled={true}
                  />
                </div>
                <div>
                  <label className="pl-2 font-bold">Appointment date</label>
                  <input
                    type="text"
                    className="p-2 border rounded-md focus:outline-none w-full mt-2 bg-gray-300 opacity-60"
                    value={getFormattedDateInputDate(empAppointmentDate)}
                    disabled={true}
                  />
                </div>
                {employeetype === "Consolidated Employee" ||
                employeetype === "Regular Employee" ? (
                  <Fragment>
                    <div>
                      <label className="pl-2 font-bold">
                        Previous increment date
                      </label>
                      <input
                        type="date"
                        className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
                          errors.previncrementdate && "border-red-300"
                        }`}
                        name="previncrementdate"
                        ref={register({ required: true })}
                      />
                      {errors.previncrementdate && (
                        <Error message="Date is required" />
                      )}
                    </div>
                    <div>
                      <label className="pl-2 font-bold">
                        Present increment date
                      </label>
                      <input
                        type="date"
                        className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
                          errors.presentincrementdate && "border-red-300"
                        }`}
                        name="presentincrementdate"
                        ref={register({ required: true })}
                      />
                      {errors.presentincrementdate && (
                        <Error message="Date is required" />
                      )}
                    </div>
                  </Fragment>
                ) : (
                  ""
                )}
                {employeetype === "Consolidated Employee" ? (
                  <ConsolidatedSection register={register} errors={errors} />
                ) : employeetype === "Regular Employee" ? (
                  <RegularSection register={register} errors={errors} />
                ) : (
                  <MRSection register={register} errors={errors} />
                )}
                <Title title="Assessment of Performance" />
                <div className="col-span-3">
                  <PerformanceAssessment
                    scoreObject={scoreObject}
                    setScoreObject={setScoreObject}
                    totalScore={totalScore}
                    setTotalScore={setTotalScore}
                    register={register}
                    errors={errors}
                  />
                </div>
                {/* <Title title="Rating" />
                <p>Outstanding</p> */}
                <Title title="Suggestion for improvement" />
                <textarea
                  className={`border p-2 focus:outline-none col-span-3 rounded-md resize-none ${
                    errors.sugestionforimprovement && "border-red-300"
                  }`}
                  name="sugestionforimprovement"
                  ref={register({ required: true })}
                />
                {errors.sugestionforimprovement && (
                  <Error message="Suggestion is required" />
                )}
                <div>
                  <button
                    className={`px-4 py-2 focus:outline-none bg-green-500 text-white shadow-md ${
                      formState.isSubmitting ? "opacity-50" : "opacity-100"
                    }  rounded-lg font-bold text-sm flex justify-center`}
                    type="submit"
                    disabled={formState.isSubmitting ? true : false}
                  >
                    {isSubmitting ? (
                      <Fragment>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Submitting</span>
                      </Fragment>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* ) : ( */}
          {/* <div className="px-4 py-10 bg-white flex-row items-center justify-center text-center shadow-lg rounded-md w-4/5 m-auto">
            <p className="flex justify-center text-green-500 mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-16"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </p>
            <p>
              ACR successfully filled for the employee -{" "}
              <label className="font-bold italic"> {employeename}</label>{" "}
            </p>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

const MRSection = ({ register, errors }) => {
  return (
    <div className="">
      <label className="pl-2 font-bold">Present Muster Roll Wage</label>
      <input
        type="text"
        className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
          errors.mrwage && "border-red-300"
        }`}
        name="mrwage"
        ref={register({ required: true })}
      />
      {errors.mrwage && (
        <Error message="Present Muster Roll Wage is required" />
      )}
    </div>
  );
};
const ConsolidatedSection = ({ register, errors }) => {
  return (
    <Fragment>
      <div>
        <label className="pl-2 font-bold">
          Date of upgradation to consolidated pay
        </label>
        <input
          type="date"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.dateupgradeconsolidatedpay && "border-red-300"
          }`}
          name="dateupgradeconsolidatedpay"
          ref={register({ required: true })}
        />
        {errors.dateupgradeconsolidatedpay && (
          <Error message="Date is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Present consolidated pay</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.presentconsolidatedpay && "border-red-300"
          }`}
          name="presentconsolidatedpay"
          ref={register({ required: true })}
        />
        {errors.presentconsolidatedpay && (
          <Error message="Present consolidated pay is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Previous consolidated pay</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.previousconsolidatedpay && "border-red-300"
          }`}
          name="previousconsolidatedpay"
          ref={register({ required: true })}
        />
        {errors.previousconsolidatedpay && (
          <Error message="Previous consolidated pay is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Rate of increment</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.rateofincrement && "border-red-300"
          }`}
          name="rateofincrement"
          ref={register({ required: true })}
        />
        {errors.rateofincrement && (
          <Error message="Rate of increment is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">
          Consolidated pay after increment
        </label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.conspayafterincrement && "border-red-300"
          }`}
          name="conspayafterincrement"
          ref={register({ required: true })}
        />
        {errors.conspayafterincrement && (
          <Error message="Consolidated pay after increment is required" />
        )}
      </div>
    </Fragment>
  );
};
const RegularSection = ({ register, errors }) => {
  return (
    <Fragment>
      <div>
        <label className="pl-2 font-bold">Present pay scale</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.presentpayscale && "border-red-300"
          }`}
          name="presentpayscale"
          ref={register({ required: true })}
        />
        {errors.presentpayscale && (
          <Error message="Present pay scale is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Present basic pay</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.presentbasicpay && "border-red-300"
          }`}
          name="presentbasicpay"
          ref={register({ required: true })}
        />
        {errors.presentbasicpay && (
          <Error message="Present basic pay is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Rate of due increment</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.duerateincrement && "border-red-300"
          }`}
          name="duerateincrement"
          ref={register({ required: true })}
        />
        {errors.duerateincrement && (
          <Error message="Rate of due increment is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Level of rating</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.ratinglevel && "border-red-300"
          }`}
          name="ratinglevel"
          ref={register({ required: true })}
        />
        {errors.ratinglevel && <Error message="Level of rating is required" />}
      </div>
      <div>
        <label className="pl-2 font-bold">No of increments allowed</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.noincrementallowed && "border-red-300"
          }`}
          name="noincrementallowed"
          ref={register({ required: true })}
        />
        {errors.noincrementallowed && (
          <Error message="No of increment allowed is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Rate of increment allowed</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.rateofincrementallowed && "border-red-300"
          }`}
          name="rateofincrementallowed"
          ref={register({ required: true })}
        />
        {errors.rateofincrementallowed && (
          <Error message="Rate of increment allowed is required" />
        )}
      </div>
      <div>
        <label className="pl-2 font-bold">Future basic pay</label>
        <input
          type="text"
          className={`p-2 border rounded-md focus:outline-none w-full mt-2 ${
            errors.futurebasicpay && "border-red-300"
          }`}
          name="futurebasicpay"
          ref={register({ required: true })}
        />
        {errors.futurebasicpay && (
          <Error message="Future basic pay is required" />
        )}
      </div>
    </Fragment>
  );
};
const Title = ({ title }) => {
  return (
    <div className="col-span-3">
      <button
        className="font-medium pl-2 flex items-center bg-dark-blue text-white rounded-md py-1 focus:outline-none"
        type="button"
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default AddACR;

import { React, useState } from "react";
import { Link } from "react-router-dom";
import Man from "../../assets/billgates.jpg";
import { getFirstAlphabets } from "../../utilities/functions/UtilityFunctions";
function Sidebar() {
  const [activeSideItem, setActiveSideItem] = useState("dashboard");
  const sideItemClick = (item) => {
    setActiveSideItem(item);
  };
  const [showSubConfigs, setShowSubConfigs] = useState(false);
  const [showSubReports, setShowSubReports] = useState(false);

  const hanldeConfigurationsCLick = () => {
    setShowSubConfigs(!showSubConfigs);
  };

  const hanldeReportsCLick = () => {
    setShowSubReports(!showSubReports);
  };
  return (
    <div className="w-96 text-left h-screen sticky top-0 px-4 bg-gray-900">
      <div className="flex mt-6 mb-10 text-sm text-white align-items">
        <div className="flex items-center">
          <div className="w-10 h-10 bg-white rounded-md flex items-center justify-center">
            <p className="font-bold text-gray-700 text-lg">
              {getFirstAlphabets(localStorage.getItem("role"))}
            </p>
          </div>
          <div className="ml-2">
            <p className="text-xs">Welcome,</p>
            <p className="text-xs font-medium text-gray-400">
              {localStorage.getItem("role")}
            </p>
          </div>
        </div>
      </div>
      <Link to="/admin/systemadmindashboard">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out text-sm ${
            activeSideItem === "dashboard" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("dashboard")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "dashboard"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "dashboard"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Dashboard
            </p>
          </div>
        </p>
      </Link>

      <Link to="/admin/employeelistgangtok">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out text-sm ${
            activeSideItem === "employee" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("employee")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "employee"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "employee"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Gangtok Employees
            </p>
          </div>
        </p>
      </Link>

      <Link to="/admin/employeelistjorethang">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out text-sm ${
            activeSideItem === "jorethangemployees" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("jorethangemployees")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "jorethangemployees"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "jorethangemployees"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Jorethang Employees
            </p>
          </div>
        </p>
      </Link>

      <p
        className="w-auto cursor-pointer group rounded-sm py-2 px-2 text-white text-sm"
        onClick={hanldeConfigurationsCLick}
      >
        <div className="flex items-center">
          <div
            className={`p-1 rounded-md mr-3 ${
              activeSideItem === "configuration"
                ? "bg-gray-800 text-white"
                : "opacity-60"
            } group-hover:bg-sky-blue-dark `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <p
            className={`${
              activeSideItem === "configuration"
                ? "text-white opacity-100"
                : "text-white opacity-60"
            } group-hover:text-white flex items-center justify-center`}
          >
            Configurations
            {showSubConfigs ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </p>
        </div>
      </p>
      {showSubConfigs && (
        <div className="ml-14 text-sm text-white transition ease-in">
          <Link to="/sectionconfiguration">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5${
                activeSideItem === "section" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("section")}
            >
              Section
            </p>
          </Link>
          <Link to="/designationconfiguration">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "designation" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("designation")}
            >
              Designation
            </p>
          </Link>
          <Link to="/employeetypeconfiguration">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "employeetype" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("employeetype")}
            >
              Employee Type
            </p>
          </Link>
          <Link to="/levelconfiguration">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "level" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("level")}
            >
              Level
            </p>
          </Link>
          <Link to="/promotionpatternconfiguration">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "promotionpattern" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("promotionpattern")}
            >
              Promotion Pattern
            </p>
          </Link>
          <Link to="/levelpatternconfiguration">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "levelpattern" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("levelpattern")}
            >
              Level Pattern
            </p>
          </Link>
          <Link to="/leavetypeconfiguration">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "leavetype" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("leavetype")}
            >
              Leave Types
            </p>
          </Link>
        </div>
      )}

      <p
        className="w-auto cursor-pointer group rounded-sm py-2 px-2 text-white text-sm"
        onClick={hanldeReportsCLick}
      >
        <div className="flex items-center">
          <div
            className={`p-1 rounded-md mr-3 ${
              activeSideItem === "reports"
                ? "bg-gray-800 text-white"
                : "opacity-60"
            } group-hover:bg-sky-blue-dark `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <p
            className={`${
              activeSideItem === "reports"
                ? "text-white opacity-100"
                : "text-white opacity-60"
            } group-hover:text-white flex items-center justify-center`}
          >
            Reports
            {showSubReports ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </p>
        </div>
      </p>
      {showSubReports && (
        <div className="ml-14 text-sm text-white transition ease-in">
          <Link to="/admin/employeeType">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "Employeetype" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("Employeetype")}
            >
              Employee Type
            </p>
          </Link>
          <Link to="/admin/levelWiseReport">
            <p
              className={`p-1 cursor-pointer transition duration-500 ease-in-out rounded-sm transform hover:-translate-y-0.5 ${
                activeSideItem === "LevelWiseReport" && "bg-sky-blue-dark"
              }`}
              onClick={() => sideItemClick("LevelWiseReport")}
            >
              Level Wise
            </p>
          </Link>
        </div>
      )}

      <Link to="/admin/userlist">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out text-sm ${
            activeSideItem === "userlist" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("userlist")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "userlist"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "userlist"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              User Management
            </p>
          </div>
        </p>
      </Link>

      <Link to="/admin/inactive/gangtok">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out text-sm ${
            activeSideItem === "inactive employee gtk" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("inactive employee gtk")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "inactive employee gtk"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "inactive employee gtk"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Gangtok Inactive Employees
            </p>
          </div>
        </p>
      </Link>

      <Link to="/admin/inactive/jorethang">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out text-sm ${
            activeSideItem === "inactive employee jrt" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("inactive employee jrt")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "inactive employee jrt"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "inactive employee jrt"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Jorethang Inactive Employees
            </p>
          </div>
        </p>
      </Link>
    </div>
  );
}

export default Sidebar;

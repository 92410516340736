import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./TopBar";
import SectionHeadDashboard from "./SectionHeadDashboard";
import EmployeeList from "../SectionHead/ACR/EmployeeList";
import AcrList from "./ACR/AcrList";
import AddAcr from "./ACR/AddAcr";
import AcrDetails from "./ACR/AcrDetails";
import ChangePassword from "../Components/ChangePassword";
import { ToastProvider } from "react-toast-notifications";
import AcrForwardedEmpList from "./ACR/AcrForwardedEmpList";

const SectionHeadHome = () => {
  return (
    <Fragment>
      <Router>
        <div className="bg-gray-200 flex">
          <Sidebar />
          <div className="flex flex-col w-full">
            <Topbar />
            <Switch>
              <Route path="/sectionhead/changepassword">
                <ToastProvider>
                  <ChangePassword />
                </ToastProvider>
              </Route>
              <Route path="/sectionhead/sectionheaddashboard">
                <SectionHeadDashboard />
              </Route>
              <Route path="/sectionhead/employeelist">
                <EmployeeList />
              </Route>
              <Route path="/sectionhead/acrlist">
                <AcrList />
              </Route>
              <Route path="/sectionhead/addacr">
                <AddAcr />
              </Route>
              <Route path="/sectionhead/acrdetails">
                <AcrDetails />
              </Route>
              <Route path="/sectionhead/acrforwardedempList">
                <AcrForwardedEmpList />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </Fragment>
  );
};

export default SectionHeadHome;

import { React, useState } from "react";
import { Link } from "react-router-dom";
import Man from "../../assets/billgates.jpg";
import { getFirstAlphabets } from "../../utilities/functions/UtilityFunctions";
function Sidebar() {
  const [activeSideItem, setActiveSideItem] = useState("dashboard");
  const sideItemClick = (item) => {
    setActiveSideItem(item);
  };
  const [showSubConfigs, setShowSubConfigs] = useState(false);

  const hanldeConfigurationsCLick = () => {
    if (showSubConfigs) {
      setShowSubConfigs(false);
    } else {
      setShowSubConfigs(true);
    }
  };
  return (
    <div className="w-72 text-left h-screen sticky top-0 px-4 bg-gray-900">
      <div className="flex mt-6 mb-10 text-sm text-white align-items justify-">
        <div className="flex items-center">
          <div className="w-12 h-12 bg-white rounded-md flex items-center justify-center">
            <p className="font-medium text-gray-700 text-xl">
              {getFirstAlphabets(localStorage.getItem("role"))}
            </p>
          </div>
          <div className="ml-2">
            <p className="text-sm font-medium">Welcome,</p>
            <p className="text-xs text-gray-200">
              {localStorage.getItem("role")}
            </p>
          </div>
        </div>
      </div>
      <Link to="/accounts/accountsdashboard">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out ${
            activeSideItem === "dashboard" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("dashboard")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "dashboard"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "dashboard"
                  ? "text-white opacity-100"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Dashboard
            </p>
          </div>
        </p>
      </Link>
      <Link to="/accounts/employeelistgangtok">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out ${
            activeSideItem === "gangtokemployees" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("gangtokemployees")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "gangtokemployees"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "gangtokemployees"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Gangtok Employees
            </p>
          </div>
        </p>
      </Link>
      <Link to="/accounts/employeelistjorethang">
        <p
          className={`w-auto cursor-pointer group rounded-sm py-2 px-2 text-white transition duration-500 ease-in-out ${
            activeSideItem === "jorethangemployees" && "bg-sky-blue-dark"
          }`}
          onClick={() => sideItemClick("jorethangemployees")}
        >
          <div className="flex items-center">
            <div
              className={`p-1 rounded-md mr-3 ${
                activeSideItem === "jorethangemployees"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:bg-sky-blue-dark group-hover:text-white `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <p
              className={`${
                activeSideItem === "jorethangemployees"
                  ? "text-white"
                  : "text-white opacity-60"
              } group-hover:text-white`}
            >
              Jorethang Employees
            </p>
          </div>
        </p>
      </Link>
    </div>
  );
}

export default Sidebar;

import axios from "axios";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import Error from "../../../utilities/Error";
import { getFormattedDateInputDate } from "../../../utilities/getFormattedDateInputDate";
import GeneralDeleteModal from "../../../version2/ReusableComponents/GeneralDeleteModal";

export default function LeaveTypeConfigurationList({
  handleLeaveTypeAdd,
  leaveTypeList,
  employeeTypeList,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [leaveTypeConfigurationList, setLeaveTypeConfigurationList] = useState(
    []
  );
  const [selectedConfiguration, setSelectedConfiguration] = useState();
  const [selectedConfigurationId, setSelectedConfigurationId] = useState();

  const handleEdit = (leavetypeconfiguration) => {
    setSelectedConfiguration(leavetypeconfiguration);
  };
  const handleDelete = (leavetypeconfiguration) => {
    setShowDeleteModal(true);
    setSelectedConfigurationId(leavetypeconfiguration?.configid);
  };
  const onDelete = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/deleteLeaveTypeConfiguration`,
        {
          data: {
            configid: parseInt(selectedConfigurationId),
          },
        }
      );
      if (result.data.status === 200) {
        getLeaveTypeConfigurations();
        addToast("Successfully deleted configuration !", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast("Something went wrong !", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast("Something went wrong !", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setShowDeleteModal(false);
  };
  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addLeaveTypeConfiguration`,
        {
          data: {
            configid:
              selectedConfiguration !== undefined
                ? selectedConfiguration?.configid
                : 0,
            leavetypeid: parseInt(data.leavetype),
            employeetypeid: parseInt(data.employeetype),
            numberofdays: parseInt(data.numberofdays),
            startdate: data.startdate,
            createdby: localStorage.getItem("username"),
          },
        }
      );
      if (result.data.status === 200) {
        getLeaveTypeConfigurations();
        addToast(`Configuration successfully ${result.data.message} !`, {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        if (result.data.message.includes("unique_leave_pair")) {
          addToast("Cannot insert duplicate records !", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast("Something went wrong !", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      setSelectedConfiguration();
      setIsSubmitting(false);
      reset();
    } catch (error) {
      addToast("Something went wrong !", {
        appearance: "error",
        autoDismiss: true,
      });
      setIsSubmitting(false);
    }
  };

  const getLeaveTypeConfigurations = async () => {
    try {
      let result;
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getLeaveTypeConfigurationList`
      );
      console.log("RESULT", result.data);
      setLeaveTypeConfigurationList(result.data);
    } catch (error) {
      addToast("Something went wrong !", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  useState(() => {
    getLeaveTypeConfigurations();
  }, []);
  return (
    <div>
      <div className="flex justify-between items-center">
        <span className="font-medium text-xl">Leave Type Configuration</span>
        <button
          className="bg-sky-blue px-3 py-2 text-white focus:outline-none rounded-md flex items-center shadow-lg text-sm font-medium"
          onClick={handleLeaveTypeAdd}
        >
          <span>Leave Type List</span>
        </button>
      </div>
      <GeneralDeleteModal
        type="Leave Configuration"
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        excuteFunction={onDelete}
      />
      <div className="bg-white p-4 my-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="border p-6">
            <legend className="font-bold">Configuration</legend>
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-1">
                <p className="font-medium">Leave Type</p>
                <select
                  name="leavetype"
                  ref={register({ required: true })}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.leavetype ? "border-red-700" : ""
                  }`}
                >
                  {selectedConfiguration !== undefined ? (
                    <>
                      {leaveTypeList
                        ?.filter(
                          (leavetype) =>
                            parseInt(leavetype.leavetypeid) ===
                            parseInt(selectedConfiguration?.leavetypeid)
                        )
                        .map((leavetype) => {
                          return (
                            <option value={leavetype.leavetypeid}>
                              {leavetype.leavetypename}
                            </option>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <option value="">--select leave type-- </option>
                      {leaveTypeList?.map((leavetype) => {
                        return (
                          <option value={leavetype.leavetypeid}>
                            {leavetype.leavetypename}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                {errors.leavetype && <Error message="Leave type is required" />}
              </div>
              <div className="col-span-1">
                <p className="font-medium">Employee Type</p>
                <select
                  name="employeetype"
                  ref={register({ required: true })}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.employeetype ? "border-red-700" : ""
                  }`}
                >
                  {selectedConfiguration !== undefined ? (
                    <>
                      {employeeTypeList
                        ?.filter(
                          (emptype) =>
                            parseInt(emptype.employeetypeid) ===
                            parseInt(selectedConfiguration?.employeetypeid)
                        )
                        .map((emptype) => {
                          return (
                            <option value={emptype.employeetypeid}>
                              {emptype.employeetypename}
                            </option>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <option value="">--select employee type-- </option>
                      {employeeTypeList?.map((emptype) => {
                        return (
                          <option value={emptype.employeetypeid}>
                            {emptype.employeetypename}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                {errors.employeetype && (
                  <Error message="Employee type is required" />
                )}
              </div>
              <div className="col-span-1">
                <p className="font-medium">No of Days</p>
                <input
                  type="number"
                  name="numberofdays"
                  placeholder="Enter number of days"
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.numberofdays ? "border-red-700" : ""
                  }`}
                  ref={register({ required: true })}
                  defaultValue={
                    selectedConfiguration !== undefined
                      ? selectedConfiguration?.numberofdays
                      : ""
                  }
                />
                {errors.numberofdays && (
                  <Error message="No of days is required" />
                )}
              </div>
              {/* <div className="col-span-1">
                <p className="font-medium">Extension allowed for</p>
                <input
                  name="extendnumberofdays"
                  placeholder="Enter number of days"
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.extendnumberofdays ? "border-red-700" : ""
                  }`}
                  ref={register({ required: false })}
                />
              </div> */}
              <div className="col-span-1">
                <p className="font-medium">Start Date</p>
                <input
                  name="startdate"
                  type="date"
                  className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                    errors.startdate ? "border-red-700" : ""
                  }`}
                  ref={register({ required: true })}
                />
                {errors.startdate && <Error message="Start date is required" />}
              </div>
            </div>
            <div className="pt-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                disabled={isSubmitting ? true : false}
              >
                {isSubmitting ? (
                  <Fragment>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Submitting</span>
                  </Fragment>
                ) : selectedConfiguration !== undefined ? (
                  "Update Configuation"
                ) : (
                  "Add Configuation"
                )}
              </button>
              {selectedConfiguration !== undefined && (
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setSelectedConfiguration();
                    reset();
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </fieldset>
        </form>
      </div>

      <div className="bg-white p-4">
        <p className="font-bold text-center border-b pb-2">
          Leave Configurations
        </p>
        {leaveTypeConfigurationList?.length > 0 ? (
          <div className="flex flex-col px-1 mt-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 text-sm">
                    <thead className="bg-gray-800 text-white">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                        >
                          Sl No
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                        >
                          Leave Type
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                        >
                          Employment type
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                        >
                          No of days
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                        >
                          Start date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-right font-medium uppercase tracking-wider"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {leaveTypeConfigurationList.map((leavetype, index) => (
                        <tr>
                          <td className="px-6 py-2 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap">
                            {leavetype.leavetypename}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap">
                            {leavetype.employeetypename}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap">
                            {leavetype.numberofdays}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap">
                            {getFormattedDateInputDate(leavetype.startdate)}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap flex items-center justify-end">
                            <div onClick={() => handleEdit(leavetype)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5  cursor-pointer"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                            </div>
                            <div onClick={() => handleDelete(leavetype)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 ml-4 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-6 bg-white text-center font-medium">
            No leave types configurations found !
          </div>
        )}
      </div>
    </div>
  );
}

import React, { Fragment, useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";

const AddLevelPattern = ({
  handleBackToList,
  actionType,
  levelPatternObj,
  levelList,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const appointmentTypes = ["Muster Roll", "Direct Appointment"];
  const [appointmentType, setAppointmentType] = useState();
  const [fromLevelId, setFromLevelId] = useState();
  const [toLevelId, setToLevelId] = useState();
  const [isConsolidated, setIsConsolidated] = useState(false);

  const handleIsConsolidatedChange = (e) => {
    if (e.target.checked) {
      setIsConsolidated(true);
    } else {
      setIsConsolidated(false);
    }
  };

  const handleAppointmentTypeChange = (e) => {
    setAppointmentType(e.target.value);
  };
  const onSubmit = async (data) => {
    if (actionType === "add") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addLevelPattern`,
        {
          data: {
            fromLevelId: data.fromLevelId,
            toLevelId: data.toLevelId,
            noOfYears: data.noOfYears,
            appointmentType: data.appointmentType,
            isConsolidated: isConsolidated,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("addlevelpattern");
      }
    } else if (actionType === "edit") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/editLevelPatternDetails`,
        {
          data: {
            levelPatternId: levelPatternObj.levelPatternId,
            fromLevelId: data.fromLevelId,
            toLevelId: data.toLevelId,
            noOfYears: data.noOfYears,
            appointmentType: data.appointmentType,
            isConsolidated: data.isconsolidated,
          },
        }
      );

      if (result !== null && result !== "") {
        handleBackToList("editlevelpattern");
      }
    }
  };
  const handleFromLevelChange = (e) => {
    setFromLevelId(e.target.value);
  };

  const handleToLevelChange = (e) => {
    setToLevelId(e.target.value);
  };

  const handleCancel = () => {
    handleBackToList();
  };

  const setAppointmentTypeEdit = () => {
    setAppointmentType(levelPatternObj.appointmentType);
    setIsConsolidated(levelPatternObj.isConsolidated);
  };

  useEffect(() => {
    if (actionType !== "add") {
      setAppointmentTypeEdit();
    }
  }, []);
  return (
    <Fragment>
      {actionType === "add" ? (
        <div className="bg-white w-3/4 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">
            Add Level Pattern{" "}
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <div className="grid grid-cols-5 gap-0 flex items-center">
              <div className="col-span-2">
                <p>From Level</p>
                <select
                  name="fromLevelId"
                  ref={register({ required: true })}
                  onChange={handleFromLevelChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.fromLevelId ? "border-red-700" : ""
                  }`}
                  value={fromLevelId}
                >
                  <option value="">--select level-- </option>
                  {levelList &&
                    levelList.map((x) => {
                      return (
                        <option value={x.levelid.toString()}>
                          Level {x.levelnumber}
                        </option>
                      );
                    })}
                </select>
                {errors.fromLevelId && (
                  <Error message="From Level is required" />
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 text-theme-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="col-span-2">
                <p>To Level</p>
                <select
                  name="toLevelId"
                  ref={register({ required: true })}
                  onChange={handleToLevelChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.toLevelId ? "border-red-700" : ""
                  }`}
                  value={toLevelId}
                >
                  <option value="">--select level-- </option>
                  {levelList &&
                    levelList.map((x) => {
                      return (
                        <option value={x.levelid.toString()}>
                          Level {x.levelnumber}
                        </option>
                      );
                    })}
                </select>
                {errors.toLevelId && <Error message="To level is required" />}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10 mt-4 flex items-center">
              <div className="col-span-1">
                <p>Appointment type</p>
                <select
                  name="appointmentType"
                  ref={register({ required: true })}
                  onChange={handleAppointmentTypeChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.appointmentType ? "border-red-700" : ""
                  }`}
                  value={appointmentType}
                >
                  <option value="">--select appointment type-- </option>
                  {appointmentTypes &&
                    appointmentTypes.map((x) => {
                      return <option value={x}>{x}</option>;
                    })}
                </select>
                {errors.appointmenttype && (
                  <Error message="Appointment type is required" />
                )}{" "}
              </div>
              <div>
                {" "}
                <div className="flex items-center pl-2">
                  <input
                    type="checkbox"
                    style={{ width: "16px", height: "16px" }}
                    className="mr-2 mt-4 cursor-pointer"
                    name="isconsolidated"
                    value={isConsolidated}
                    onChange={handleIsConsolidatedChange}
                    ref={register({ required: false })}
                  />{" "}
                  <span className="mt-4"> Deduct consolidated years ? </span>
                  <br />
                </div>
              </div>
            </div>

            <p className="mt-3">No. of years :</p>
            <input
              name="noOfYears"
              type="number"
              className={`border rounded-lg w-1/2 py-1.5 px-3 text-sm outline-none ${
                errors.noOfYears ? "border-red-700" : ""
              }`}
              ref={register({ required: true })}
            />
            {errors.noOfYears && <Error message="No. of years required" />}

            <br></br>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">
            Edit Level Pattern
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <div className="grid grid-cols-5 gap-0 flex items-center">
              <div className="col-span-2">
                <p>From Level</p>
                <select
                  name="fromLevelId"
                  ref={register({ required: true })}
                  defaultValue={levelPatternObj.fromLevelId}
                  onChange={handleFromLevelChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.fromLevelId ? "border-red-700" : ""
                  }`}
                  value={fromLevelId}
                >
                  <option value="">--select level-- </option>
                  {levelList &&
                    levelList.map((x) => {
                      return (
                        <option value={x.levelid.toString()}>
                          Level {x.levelnumber}
                        </option>
                      );
                    })}
                </select>
                {errors.fromLevelId && (
                  <Error message="From Level is required" />
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 text-theme-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="col-span-2">
                <p>To Level</p>
                <select
                  name="toLevelId"
                  defaultValue={levelPatternObj.toLevelId}
                  ref={register({ required: true })}
                  onChange={handleToLevelChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.toLevelId ? "border-red-700" : ""
                  }`}
                  value={toLevelId}
                >
                  <option value="">--select level-- </option>
                  {levelList &&
                    levelList.map((x) => {
                      return (
                        <option value={x.levelid.toString()}>
                          Level {x.levelnumber}
                        </option>
                      );
                    })}
                </select>
                {errors.toLevelId && <Error message="To level is required" />}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10 mt-4 flex items-center">
              <div className="col-span-1">
                <p>Appointment type</p>
                <select
                  name="appointmentType"
                  ref={register({ required: true })}
                  onChange={handleAppointmentTypeChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.appointmentType ? "border-red-700" : ""
                  }`}
                  value={appointmentType}
                >
                  <option value="">--select appointment type-- </option>
                  {appointmentTypes &&
                    appointmentTypes.map((x) => {
                      return <option value={x}>{x}</option>;
                    })}
                </select>
                {errors.appointmenttype && (
                  <Error message="Appointment type is required" />
                )}{" "}
              </div>
              <div>
                {" "}
                <div className="flex items-center pl-2">
                  <input
                    type="checkbox"
                    style={{ width: "16px", height: "16px" }}
                    className="mr-2 mt-4 cursor-pointer"
                    name="isconsolidated"
                    defaultChecked={
                      levelPatternObj.isConsolidated ? true : false
                    }
                    value={isConsolidated}
                    onChange={handleIsConsolidatedChange}
                    ref={register({ required: false })}
                  />{" "}
                  <span className="mt-4"> Deduct consolidated years ? </span>
                  <br />
                </div>
              </div>
            </div>

            <p className="mt-3">No. of years :</p>
            <input
              name="noOfYears"
              type="number"
              defaultValue={levelPatternObj.noOfYears}
              className={`border rounded-lg w-1/2 py-1.5 px-3 text-sm outline-none ${
                errors.noOfYears ? "border-red-700" : ""
              }`}
              ref={register({ required: true })}
            />
            {errors.noOfYears && <Error message="No. of years required" />}

            <br></br>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default AddLevelPattern;

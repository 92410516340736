import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastProvider, useToasts } from "react-toast-notifications";
import Error from "../../../../utilities/Error";
import { getFormattedDateInputDateYYYYMMDD } from "../../../../utilities/getFormattedDateInputDate";

export default function LeaveEntryModal({
  isVisible,
  setIsLeaveModalVisible,
  employeedetails,
  getLeaveHistoryList,
}) {
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const getLeaveTypeList = async () => {
    try {
      setIsLoading(true);
      let result;
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getLeaveTypeList`
      );
      setLeaveTypeList(result.data);
    } catch (error) {
      console.log("Error", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getLeaveTypeList();
  }, []);
  const onSubmit = async (data) => {
    let date1 = new Date(data.datefrom);
    let date2 = new Date(data.dateto);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    try {
      setIsSubmitting(true);
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeLeaveEntry`,
        {
          data: {
            employeeid: parseInt(employeedetails?.employeeid),
            leavetypeid: parseInt(data.leavetype),
            datefrom: data.datefrom,
            dateto: data.dateto,
            reason: data.reason,
            numberofdays: parseInt(Difference_In_Days),
            createdby: localStorage.getItem("username"),
          },
        }
      );
      if (result.data.status === 200) {
        getLeaveHistoryList();
        addToast("Leave details added !", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast("Something went wrong !", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      reset();
      setIsSubmitting(false);
      setIsLeaveModalVisible();
    } catch (error) {
      addToast("Something went wrong !", {
        appearance: "error",
        autoDismiss: true,
      });
      setIsSubmitting(false);
    }
  };
  return (
    <div>
      <div>
        <ToastProvider
          autoDismiss={true}
          placement="top-center"
        ></ToastProvider>
        {isVisible && (
          <div
            className="fixed z-20 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                        <div className="flex items-center">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-green-600"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <h3
                            className="text-lg leading-6 font-medium text-gray-900 pl-4"
                            id="modal-title"
                          >
                            Leave Entry
                          </h3>
                        </div>
                        <div className="mt-6">
                          <p className="font-medium">Leave Type</p>
                          <select
                            name="leavetype"
                            ref={register({ required: true })}
                            className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                              errors.leavetype ? "border-red-700" : ""
                            }`}
                          >
                            <option value="">--select leave type-- </option>
                            {leaveTypeList?.map((leavetype) => {
                              return (
                                <option value={leavetype.leavetypeid}>
                                  {leavetype.leavetypename}
                                </option>
                              );
                            })}
                          </select>
                          {errors.leavetype && (
                            <Error message="Leave type is required" />
                          )}
                          <p className="font-medium mt-2">From date</p>
                          <input
                            name="datefrom"
                            type="date"
                            className={`border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                              errors.datefrom ? "border-red-300" : ""
                            }`}
                            ref={register({ required: true })}
                            min={getFormattedDateInputDateYYYYMMDD(new Date())}
                          />
                          {errors.datefrom && (
                            <Error message="From date is required" />
                          )}
                          <p className="font-medium mt-2">To date</p>
                          <input
                            name="dateto"
                            type="date"
                            className={`border border-gray-300 rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                              errors.dateto ? "border-red-300" : ""
                            }`}
                            ref={register({ required: true })}
                            min={getFormattedDateInputDateYYYYMMDD(new Date())}
                          />
                          {errors.dateto && (
                            <Error message="To date is required" />
                          )}
                          <p className="mt-4 font-medium">Reason</p>
                          <textarea
                            name="reason"
                            placeholder="Write remarks here"
                            className={`border border-gray-300 rounded-lg w-full p-3 text-sm outline-none resize-none ${
                              errors.reason ? "border-red-300" : ""
                            }`}
                            ref={register({ required: true })}
                            rows={5}
                          />
                          {errors.reason && (
                            <Error message="Reason is required" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                      disabled={isSubmitting ? true : false}
                    >
                      {isSubmitting ? (
                        <Fragment>
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <span>Submitting</span>
                        </Fragment>
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setIsLeaveModalVisible(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

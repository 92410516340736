import React, { useState } from "react";
import AddDesignation from "./AddDesignation";
import DesignationList from "./DesignationList";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DeleteModal from "../../../utilities/DeleteModal";

const DesignationConfiguration = () => {
  const [designationList, setDesignationList] = useState();
  const [isDesignationList, setIsDesignationList] = useState(true);
  const [isDesignationAdd, setIsDesignationAdd] = useState(false);
  const [designationObj, setDesignationObj] = useState({
    designationId: "",
    designationName: "",
    designationHeirarchy: "",
  });
  const [deleteDesignationObj, setDeleteDesignationObj] = useState({
    designationId: "",
  });
  const [actionType, setActionType] = useState("");
  const { addToast } = useToasts();

  const handleDesignationAdd = () => {
    setIsDesignationAdd(true);
    setIsDesignationList(false);
    setActionType("add");
  };

  const handleDesignationEdit = (e) => {
    setIsDesignationAdd(true);
    setIsDesignationList(false);
    setActionType("edit");
  };
  const handleBackToList = (calledFrom, deletedId) => {
    setIsDesignationAdd(false);
    setIsDesignationList(true);
    setActionType("");
    if (calledFrom === "adddesignation") {
      addToast("Designation successfully added.", {
        appearance: "success",
      });
    }
    if (calledFrom === "editdesignation") {
      addToast("Designation successfully updated.", {
        appearance: "success",
      });
    }
    if (calledFrom === "deletedesignation") {
      let newDesignationList = designationList.filter(
        function removeDesignation(x) {
          return parseInt(x.designationid) !== parseInt(deletedId);
        }
      );
      setDesignationList(newDesignationList);
      addToast("Designation successfully deleted.", {
        appearance: "success",
      });
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <span className="font-medium text-xl">Designation Configuration</span>
        {isDesignationList ? (
          <div>
            <button
              className="bg-theme-green p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handleDesignationAdd}
            >
              <span>Add Designation</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div>
            <button
              className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handleBackToList}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Back to List</span>
            </button>
          </div>
        )}
      </div>
      <div className="rounded-lg">
        {isDesignationAdd && (
          <div>
            <ToastProvider autoDismiss={true} placement="top-center">
              <div className="flex justify-center">
                <AddDesignation
                  handleBackToList={handleBackToList}
                  actionType={actionType}
                  designationObj={designationObj}
                />
              </div>
            </ToastProvider>
          </div>
        )}
        {isDesignationList && (
          <div>
            <DeleteModal
              id={deleteDesignationObj.designationId}
              type="designation"
              handleBackToList={handleBackToList}
            />
            <DesignationList
              handleDesignationEdit={handleDesignationEdit}
              setDesignationObj={setDesignationObj}
              setDeleteDesignationObj={setDeleteDesignationObj}
              designationList={designationList}
              setDesignationList={setDesignationList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DesignationConfiguration;

import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import ProgressionBox from "./ProgressionBox";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import nouser from "../../../../assets/noUser.jpg";
import EditEmpProgressionHistory from "../EditEmpProgressionHistory";
import EditSalaryIncrement from "../EditSalaryIncrement";
import EditSuspension from "../EditSuspension";

export default function EmployeeProgressionHistory() {
  const { state } = useLocation();
  const empid = state.employeeid;
  const unitname = state.unitname;
  const empDetails = state.empDetails;
  const [employeeYearList, setEmployeeYearList] = useState([]);
  const [empProgressionHistory, setEmpProgressionHistory] = useState([]);
  const [empUpdateData, setEmpUpdateData] = useState();
  const [progressionType, setProgressionType] = useState();
  const [isProgressionEditVisible, setIsProgressionEditVisible] =
    useState(false);
  const [isSuspensionEditVisible, setIsSuspensionEditVisible] = useState(false);
  const [isIncrementEditVisible, setIsIncrementEditVisible] = useState(false);

  const handleEditClick = (data) => {
    if (data.progressiontype === "Increment") {
      setIsIncrementEditVisible(true);
    } else if (data.progressiontype === "Suspension") {
      setIsSuspensionEditVisible(true);
    } else {
      setIsProgressionEditVisible(true);
    }
    setEmpUpdateData(data);
    setProgressionType(data.progressiontype);
  };

  useEffect(async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getemployeeyearsbyid`,
      { params: { empid: empid } }
    );
    setEmployeeYearList(() => result.data);

    let resultProgression = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCareerProgressionHistoryV2`,
      {
        params: {
          employeeId: empid,
        },
      }
    );
    setEmpProgressionHistory(resultProgression.data);
  }, []);
  console.log(employeeYearList);
  return (
    <div className="mt-6">
      <EditEmpProgressionHistory
        isVisible={isProgressionEditVisible}
        setIsProgressionVisible={setIsProgressionEditVisible}
        selectedEmployee={empDetails}
        empUpdateData={empUpdateData}
        progressionType={progressionType}
        setProgressionType={setProgressionType}
        setEmpProgressionHistory={setEmpProgressionHistory}
        empid={empid}
      />
      <EditSalaryIncrement
        isVisible={isIncrementEditVisible}
        setIsSalaryIncrementVisible={setIsIncrementEditVisible}
        selectedEmployee={empDetails}
        empUpdateData={empUpdateData}
        setEmpProgressionHistory={setEmpProgressionHistory}
        empid={empid}
      />
      <EditSuspension
        isVisible={isSuspensionEditVisible}
        setIsSuspensionVisible={setIsSuspensionEditVisible}
        selectedEmployee={empDetails}
        empUpdateData={empUpdateData}
        setEmpProgressionHistory={setEmpProgressionHistory}
        empid={empid}
      />
      <div className="flex justify-end items-center px-6 mb-6">
        {unitname === "Gangtok" ? (
          <Fragment>
            <Link to="/admin/employeelistgangtok">
              <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Back to Employee List </span>
              </button>{" "}
            </Link>{" "}
          </Fragment>
        ) : (
          <Fragment>
            <Link to="/admin/employeelistjorethang">
              <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Back to Employee List</span>
              </button>{" "}
            </Link>{" "}
          </Fragment>
        )}
      </div>
      <div>
        <div class="flex items-center mt-6 justify-center border rounded py-3 bg-white">
          <div class="flex-shrink-0 h-20 w-20">
            {empDetails.employeephoto ? (
              <img
                class="h-20 w-20 rounded-full"
                src={empDetails.employeephoto}
                alt="user"
              />
            ) : (
              <img class="h-20 w-20 rounded-full" src={nouser} alt="nouser" />
            )}
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {empDetails.employeename}
            </div>
            <div class="text-sm text-gray-500">
              {empDetails.empdesignationname}
            </div>
            <div class="text-sm text-green-500">{empDetails.emptypename}</div>
          </div>
        </div>
      </div>
      <p className="font-medium text-xl text-gray-700 text-center py-10">
        Employee Career Progression History
      </p>
      <div className="history-tl-container">
        <ul className="tl">
          {employeeYearList?.length > 0 && (
            <Fragment>
              {employeeYearList.map((year, index) => (
                <li
                  className="tl-item"
                  ng-repeat="item in retailer_history"
                  key={index}
                >
                  <div className="timestamp">{year.date_part}</div>
                  <div className="item-title">
                    <div className="grid grid-cols-3 gap-4">
                      {empProgressionHistory
                        .filter(
                          (x) =>
                            new Date(x.progressiondate).getFullYear() ===
                            parseInt(year.date_part)
                        )
                        .map((empDetails) => (
                          <ProgressionBox
                            empDetails={empDetails}
                            setEmpProgressionHistory={setEmpProgressionHistory}
                            empProgressionHistory={empProgressionHistory}
                            setEmployeeYearList={setEmployeeYearList}
                            empid={empid}
                            handleEditClick={handleEditClick}
                          />
                        ))}
                    </div>
                  </div>
                </li>
              ))}
            </Fragment>
          )}
        </ul>
      </div>
    </div>
  );
}

import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import ChangePassword from "../Components/ChangePassword";
import PromotionPendingList from "../SystemAdmin/Notificaiton/PromotionPendingList";
import RetirementList from "../SystemAdmin/Notificaiton/RetirementList";
import SalaryIncrementList from "../SystemAdmin/Notificaiton/SalaryIncrementList";
import AcrDetails from "./ACR/AcrDetails";
import AcrList from "./ACR/AcrList";
import EmployeeDetails from "./EmployeeManagement/EmployeeDetails";
import EmployeeListGangtok from "./EmployeeManagement/EmployeeListGangtok";
import EmployeeListJorethang from "./EmployeeManagement/EmployeeListJorethang";
import HigherManagementDashboard from "./HighermanagementDashboard";
import Sidebar from "./Sidebar";
import GangtokUnitStats from "./Statistics/GangtokUnitStats";
import JorethangUnitStats from "./Statistics/JorethangUnitStats";
import Topbar from "./TopBar";

const HigherManagementHome = () => {
  return (
    <Fragment>
      {" "}
      <Router>
        <div className="bg-gray-100 flex">
          <Sidebar />
          <div className="flex flex-col w-full">
            <Topbar />
            <Switch>
              <Route path="/highermanagement/changepassword">
                <ToastProvider>
                  <ChangePassword />
                </ToastProvider>
              </Route>
              <Route path="/highermanagement/highermanagementdashboard">
                <HigherManagementDashboard />
              </Route>
              <Route path="/highermanagement/employeelistgangtok">
                <EmployeeListGangtok />
              </Route>
              <Route path="/highermanagement/employeelistjorethang">
                <EmployeeListJorethang />
              </Route>
              <Route path="/highermanagement/employeedetails">
                <EmployeeDetails />
              </Route>
              <Route path="/highermanagement/acrlist">
                <AcrList />
              </Route>
              <Route path="/highermanagement/acrdetails">
                <AcrDetails />
              </Route>
              <Route path="/highermanagement/gangtokunitstats">
                <GangtokUnitStats />
              </Route>
              <Route path="/highermanagement/jorethangunitstats">
                <JorethangUnitStats />
              </Route>
              <Route path="/highermanagement/promotionPendingList">
                <PromotionPendingList />
              </Route>
              <Route path="/highermanagement/RetirementList">
                <RetirementList />
              </Route>
              <Route path="/highermanagement/SalaryIncrementPendingList">
                <SalaryIncrementList />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </Fragment>
  );
};

export default HigherManagementHome;

import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./TopBar";
import EmployeeListGangtok from "../Accounts/EmployeeManagement/EmployeeListGangtok";
import EmployeeListJorethang from "../Accounts/EmployeeManagement/EmployeeListJorethang";
import AccountsDashboard from "./AccountsDashboard";
import ChangePassword from "../Components/ChangePassword";
import { ToastProvider } from "react-toast-notifications";

const AccountsHome = () => {
  return (
    <Fragment>
      <Router>
        <div className="flex bg-gray-200">
          <Sidebar />
          <div className="flex flex-col w-full">
            <Topbar />
            <Switch>
              <Route path="/accounts/changepassword">
                <ToastProvider>
                  <ChangePassword />
                </ToastProvider>
              </Route>
              <Route path="/accounts/accountsdashboard">
                <AccountsDashboard />
              </Route>
              <Route path="/accounts/employeelistgangtok">
                <EmployeeListGangtok />
              </Route>
              <Route path="/accounts/employeelistjorethang">
                <EmployeeListJorethang />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </Fragment>
  );
};

export default AccountsHome;

import React, { Component, Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Link, useHistory } from "react-router-dom";

const HandleEmployee = ({
  toggleHandleEmployeeModal,
  employeeid,
  getEmployeeDetails,
}) => {
  const { register, handleSubmit, errors, formState, reset } = useForm();
  const [progressionType, setProgressionType] = useState();
  const [progressionTo, setProgressionTo] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sectionList, setSectionList] = useState();
  const [employeeTypeList, setEmployeeTypeList] = useState();

  const progressionTypes = ["Unit Transfer", "Section Change", "Upgradation"];
  const handleProgressionTypeChange = (e) => {
    setProgressionType(e.target.value);
  };

  const handleClose = () => {
    reset();
    toggleHandleEmployeeModal();
  };

  async function getSectionList() {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionList`
    );
    setSectionList(result.data);
  }
  const unitList = ["Gangtok", "Jorethang"];

  const handleProgressionToChange = (e) => {
    setProgressionTo(e.target.value);
  };
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    if (progressionType === "Unit Transfer") {
      //update unit in employee current officail details
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/updateEmployeeCurrentUnit`,
        {
          data: {
            empId: employeeid,
            unitname: data.progressionTo,
          },
        }
      );

      if (result.data) {
        //add progression to emloyee career progression history
        const result = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeCareerProgressionHistory`,
          {
            data: {
              empId: employeeid,
              progressionType: progressionType,
              progressionTo: data.progressionTo,
              progressionDate: data.progressionDate,
              remarks: data.remarks,
            },
          }
        );
        if (result.data) {
          getEmployeeDetails();
          setIsSubmitting(false);
          reset();
          setProgressionType("");
          toggleHandleEmployeeModal();
        } else {
          console.log(
            "Could not add into employee career progression history."
          );
        }
      } else {
        console.log("Could not update employee current unit.");
      }
    }
    if (progressionType === "Section Change") {
      //update section in employee current officail details
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/updateEmployeeCurrentSection`,
        {
          data: {
            empId: employeeid,
            sectionId: data.progressionTo,
          },
        }
      );
      if (result.data) {
        //add progression to emloyee career progression history
        const result = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeCareerProgressionHistory`,
          {
            data: {
              empId: employeeid,
              progressionType: progressionType,
              progressionTo: data.progressionTo,
              progressionDate: data.progressionDate,
              remarks: data.remarks,
            },
          }
        );
        if (result.data) {
          getEmployeeDetails();
          setIsSubmitting(false);
          reset();
          setProgressionType("");
          toggleHandleEmployeeModal();
        } else {
          console.log(
            "Could not add into employee career progression history."
          );
        }
      } else {
        console.log("Could not update employee current unit.");
      }
    }
    if (progressionType === "Upgradation") {
      //update employee type in employee current officail details
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/updateEmployeeCurrentEmpType`,
        {
          data: {
            empId: employeeid,
            empTypeId: data.progressionTo,
            upgradationDate: data.progressionDate,
          },
        }
      );
      if (result.data) {
        //add progression to emloyee career progression history
        const result = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeCareerProgressionHistory`,
          {
            data: {
              empId: employeeid,
              progressionType: progressionType,
              progressionTo: data.progressionTo,
              progressionDate: data.progressionDate,
              remarks: data.remarks,
            },
          }
        );
        if (result.data) {
          getEmployeeDetails();
          setIsSubmitting(false);
          reset();
          setProgressionType("");
          toggleHandleEmployeeModal();
        } else {
          console.log(
            "Could not add into employee career progression history."
          );
        }
      } else {
        console.log("Could not update employee current unit.");
      }
    }
  };

  async function getEmployeeTypeList() {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeList`
    );
    setEmployeeTypeList(result.data);
  }

  useEffect(() => {
    getSectionList();
    getEmployeeTypeList();
  }, []);
  return (
    <Fragment>
      <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center handleEmployeeModal">
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 z-40"></div>
        <div className="bg-white rounded-lg z-50 w-2/4 top-20 max-h-11/12 overflow-y-hidden">
          <div className="w-full rounded-lg p-6">
            <div className="flex justify-between">
              <span className="text-xl font-medium">Handle Employee</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width="20"
                height="20"
                className="cursor-pointer"
                onClick={handleClose}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="grid grid-cols-6 gap-10 bg-blue-100 text-blue-900 p-3 my-3 rounded-md">
              <div className="col-span-2">Progression type</div>
              <div className="col-span-2">Progression to</div>
              <div className="col-span-2">Date</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="text-left">
              <div className="grid grid-cols-6 gap-10 flex items-center mb-3">
                <div className="col-span-2">
                  {" "}
                  <select
                    name="progressionType"
                    ref={register({ required: true })}
                    className="border rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                    onChange={handleProgressionTypeChange}
                    value={progressionType}
                  >
                    <option value="">--select progression type-- </option>
                    {progressionTypes &&
                      progressionTypes.map((x) => {
                        return <option value={x}>{x}</option>;
                      })}
                  </select>
                  {errors.progressionType && (
                    <Error message="Progression type is required" />
                  )}
                </div>
                <div className="col-span-2">
                  {" "}
                  <select
                    name="progressionTo"
                    ref={register({ required: true })}
                    onChange={handleProgressionToChange}
                    className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                    value={progressionTo}
                  >
                    <option value="">--select progression to-- </option>
                    {progressionType === "Unit Transfer" ? (
                      <Fragment>
                        {" "}
                        {unitList &&
                          unitList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}{" "}
                      </Fragment>
                    ) : progressionType === "Section Change" ? (
                      <Fragment>
                        {sectionList &&
                          sectionList.map((x) => {
                            return (
                              <option value={x.sectionid}>
                                {x.sectionname}
                              </option>
                            );
                          })}
                      </Fragment>
                    ) : progressionType === "Upgradation" ? (
                      <Fragment>
                        {employeeTypeList &&
                          employeeTypeList.map((x) => {
                            return (
                              <option value={x.employeetypeid}>
                                {x.employeetypename}
                              </option>
                            );
                          })}
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </select>
                  {errors.progressionTo && (
                    <Error message="Progression to is required" />
                  )}
                </div>

                <div className="col-span-2">
                  <input
                    type="date"
                    ref={register({ required: true })}
                    className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                    name="progressionDate"
                  />
                  {errors.progressionDate && (
                    <Error message="Progression date is required" />
                  )}
                </div>
              </div>
              <div className="bg-blue-100 text-blue-900 p-3 my-3 rounded-md mt-9">
                Remarks (if any)
              </div>
              <div>
                <textarea
                  type="text"
                  className="border rounded-lg w-full h-20  py-1.5 px-3 text-sm outline-none"
                  placeholder="Remarks"
                  autoComplete="off"
                  ref={register({ required: false })}
                  name="remarks"
                ></textarea>
              </div>
              <button
                type="submit"
                className="mt-6 focus:outline-none rounded-sm py-2 px-4 rounded-md bg-sky-blue text-white flex items-center"
                onClick={handleSubmit}
                disabled={isSubmitting ? true : false}
              >
                {isSubmitting ? (
                  <Fragment>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Submitting</span>
                  </Fragment>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HandleEmployee;

import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import nouser from "../../../assets/noUser.jpg";
import { ToastProvider, useToasts } from "react-toast-notifications";

export default function SetInActive({
  isVisible,
  setIsInActiveVisible,
  selectedEmployee,
  inActiveStatus,
  getEmployeeList,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async (data) => {
    try {
      const result = axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/updateEmployeeIsActiveStatus`,
        {
          data: {
            isactivestatus: inActiveStatus,
            empid: selectedEmployee.employeeid,
          },
        }
      );
      setIsInActiveVisible(false);
      addToast("Is Active status successfully updated.", {
        appearance: "success",
        autoDismiss: true,
      });
      getEmployeeList();
    } catch (error) {
      addToast("Oops something went wrong", {
        appearance: "error",
        autoDismiss: true,
      });
      console.log("ERROR", error);
    }
  };
  return (
    <div>
      <ToastProvider autoDismiss={true} placement="top-center"></ToastProvider>
      {isVisible && (
        <div
          className="fixed z-20 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <div className="flex items-center">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-red-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                            />
                          </svg>
                        </div>
                        <h3
                          className="text-lg leading-6 font-medium text-gray-900 pl-4"
                          id="modal-title"
                        >
                          Active Status
                        </h3>
                      </div>
                      <div class="flex items-center mt-6 justify-center border rounded py-3">
                        <div class="flex-shrink-0 h-20 w-20">
                          {selectedEmployee.employeephoto ? (
                            <img
                              class="h-20 w-20 rounded-full"
                              src={selectedEmployee.employeephoto}
                              alt="user"
                            />
                          ) : (
                            <img
                              class="h-20 w-20 rounded-full"
                              src={nouser}
                              alt="nouser"
                            />
                          )}
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {selectedEmployee.employeename}
                          </div>
                          <div class="text-sm text-gray-500">
                            {selectedEmployee.empdesignationname}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        {inActiveStatus ? (
                          <p>
                            Are you sure you want to set this employee active ?
                          </p>
                        ) : (
                          <p>
                            Are you sure you want to set this employee inactive
                            ?
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={isSubmitting ? true : false}
                  >
                    {isSubmitting ? (
                      <Fragment>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Submitting</span>
                      </Fragment>
                    ) : (
                      "Yes"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setIsInActiveVisible(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

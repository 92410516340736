import { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LoginProvider } from "../../context/LoginContext";
import LoginComponent from "../../login-components/LoginComponent";
import LogoutComponent from "../../login-components/LogoutComponent";
import AccountsHome from "../Accounts/AccountsHome";
import HigherManagementHome from "../HigherManagement/HigherManagementHome";
import GangtokUnitStats from "../HigherManagement/Statistics/GangtokUnitStats";
import SectionHeadHome from "../SectionHead/SectionHeadHome";
import EmployeeType from "../SystemAdmin/Reports/EmployeeType";
import SystemAdminHome from "../SystemAdmin/SystemAdminHome";

const RouteComponent = () => {
  return (
    <Fragment>
      <LoginProvider>
        <Router>
          <Switch>
            <Route path="/" exact component={LoginComponent} />
            <Route
              path="/admin/changepassword"
              exact
              component={SystemAdminHome}
            />
            {/* SYSTEM ADMIN ROUTES */}
            <Route
              path="/admin/systemadmindashboard"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/employeelistgangtok"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/inactive/gangtok"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/employeelistjorethang"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/inactive/jorethang"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/addemployee"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/editemployee"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/viewempprogressionhistory"
              exact
              component={SystemAdminHome}
            />
            <Route path="/admin/userlist" exact component={SystemAdminHome} />
            <Route path="/admin/adduser" exact component={SystemAdminHome} />
            <Route
              path="/admin/employeeType"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/levelWiseReport"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/promotionPendingList"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/RetirementList"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/SalaryIncrementPendingList"
              exact
              component={SystemAdminHome}
            />

            <Route
              path="/sectionconfiguration"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/designationconfiguration"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/employeetypeconfiguration"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/levelconfiguration"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/promotionpatternconfiguration"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/levelpatternconfiguration"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/leavetypeconfiguration"
              exact
              component={SystemAdminHome}
            />
            <Route
              path="/admin/employeeleaves"
              exact
              component={SystemAdminHome}
            />

            {/* HIGHER MANAGEMENT ROUTES */}
            <Route
              path="/highermanagement/highermanagementdashboard"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/employeelistgangtok"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/employeelistjorethang"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/employeedetails"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/acrlist"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/acrdetails"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/gangtokunitstats"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/jorethangunitstats"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/changepassword"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/promotionPendingList"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/RetirementList"
              exact
              component={HigherManagementHome}
            />
            <Route
              path="/highermanagement/SalaryIncrementPendingList"
              exact
              component={HigherManagementHome}
            />
            {/* SECTION HEAD ROUTES */}
            <Route
              path="/sectionhead/sectionheaddashboard"
              exact
              component={SectionHeadHome}
            />
            <Route
              path="/sectionhead/acrlist"
              exact
              component={SectionHeadHome}
            />
            <Route
              path="/sectionhead/addacr"
              exact
              component={SectionHeadHome}
            />
            <Route
              path="/sectionhead/acrdetails"
              exact
              component={SectionHeadHome}
            />
            <Route
              path="/sectionhead/changepassword"
              exact
              component={SectionHeadHome}
            />
            <Route
              path="/sectionhead/acrforwardedempList"
              exact
              component={SectionHeadHome}
            />
            {/* ACCOUNTS ROUTES */}
            <Route
              path="/accounts/accountsdashboard"
              exact
              component={AccountsHome}
            />
            <Route
              path="/accounts/employeelistgangtok"
              exact
              component={AccountsHome}
            />
            <Route
              path="/accounts/employeelistjorethang"
              exact
              component={AccountsHome}
            />
            <Route
              path="/accounts/changepassword"
              exact
              component={AccountsHome}
            />
          </Switch>
        </Router>
        <LogoutComponent />
      </LoginProvider>
    </Fragment>
  );
};

export default RouteComponent;

import React, { Fragment, useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";

const AddPromotionPattern = ({
  handleBackToList,
  actionType,
  promotionPatternObj,
  empTypeList,
}) => {
  const { register, handleSubmit, errors, reset, formState } = useForm();
  const [appointmentType, setAppointmentType] = useState();
  const appointmentTypes = ["Muster Roll", "Direct Appointment"];
  const [fromEmpTypeId, setFromEmpTypeId] = useState();
  const [toEmpTypeId, setToEmpTypeId] = useState();
  const onSubmit = async (data) => {
    if (actionType === "add") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addPromotionPattern`,
        {
          data: {
            fromEmpTypeId: data.fromEmpTypeId,
            toEmpTypeId: data.toEmpTypeId,
            noOfYears: data.noOfYears,
            appointmentType: data.appointmentType,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("addpromotionpattern");
      }
    } else if (actionType === "edit") {
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/editPromotionPatternDetails`,
        {
          data: {
            patternId: promotionPatternObj.patternId,
            fromEmpTypeId: data.fromEmpTypeId,
            toEmpTypeId: data.toEmpTypeId,
            noOfYears: data.noOfYears,
            appointmentType: data.appointmentType,
          },
        }
      );

      if (result !== null && result !== "") {
        handleBackToList("editpromotionpattern");
      }
    }
  };
  const handleFromEmpTypeChange = (e) => {
    setFromEmpTypeId(e.target.value);
  };

  const handleToEmpTypeChange = (e) => {
    setToEmpTypeId(e.target.value);
  };

  const handleAppointmentTypeChange = (e) => {
    setAppointmentType(e.target.value);
  };
  const handleCancel = () => {
    handleBackToList();
  };

  const setAppointmentTypeEdit = () => {
    setAppointmentType(promotionPatternObj.appointmentType);
  };

  useEffect(() => {
    if (actionType !== "add") {
      setAppointmentTypeEdit();
    }
  }, []);
  return (
    <Fragment>
      {actionType === "add" ? (
        <div className="bg-white w-3/4 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">
            Add Promotion Pattern{" "}
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <div className="grid grid-cols-5 gap-0 flex items-center">
              <div className="col-span-2">
                <p>From Employee Type</p>
                <select
                  name="fromEmpTypeId"
                  ref={register({ required: true })}
                  onChange={handleFromEmpTypeChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.fromEmpTypeId ? "border-red-700" : ""
                  }`}
                  value={fromEmpTypeId}
                >
                  <option value="">--select employee type-- </option>
                  {empTypeList &&
                    empTypeList.map((x) => {
                      return (
                        <option value={x.employeetypeid.toString()}>
                          {x.employeetypename}
                        </option>
                      );
                    })}
                </select>
                {errors.fromEmpTypeId && (
                  <Error message="From Employee type is required" />
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 text-theme-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="col-span-2">
                <p>To Employee Type</p>
                <select
                  name="toEmpTypeId"
                  ref={register({ required: true })}
                  onChange={handleToEmpTypeChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.toEmpTypeId ? "border-red-700" : ""
                  }`}
                  value={toEmpTypeId}
                >
                  <option value="">--select employee type-- </option>
                  {empTypeList &&
                    empTypeList.map((x) => {
                      return (
                        <option value={x.employeetypeid.toString()}>
                          {x.employeetypename}
                        </option>
                      );
                    })}
                </select>
                {errors.toEmpTypeId && (
                  <Error message="To Employee type is required" />
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10 mt-4">
              <div className="col-span-1">
                <p className="">No. of years :</p>
                <input
                  name="noOfYears"
                  type="number"
                  className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                    errors.noOfYears ? "border-red-700" : ""
                  }`}
                  ref={register({ required: true })}
                />
                {errors.noOfYears && <Error message="No. of years required" />}
              </div>
              <div className="col-span-1">
                <p>Appointment type :</p>
                <select
                  name="appointmentType"
                  ref={register({ required: false })}
                  onChange={handleAppointmentTypeChange}
                  className="border rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                  value={appointmentType}
                >
                  <option value="">--select appointment type-- </option>
                  {appointmentTypes &&
                    appointmentTypes.map((x) => {
                      return <option value={x}>{x}</option>;
                    })}
                </select>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">
            Edit Promotion Pattern
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <div className="grid grid-cols-5 gap-0 flex items-center">
              <div className="col-span-2">
                <p>From Employee Type</p>
                <select
                  name="fromEmpTypeId"
                  defaultValue={promotionPatternObj.fromEmpTypeId}
                  ref={register({ required: true })}
                  onChange={handleFromEmpTypeChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.fromEmpTypeId ? "border-red-700" : ""
                  }`}
                  value={fromEmpTypeId}
                >
                  <option value="">--select employee type-- </option>
                  {empTypeList &&
                    empTypeList.map((x) => {
                      return (
                        <option value={x.employeetypeid.toString()}>
                          {x.employeetypename}
                        </option>
                      );
                    })}
                </select>
                {errors.fromEmpTypeId && (
                  <Error message="From Employee type is required" />
                )}
              </div>
              <div className="col-span-1 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 text-theme-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="col-span-2">
                <p>To Employee Type</p>
                <select
                  name="toEmpTypeId"
                  defaultValue={promotionPatternObj.toEmpTypeId}
                  ref={register({ required: true })}
                  onChange={handleToEmpTypeChange}
                  className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                    errors.toEmpTypeId ? "border-red-700" : ""
                  }`}
                  value={toEmpTypeId}
                >
                  <option value="">--select employee type-- </option>
                  {empTypeList &&
                    empTypeList.map((x) => {
                      return (
                        <option value={x.employeetypeid.toString()}>
                          {x.employeetypename}
                        </option>
                      );
                    })}
                </select>
                {errors.toEmpTypeId && (
                  <Error message="To Employee type is required" />
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10 mt-4">
              <div className="col-span-1">
                <p className="mt-3">No. of years :</p>
                <input
                  name="noOfYears"
                  type="number"
                  defaultValue={promotionPatternObj.noOfYears}
                  className={`border rounded-lg w-1/2 py-1.5 px-3 text-sm outline-none ${
                    errors.noOfYears ? "border-red-700" : ""
                  }`}
                  ref={register({ required: true })}
                />
                {errors.noOfYears && <Error message="No. of years required" />}
              </div>
              <div className="col-span-1">
                <p>Appointment type :</p>
                <select
                  name="appointmentType"
                  ref={register({ required: false })}
                  onChange={handleAppointmentTypeChange}
                  className="border rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                  value={appointmentType}
                >
                  <option value="">--select appointment type-- </option>
                  {appointmentTypes &&
                    appointmentTypes.map((x) => {
                      return <option value={x}>{x}</option>;
                    })}
                </select>
              </div>
            </div>

            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default AddPromotionPattern;

import React, { Fragment, useContext, useState } from "react";
import AddEmployeeType from "../employee_type-components/AddEmployeeType";
import EmployeeTypeList from "../employee_type-components/EmployeeTypeList";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DeleteModal from "../../../utilities/DeleteModal";

const EmployeeTypeConfiguration = () => {
  const [empTypeList, setEmpTypeList] = useState();
  const [isList, setIsList] = useState(true);
  const [isEmpTypeAdd, setIsEmpTypeAdd] = useState(false);
  const [empTypeObj, setEmpTypeObj] = useState({
    empTypeId: "",
    empTypeName: "",
  });
  const [deleteEmpTypeObj, setDeleteEmpTypeObj] = useState({
    empTypeId: "",
  });
  const [actionType, setActionType] = useState("");
  const { addToast } = useToasts();

  const handleEmpTypeAdd = () => {
    setIsEmpTypeAdd(true);
    setIsList(false);
    setActionType("add");
  };

  const handleEmpTypeEdit = (e) => {
    setIsEmpTypeAdd(true);
    setIsList(false);
    setActionType("edit");
  };
  const handleBackToList = (calledFrom, deletedId) => {
    setIsEmpTypeAdd(false);
    setIsList(true);
    setActionType("");
    if (calledFrom === "addemptype") {
      addToast("Employee Type successfully added.", {
        appearance: "success",
      });
    }
    if (calledFrom === "editemptype") {
      addToast("Employee Type successfully updated.", {
        appearance: "success",
      });
    }
    if (calledFrom === "deleteemptype") {
      let newEmpTypeList = empTypeList.filter(function removeEmpType(x) {
        return parseInt(x.employeetypeid) !== parseInt(deletedId);
      });
      setEmpTypeList(newEmpTypeList);
      addToast("Employee Type successfully deleted.", {
        appearance: "success",
      });
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <span className="font-medium text-xl">Employee Type Configuration</span>
        {isList ? (
          <div>
            <button
              className="bg-theme-green p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handleEmpTypeAdd}
            >
              <span>Add Employee Type</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div>
            <button
              className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handleBackToList}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Back to List</span>
            </button>
          </div>
        )}
      </div>
      <div className="rounded-lg">
        {isEmpTypeAdd && (
          <div>
            <ToastProvider autoDismiss={true} placement="top-center">
              <div className="flex justify-center">
                <AddEmployeeType
                  handleBackToList={handleBackToList}
                  actionType={actionType}
                  empTypeObj={empTypeObj}
                />
              </div>
            </ToastProvider>
          </div>
        )}
        {isList && (
          <div>
            <DeleteModal
              id={deleteEmpTypeObj.empTypeId}
              type="employeetype"
              handleBackToList={handleBackToList}
            />
            <EmployeeTypeList
              handleEmpTypeEdit={handleEmpTypeEdit}
              setEmpTypeObj={setEmpTypeObj}
              setDeleteEmpTypeObj={setDeleteEmpTypeObj}
              empTypeList={empTypeList}
              setEmpTypeList={setEmpTypeList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeTypeConfiguration;

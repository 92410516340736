import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import dashsboard from "../../../assets/dashboard.png";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Loader from "../../../utilities/Loader";

const JorethangUnitStats = () => {
  const { state } = useLocation();
  const unit = "Jorethang";
  const empCount = state.empCountJorethang;
  const [statusWiseEmpCount, setStatusWiseEmpCount] = useState({});
  const [sectionWiseEmpCount, setSectionWiseEmpCount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sectionWiseEmptypeCountObj, setSectionWiseEmpTypeCountObj] = useState(
    []
  );

  const [marketingObj, setMarketingObj] = useState();
  const [plantObj, setPlantObj] = useState([]);
  const [procurementsObj, setProcurementsObj] = useState([]);
  const [accountsObj, setAccountsObj] = useState([]);
  const [adminObj, setAdminObj] = useState([]);
  const [storeObj, setStoreObj] = useState([]);

  const getStatistics = async () => {
    let result1 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getStatusWiseEmployeeCount`
    );
    setStatusWiseEmpCount(result1.data);
    let result2 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionWiseEmployeeCountJorethang`
    );
    for (let i = 0; i < result2.data.length; i++) {
      result2.data[i].empcount = parseInt(result2.data[i].empcount);
    }
    setSectionWiseEmpCount(result2.data);
    let result3 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionWiseEmptypeCountJorethang`
    );
    setSectionWiseEmpTypeCountObj(result3.data);
    setPlantObj(result3.data.filter((x) => x.sectionname === "Plant"));
    setMarketingObj(result3.data.filter((x) => x.sectionname === "Marketing"));
    setProcurementsObj(result3.data.filter((x) => x.sectionname === "P&I"));
    setAccountsObj(result3.data.filter((x) => x.sectionname === "Accounts"));
    setAdminObj(result3.data.filter((x) => x.sectionname === "Administration"));
    setStoreObj(result3.data.filter((x) => x.sectionname === "Store"));
    setIsLoading(false);
  };

  useEffect(() => {
    getStatistics();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <Loader message={"Fetching statistics."} />
      ) : (
        <div className="p-6">
          <div className="flex justify-between">
            <div>
              <p className="font-medium text-2xl text-gray-800">
                Employee Statistics
              </p>
              <p className="text-gray-700 text-xl flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  />
                </svg>
                Jorethang
              </p>{" "}
            </div>
            <Link
              to={{
                pathname: "/highermanagement/highermanagementdashboard",
              }}
            >
              <button className="bg-gray-900 text-white py-2 px-4 rounded-md focus:outline-none rounded-sm shadow-md flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Back to Dashboard</span>
              </button>
            </Link>
          </div>

          <div className="my-6">
            <div className="flex rounded-t-md p-4 bg-blue-100">
              <p className="text-4xl text-blue-900 font-medium">
                <span>{empCount}</span>
              </p>
              <div className="flex items-end pl-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 w-9 text-blue-900"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
                <p className="text-blue-900 pl-3">
                  Employees regsitered in Jorethang unit
                </p>
              </div>
            </div>
            {statusWiseEmpCount.length > 0 && (
              <div className="bg-white p-4">
                <div className="grid grid-cols-4">
                  {statusWiseEmpCount
                    ?.filter((emp) => emp.empcurrentunitname === "Jorethang")
                    .sort((a, b) =>
                      a.empcurrentemptypename > b.empcurrentemptypename ? 1 : -1
                    )
                    .map((emptype, index) => (
                      <StatusCount
                        key={index}
                        count={emptype.empcount}
                        status={emptype.empcurrentemptypename}
                        bordercolor={"border-green-200"}
                        bgcolor={"bg-green-400"}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="p-4 rounded-lg bg-white">
            <p className="font-medium text-lg text-gray-800">
              Section wise registered employees :
            </p>
            <ResponsiveContainer width="100%" height={450}>
              <BarChart
                className="mt-8"
                data={sectionWiseEmpCount}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="sectionname" />
                {/* <YAxis /> */}
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="empcount"
                  fill="#1F73D8"
                  name="No. of Employees"
                  label={{
                    fill: "white",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="p-6 bg-white shadow-lg mt-6">
            <p className="font-medium text-lg text-gray-800">
              Section wise employee type count :
            </p>
            <div className="grid grid-cols-2 gap-20">
              <div className="col-span-1">
                <SectionWiseEmptypeCount
                  section={"Plant"}
                  countObj={plantObj}
                  color={"green"}
                />
              </div>
              <div className="col-span-1">
                <SectionWiseEmptypeCount
                  section={"Marketing"}
                  countObj={marketingObj}
                  color={"green"}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-20 mt-9">
              <div className="col-span-1">
                <SectionWiseEmptypeCount
                  section={"P&I"}
                  countObj={procurementsObj}
                  color={"blue"}
                />
              </div>
              <div className="col-span-1">
                <SectionWiseEmptypeCount
                  section={"Accounts"}
                  countObj={accountsObj}
                  color={"blue"}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-20 mt-9">
              <div className="col-span-1">
                <SectionWiseEmptypeCount
                  section={"Administration"}
                  countObj={adminObj}
                  color={"yellow"}
                />
              </div>
              <div className="col-span-1">
                <SectionWiseEmptypeCount
                  section={"Store"}
                  countObj={storeObj}
                  color={"yellow"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const StatusCount = ({ count, status, bgcolor, bordercolor }) => {
  return (
    <div className="flex flex-col justify-center items-center p-2 border">
      <div className="font-bold flex items-center">{count}</div>
      <p className="text-center mt-2 font-medium text-sm">{status}</p>
    </div>
  );
};

const SectionWiseEmptypeCount = ({ section, countObj, color }) => {
  return (
    <Fragment>
      <div className="mt-9">
        <div className="flex text-white">
          <label
            className={`bg-${color}-600 text-white px-4 py-3 rounded-t-md`}
          >
            {section}
          </label>
        </div>
        <div className="grid grid-cols-4">
          <div
            className={`col-span-3 flex justify-between px-6 py-2 bg-${color}-100 border-${color}-200 border-b-4 rounded-r-md`}
          >
            {countObj.map((x) => {
              return (
                <div>
                  <p className="text-sm font-medium">{x.employeetypename}</p>
                  <p className={`text-2xl font-medium text-${color}-900`}>
                    {x.count}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default JorethangUnitStats;

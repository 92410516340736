import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../utilities/Loader";
const UserList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);

  const getUsersList = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getUsersList`
    );
    setUsersList(result.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getUsersList();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between">
          <span className="font-medium text-2xl">User List</span>
          <Link
            to={{
              pathname: "/admin/adduser",
              state: {
                unitname: "Gangtok",
              },
            }}
          >
            {" "}
            <button className="bg-theme-green p-2 text-white focus:outline-none rounded-sm flex items-center">
              <span>Add User</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </Link>
        </div>
        <div className="rounded-lg p-3 mt-4">
          {isLoading ? (
            <Loader message={"fetching users...."} />
          ) : (
            <Fragment>
              <div>
                <div className="grid grid-cols-5 bg-blue-200 p-3">
                  <div className="col-span-1">Username</div>
                  <div className="col-span-1">Role</div>
                  <div className="col-span-1">Unit</div>
                  <div className="col-span-1">Section</div>
                  <div className="col-span-1 text-center">Action</div>
                </div>
              </div>
              {usersList && usersList.length > 0 ? (
                <Fragment>
                  <div className="">
                    {usersList.map((x) => {
                      return (
                        <div className="grid grid-cols-5 border m-2 p-3 flex items-center bg-white">
                          <div className="col-span-1">{x.username}</div>
                          <div className="col-span-1">{x.role}</div>
                          <div className="col-span-1">
                            {x.unitname !== null && x.unitname !== ""
                              ? x.unitname
                              : "-"}
                          </div>
                          <div className="col-span-1">
                            {x.sectionname !== null && x.sectionname !== ""
                              ? x.sectionname
                              : "-"}
                          </div>
                          <div className="col-span-1 flex justify-center">
                            <button
                              type="button"
                              className="px-3 py-1 text-xs bg-red-500 hover:bg-red-600 text-white"
                            >
                              Disable
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              ) : (
                ""
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;

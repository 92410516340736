import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";
import { generateRandomAlphaNumericString } from "../../../utilities/functions/UtilityFunctions";
const bcrypt = require("bcryptjs");

const AddUser = () => {
  const { register, handleSubmit, errors, formState } = useForm();
  const [userRole, setUserRole] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userRoles = [
    "System Admin",
    "Higher Management",
    "Section Head",
    "Accounts",
  ];
  const units = ["Gangtok", "Jorethang"];
  const [sectionList, setSectionList] = useState([]);
  const getSectionNameById = (id) => {
    let name = "";
    for (var i = 0; i < sectionList.length; i++) {
      if (sectionList[i].sectionid === id) {
        name = sectionList[i].sectionname;
      }
    }
    return name;
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    var pass = generateRandomAlphaNumericString(5);
    const hashedPassword = bcrypt.hashSync(pass, bcrypt.genSaltSync());
    const result = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/createLoginUser`,
      {
        data: {
          userName: data.userName,
          passwordHash: hashedPassword,
          role: data.userRole,
          passwordResetCode: "",
          isDisabled: false,
          unit: data.unit,
          sectionid: data.section,
        },
      }
    );
    if (result) {
      // Send credentials email to employee
      console.log("user successfully created");
      var sectionName = getSectionNameById(data.section);
      const mailResult = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/sendCredentialsMail`,
        {
          data: {
            userName: data.userName,
            password: pass,
            role: data.userRole,
            unit: data.unit,
            section: sectionName,
          },
        }
      );
      if (mailResult.data === "SUCCESS") {
        console.log("Credentials Mail successfully sent.");
        window.location.replace(
          `${process.env.REACT_APP_REDIRECT_URL}admin/userlist`
        );
      } else {
        alert("Could not send credentials mail.");
      }
    } else {
      alert("Could not create user.");
    }
  };
  const getSectionList = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionList`
    );
    setSectionList(result.data);
  };
  useEffect(() => {
    getSectionList();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between items-center">
          <span className="font-medium text-2xl">Add User</span>
          <Fragment>
            {" "}
            <Link to="/admin/userlist">
              <button className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Back to User List</span>
              </button>{" "}
            </Link>{" "}
          </Fragment>
        </div>
        <div className="bg-white mt-7 p-6">
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <div className="grid grid-cols-3 gap-10">
              <div className="col-span-1">
                {" "}
                <p className="font-medium mb-2">User role :</p>
                <select
                  name="userRole"
                  ref={register({ required: true })}
                  onChange={(e) => setUserRole(e.target.value)}
                  className="border border-gray-500 rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                  value={userRole}
                >
                  <option value="">--select user role-- </option>
                  {userRoles &&
                    userRoles.map((x) => {
                      return <option value={x}>{x}</option>;
                    })}
                </select>
                {errors.userRole && <Error message="User role is required" />}
              </div>
              <div className="col-span-1">
                <p className="font-medium mb-2">Username :</p>
                <input
                  name="userName"
                  type="text"
                  placeholder="Enter username"
                  className="border border-gray-500  rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                  ref={register({ required: true })}
                />{" "}
                {errors.userName && <Error message="Username is required" />}
              </div>
              <div className="col-span-1"></div>
            </div>
            {userRole === "Section Head" ? (
              <Fragment>
                <div className="grid grid-cols-3 gap-10 mt-4">
                  <div className="col-span-1">
                    {" "}
                    <p className="font-medium mb-2">Unit :</p>
                    <select
                      name="unit"
                      ref={register({ required: true })}
                      className="border border-gray-500  rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                    >
                      <option value="">--select unit-- </option>
                      {units &&
                        units.map((x) => {
                          return <option value={x}>{x}</option>;
                        })}
                    </select>
                    {errors.unit && <Error message="Unit is required" />}
                  </div>
                  <div className="col-span-1">
                    <p className="font-medium mb-2">Section :</p>
                    <select
                      name="section"
                      ref={register({ required: true })}
                      className="border border-gray-500  rounded-lg w-full  py-1.5 px-3 text-sm outline-none"
                    >
                      <option value="">--select unit-- </option>
                      {sectionList &&
                        sectionList.map((x) => {
                          return (
                            <option value={x.sectionid}>{x.sectionname}</option>
                          );
                        })}
                    </select>
                    {errors.section && <Error message="Section is required" />}
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
            <div className="flex justify-between mt-7">
              <button
                className="px-4 py-2 focus:outline-none shadow-md rounded-sm bg-theme-green text-white font-bold text-sm flex justify-center hover:shadow-lg"
                type="submit"
                disabled={isSubmitting ? true : false}
              >
                {isSubmitting ? (
                  <Fragment>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Submitting</span>
                  </Fragment>
                ) : (
                  "Submit"
                )}
              </button>
              <Link to="/admin/userlist">
                <button className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2">
                  Cancel
                </button>
              </Link>
            </div>{" "}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddUser;

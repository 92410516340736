import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { toggleDeleteModal } from "../../../modal-functions/ModalFunctions";
import Loader from "../../../utilities/Loader";

const PromotionPatternList = ({
  handlePatternEdit,
  setPromotionPatternObj,
  setDeletePromotionPatternObj,
  promotionPatternList,
  setPromotionPatternList,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleEdit = async (e) => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getPromotionPatternDetailsById`,
      {
        params: {
          promotionPatternId: e.currentTarget.id,
        },
      }
    );

    setPromotionPatternObj({
      patternId: result.data.rows[0].promotionpatternid,
      fromEmpTypeId: result.data.rows[0].fromemployeetypeid,
      toEmpTypeId: result.data.rows[0].toemployeetypeid,
      noOfYears: result.data.rows[0].noofyears,
      appointmentType: result.data.rows[0].appointmenttype,
    });

    handlePatternEdit();
  };

  const handleDelete = async (e) => {
    toggleDeleteModal();
    setDeletePromotionPatternObj({
      patternId: e.currentTarget.id,
    });
  };

  const getPromotionPatternList = async () => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getPromotionPatternList`
    );
    setPromotionPatternList(result.data);
    setIsLoading(false);
  };
  useEffect(() => {
    getPromotionPatternList();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <Loader message="Fetching promotion pattern list." />
      ) : (
        <div className="bg-white rounded-lg w-full p-3">
          <p className="text-lg text-left font-medium mb-3">
            Promotion Pattern List{" "}
          </p>
          {promotionPatternList && promotionPatternList.length > 0 ? (
            <div className="">
              <div className="p-3 flex items-center shadow-md bg-blue-200 text-gray-600 rounded-lg mb-2 grid grid-cols-6 gap-2">
                <div className="col-span-1">
                  <span className="font-medium">From employee type</span>
                </div>
                <div className="col-span-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
                <div className="col-span-1">
                  <span className="font-medium ml-4">To employee type</span>
                </div>
                <div className="col-span-1">Appointment type</div>
                <div className="col-span-1 font-medium">No. of years</div>
                <div className="col-span-1 flex justify-end font-medium">
                  Action
                </div>
              </div>
              {promotionPatternList.map((x) => {
                return (
                  <div className="p-3 flex items-center shadow-md rounded-lg border-l-4 border-blue-300 border-b-none mb-2 grid grid-cols-6 gap-2">
                    <div className="col-span-1">
                      <span className="italic">{x.fromemployeetypename}</span>
                    </div>
                    <div className="col-span-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </div>
                    <div className="col-span-1">
                      <span className="ml-4 italic">
                        {x.toemployeetypename}
                      </span>
                    </div>
                    <div className="col-span-1">
                      {x.appointmenttype === null ||
                      x.appointmenttype === "" ? (
                        <span className="">-</span>
                      ) : (
                        x.appointmenttype
                      )}
                    </div>
                    <div className="col-span-1 font-medium text-theme-green text-lg">
                      {x.noofyears}
                    </div>
                    <div className="col-span-1 flex justify-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5  cursor-pointer"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        id={x.promotionpatternid}
                        onClick={handleEdit}
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-9 cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          id={x.promotionpatternid}
                          onClick={handleDelete}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No promotion patterns added as of now.</div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default PromotionPatternList;

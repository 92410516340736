import React, { Fragment, useEffect } from "react";

const AccountsDashboard = () => {
  return (
    <Fragment>
      <div className="p-6">Accounts Dashboard</div>
    </Fragment>
  );
};

export default AccountsDashboard;

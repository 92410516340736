import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import DesignationConfiguration from "../../systemAdmin-components/pages/designation-components/DesignationConfiguration";
import EmployeeTypeConfiguration from "../../systemAdmin-components/pages/employee_type-components/EmployeeTypeConfiguration";
import LevelConfiguration from "../../systemAdmin-components/pages/level-components/LevelConfiguration";
import LevelPatternConfiguration from "../../systemAdmin-components/pages/level_pattern-components.js/LevelPatternConfiguration";
import PromotionPatternConfiguration from "../../systemAdmin-components/pages/promotion_pattern-components/PromotionPatternConfiguration";
import SectionConfiguration from "../../systemAdmin-components/pages/section-components/SectionConfiguration";
import Sidebar from "../SystemAdmin/Sidebar";
import AddACR from "./ACR/AddACR";
import AddEmployee from "./EmployeeManagement/AddEmployee";
import AddEmpProgressionHistory from "./EmployeeManagement/AddEmpProgressionHistory";
import ViewProgressionHistory from "./EmployeeManagement/EmployeeProgression/EmployeeProgressionHistory";
import EditEmployee from "./EmployeeManagement/EditEmployee";
import EmployeeListGangtok from "./EmployeeManagement/EmployeeListGangtok";
import EmployeeListJorethang from "./EmployeeManagement/EmployeeListJorethang";
import SystemAdminDashboard from "./SystemAdminDashboard";
import Topbar from "./TopBar";
import AddUser from "./UserManagement/AddUser";
import UserList from "./UserManagement/UserList";
import ChangePassword from "../Components/ChangePassword";
import EmployeeType from "./Reports/EmployeeType";
import LevelWiseReport from "./Reports/LevelWiseReport";
import PromotionPendingList from "./Notificaiton/PromotionPendingList";
import RetirementList from "./Notificaiton/RetirementList";
import SalaryIncrementList from "./Notificaiton/SalaryIncrementList";
import LeaveTypeConfiguration from "../../systemAdmin-components/pages/employee-leave-components/LeaveTypeConfiguration";
import EmployeeLeaveConfig from "./EmployeeManagement/EmployeeLeaves/EmployeeLeaveConfig";

import InactiveEmployeeListGangtok from "./EmployeeManagement/inactiveEmployeeListGangtok.component";
import InactiveEmployeeListJorethang from "./EmployeeManagement/inactiveEmployeeListJorethang.component";

const SystemAdminHome = () => {
  useEffect(() => {
    // console.log(localStorage.getItem("role"));
    if (localStorage.getItem("role") === null) {
      window.location.replace("/");
    }
  }, []);
  return (
    <Fragment>
      <Router>
        <ToastProvider>
          <div className="bg-theme-purple-superlight flex">
            <Sidebar />
            <div className="flex flex-col w-full">
              <Topbar />
              <Switch>
                <Route path="/admin/changepassword">
                  <ToastProvider>
                    <ChangePassword />
                  </ToastProvider>
                </Route>
                <Route path="/admin/systemadmindashboard">
                  <SystemAdminDashboard />
                </Route>
                <Route path="/admin/employeelistgangtok">
                  <EmployeeListGangtok />
                </Route>
                <Route path="/admin/inactive/gangtok">
                  <InactiveEmployeeListGangtok />
                </Route>
                <Route path="/admin/employeelistjorethang">
                  <EmployeeListJorethang />
                </Route>
                <Route path="/admin/inactive/jorethang">
                  <InactiveEmployeeListJorethang />
                </Route>
                <Route path="/admin/addemployee">
                  <AddEmployee />
                </Route>
                <Route path="/admin/editemployee">
                  <EditEmployee />
                </Route>
                <Route path="/admin/viewempprogressionhistory">
                  <ViewProgressionHistory />
                </Route>
                <Route path="/admin/addacr">
                  <AddACR />
                </Route>
                <Route path="/admin/userlist">
                  <UserList />
                </Route>
                <Route path="/admin/adduser">
                  <AddUser />
                </Route>
                <Route path="/sectionconfiguration">
                  <SectionConfiguration />
                </Route>
                <Route path="/designationconfiguration">
                  <DesignationConfiguration />
                </Route>
                <Route path="/employeetypeconfiguration">
                  <EmployeeTypeConfiguration />
                </Route>
                <Route path="/levelconfiguration">
                  <LevelConfiguration />
                </Route>
                <Route path="/promotionpatternconfiguration">
                  <PromotionPatternConfiguration />
                </Route>
                <Route path="/levelpatternconfiguration">
                  <LevelPatternConfiguration />
                </Route>
                <Route path="/levelpatternconfiguration">
                  <LevelPatternConfiguration />
                </Route>
                <Route path="/admin/employeeType">
                  <EmployeeType />
                </Route>
                <Route path="/admin/levelWiseReport">
                  <LevelWiseReport />
                </Route>
                <Route path="/admin/promotionPendingList">
                  <PromotionPendingList />
                </Route>
                <Route path="/admin/RetirementList">
                  <RetirementList />
                </Route>
                <Route path="/admin/SalaryIncrementPendingList">
                  <SalaryIncrementList />
                </Route>
                <Route path="/leavetypeconfiguration">
                  <LeaveTypeConfiguration />
                </Route>
                <Route path="/admin/employeeleaves">
                  <EmployeeLeaveConfig />
                </Route>
              </Switch>
            </div>
          </div>
        </ToastProvider>
      </Router>
    </Fragment>
  );
};

export default SystemAdminHome;

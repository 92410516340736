import axios from "axios";
import React, { Fragment, useState } from "react";
import nouser from "../../../assets/noUser.jpg";
import { getFormattedDateInputDate } from "../../../utilities/getFormattedDateInputDate";
import * as XLSX from "xlsx/xlsx.mjs";

export default function EmployeeType() {
  const [empList, setEmpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unitName, setUnitName] = useState("");

  const getEmployeeList = async () => {
    if (unitName !== "") {
      try {
        setIsLoading(true);
        const result = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeWithDesignationDetails`,
          { params: { unitname: unitName } }
        );
        console.log("Emp List", result.data);
        setEmpList(result.data);
        setIsLoading(false);
      } catch (error) {
        console.log("ERROR", error);
      }
    } else {
      alert("Please select a unit");
    }
  };
  const spliceTime = (datetime) => {
    let dateString = datetime;
    if (datetime !== null) {
      dateString = dateString.split("T")[0];
      return dateString;
    } else {
      return "";
    }
  };
  const handleOnExport = () => {
    let regularEmp = [];
    let regularMrEmp = [];
    let consolidateEmp = [];
    let casualMr = [];
    let mrEmp = [];
    empList
      .filter((emp) => emp.empcurrentemptypename === "Regular")
      .map((emp, index) => {
        let empobj = {
          "Sl No": index + 1,
          Name: emp.employeename,
          "Current Designation": emp.empcurrentdesignationname,
          "Initial Designation": emp.empappointeddesignationname,
          "Appointment Date": spliceTime(emp.empappointmentdate),
          "Regularisation Date": spliceTime(emp.regularizeddate),
          "Last Promotion Date": spliceTime(emp.lastpromotiondate),
          "Employee Type": emp.empcurrentemptypename,
        };
        regularEmp.push(empobj);
      });
    empList
      .filter((emp) => emp.empcurrentemptypename === "Regular MR")
      .map((emp, index) => {
        let empobj = {
          "Sl No": index + 1,
          Name: emp.employeename,
          "Current Designation": emp.empcurrentdesignationname,
          "Initial Designation": emp.empappointeddesignationname,
          "Appointment Date": spliceTime(emp.empappointmentdate),
          "Regularisation Date": spliceTime(emp.regularizeddate),
          "Last Promotion Date": spliceTime(emp.lastpromotiondate),
          "Employee Type": emp.empcurrentemptypename,
        };
        regularMrEmp.push(empobj);
      });
    empList
      .filter((emp) => emp.empcurrentemptypename === "Consolidated")
      .map((emp, index) => {
        let empobj = {
          "Sl No": index + 1,
          Name: emp.employeename,
          "Current Designation": emp.empcurrentdesignationname,
          "Initial Designation": emp.empappointeddesignationname,
          "Appointment Date": spliceTime(emp.empappointmentdate),
          "Regularisation Date": spliceTime(emp.regularizeddate),
          "Last Promotion Date": spliceTime(emp.lastpromotiondate),
          "Employee Type": emp.empcurrentemptypename,
        };
        consolidateEmp.push(empobj);
      });
    empList
      .filter((emp) => emp.empcurrentemptypename === "Casual MR")
      .map((emp, index) => {
        let empobj = {
          "Sl No": index + 1,
          Name: emp.employeename,
          "Current Designation": emp.empcurrentdesignationname,
          "Initial Designation": emp.empappointeddesignationname,
          "Appointment Date": spliceTime(emp.empappointmentdate),
          "Regularisation Date": spliceTime(emp.regularizeddate),
          "Last Promotion Date": spliceTime(emp.lastpromotiondate),
          "Employee Type": emp.empcurrentemptypename,
        };
        casualMr.push(empobj);
      });
    empList
      .filter((emp) => emp.empcurrentemptypename === "MR")
      .map((emp, index) => {
        let empobj = {
          "Sl No": index + 1,
          Name: emp.employeename,
          "Current Designation": emp.empcurrentdesignationname,
          "Initial Designation": emp.empappointeddesignationname,
          "Appointment Date": spliceTime(emp.empappointmentdate),
          "Regularisation Date": spliceTime(emp.regularizeddate),
          "Last Promotion Date": spliceTime(emp.lastpromotiondate),
          "Employee Type": emp.empcurrentemptypename,
        };
        mrEmp.push(empobj);
      });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(regularEmp);
    let ws2 = XLSX.utils.json_to_sheet(regularMrEmp);
    let ws3 = XLSX.utils.json_to_sheet(consolidateEmp);
    let ws4 = XLSX.utils.json_to_sheet(casualMr);
    let ws5 = XLSX.utils.json_to_sheet(mrEmp);
    XLSX.utils.book_append_sheet(wb, ws1, "Regular");
    XLSX.utils.book_append_sheet(wb, ws2, "Regular MR");
    XLSX.utils.book_append_sheet(wb, ws3, "Consolidated");
    XLSX.utils.book_append_sheet(wb, ws4, "Casual MR");
    XLSX.utils.book_append_sheet(wb, ws5, "MR");
    XLSX.writeFile(wb, `${unitName}EmployeeDetails.xlsx`);
  };

  return (
    <div className="p-6">
      <p className="font-medium text-xl text-gray-700 pl-1 mb-4">
        Employee Type Wise Report
      </p>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="my-4 px-4 py-2 rounded-md border bg-white w-60">
            <select
              className="w-full"
              onChange={(e) => {
                setUnitName(e.target.value);
                setEmpList([]);
              }}
            >
              <option value="">Select</option>
              <option value="Gangtok">Gangtok</option>
              <option value="Jorethang">Jorethang</option>
            </select>
          </div>
          <button
            className="ml-4 py-2 px-4 bg-sky-blue text-white rounded-sm shadow-md"
            onClick={getEmployeeList}
          >
            View Report
          </button>
        </div>
      </div>
      {empList?.length > 0 && (
        <div className="flex justify-between items-center">
          <div className="p-4 font-bold">{unitName} Employees</div>
          <button
            className="bg-green-500 p-2 text-white focus:outline-none rounded-sm flex items-center shadow-lg mr-4"
            onClick={handleOnExport}
          >
            <span>Export to Excel</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
          </button>
        </div>
      )}
      <section id="report-section">
        <div class="max-w-full">
          <div class="-my-2 overflow-x-scroll sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block max-w-full sm:px-6 lg:px-8">
              <div class="shadow border-b border-gray-200 sm:rounded-lg">
                <table class="w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Current Designation
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Initial Designation
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Appointment Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Regularisation Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Promotion Date
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {!isLoading ? (
                      <>
                        {empList && empList.length > 0 ? (
                          <Fragment>
                            <tr>
                              <td
                                colSpan={6}
                                className="text-left py-2 px-6 font-bold"
                              >
                                Regular
                              </td>
                            </tr>
                            {empList
                              .filter(
                                (emp) => emp.empcurrentemptypename === "Regular"
                              )
                              .map((emp, index) => (
                                <TableData key={index} item={emp} />
                              ))}
                            <tr>
                              <td
                                colSpan={6}
                                className="text-left py-2 px-6 font-bold"
                              >
                                Regular MR
                              </td>
                            </tr>
                            {empList
                              .filter(
                                (emp) =>
                                  emp.empcurrentemptypename === "Regular MR"
                              )
                              .map((emp, index) => (
                                <TableData key={index} item={emp} />
                              ))}
                            <tr>
                              <td
                                colSpan={6}
                                className="text-left py-2 px-6 font-bold"
                              >
                                Consolidated
                              </td>
                            </tr>
                            {empList
                              .filter(
                                (emp) =>
                                  emp.empcurrentemptypename === "Consolidated"
                              )
                              .map((emp, index) => (
                                <TableData key={index} item={emp} />
                              ))}
                            <tr>
                              <td
                                colSpan={6}
                                className="text-left py-2 px-6 font-bold"
                              >
                                MR
                              </td>
                            </tr>
                            {empList
                              .filter(
                                (emp) => emp.empcurrentemptypename === "MR"
                              )
                              .map((emp, index) => (
                                <TableData key={index} item={emp} />
                              ))}
                            <tr>
                              <td
                                colSpan={6}
                                className="text-left py-2 px-6 font-bold"
                              >
                                Casual MR
                              </td>
                            </tr>
                            {empList
                              .filter(
                                (emp) =>
                                  emp.empcurrentemptypename === "Casual MR"
                              )
                              .map((emp, index) => (
                                <TableData key={index} item={emp} />
                              ))}
                          </Fragment>
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center py-2 px-6">
                              No employees found
                            </td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center py-2 px-6">
                          <button
                            type="button"
                            class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm transition ease-in-out duration-150 cursor-not-allowed"
                            disabled={true}
                          >
                            <svg
                              class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-700"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Loading...
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
const TableData = ({ item }) => {
  return (
    <tr>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="flex items-center">
          <div class="flex-shrink-0 h-12 w-12">
            {item.employeephoto ? (
              <img
                class="h-12 w-12 rounded-full"
                src={item.employeephoto}
                alt="user"
              />
            ) : (
              <img class="h-12 w-12 rounded-full" src={nouser} alt="nouser" />
            )}
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {item.employeename}
            </div>
            {/* <div class="text-sm text-gray-500">jane.cooper@example.com</div> */}
          </div>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">
          {item.empcurrentdesignationname}
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">
          {item.empappointeddesignationname}
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">
          {item.empappointmentdate !== null &&
            getFormattedDateInputDate(item.empappointmentdate)}
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">
          {item.regularizeddate !== null &&
            getFormattedDateInputDate(item.regularizeddate)}
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">
          {item.lastpromotiondate !== null &&
            getFormattedDateInputDate(item.lastpromotiondate)}
        </div>
      </td>
    </tr>
  );
};

import React, { useRef, useState } from "react";
import Chevron from "./Chevron";
import "./Accordian.css";

const Accordian = ({ title, content }) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content2 = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content2.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }
  return (
    <>
      <div className="accordion__section">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <p className="accordion__title">{title}</p>
          <Chevron className={`${setRotate}`} width={5} />
        </button>
        <div
          ref={content2}
          style={{ maxHeight: `${setHeight}` }}
          className="accordion__content"
        >
          <div className="accordion__text">{content}</div>
        </div>
      </div>
    </>
  );
};

export default Accordian;

import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { toggleDeleteModal } from "../../../modal-functions/ModalFunctions";
import Loader from "../../../utilities/Loader";

const LevelPatternList = ({
  handlePatternEdit,
  setLevelPatternObj,
  setDeleteLevelPatternObj,
  levelPatternList,
  setLevelPatternList,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleEdit = async (e) => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getLevelPatternDetailsById`,
      {
        params: {
          levelPatternId: e.currentTarget.id,
        },
      }
    );
    setLevelPatternObj({
      levelPatternId: result.data.rows[0].levelpatternid,
      fromLevelId: result.data.rows[0].fromlevelid,
      toLevelId: result.data.rows[0].tolevelid,
      noOfYears: result.data.rows[0].noofyears,
      appointmentType: result.data.rows[0].appointmenttype,
      isConsolidated: result.data.rows[0].isconsolidated,
    });

    handlePatternEdit();
  };

  const handleDelete = async (e) => {
    toggleDeleteModal();
    setDeleteLevelPatternObj({
      levelPatternId: e.currentTarget.id,
    });
  };

  const getLevelPatternList = async () => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getLevelPatternList`
    );
    setLevelPatternList(result.data);
    setIsLoading(false);
  };
  useEffect(() => {
    getLevelPatternList();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <Loader message="Fetching level pattern list." />
      ) : (
        <div className="bg-white rounded-lg w-full p-3">
          <p className="text-lg text-left font-medium mb-3">
            Level Pattern List{" "}
          </p>
          {levelPatternList && levelPatternList.length > 0 ? (
            <div className="text-left">
              <div className="p-3 flex items-center shadow-md text-white font-medium bg-blue-200 text-gray-600 rounded-lg  mb-2 grid grid-cols-7 gap-2 text-left">
                <div className="col-span-1">From level</div>
                <div className="col-span-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
                <div className="col-span-1">To level</div>
                <div className="col-span-1">Recruitment type</div>
                <div className="col-span-1">Deduct Consolidated Years ?</div>
                <div className="col-span-1">No. of years</div>
                <div className="col-span-1">Action</div>
              </div>

              {levelPatternList.map((x) => {
                return (
                  <div className="p-3 flex items-center shadow-md rounded-lg border-l-4 border-blue-300 border-b-none mb-2 grid grid-cols-7 gap-2 text-left">
                    <div className="col-span-1">
                      {" "}
                      <span>Level {x.fromlevelnumber}</span>
                    </div>
                    <div className="col-span-1">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </div>
                    <div className="col-span-1">
                      {" "}
                      <span>Level {x.tolevelnumber}</span>{" "}
                    </div>
                    <div className="col-span-1">{x.appointmenttype}</div>
                    <div className="col-span-1">
                      {x.isconsolidated ? "true" : "false"}
                    </div>
                    <div className="col-span-1 font-medium text-theme-green text-lg">
                      {x.noofyears}
                    </div>
                    <div className="col-span-1 flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5  cursor-pointer"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        id={x.levelpatternid}
                        onClick={handleEdit}
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 cursor-pointer ml-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          id={x.levelpatternid}
                          onClick={handleDelete}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No level patterns added as of now.</div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default LevelPatternList;

import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { toggleDeleteModal } from "../../../modal-functions/ModalFunctions";
import Loader from "../../../utilities/Loader";

const EmployeeTypeList = ({
  handleEmpTypeEdit,
  setEmpTypeObj,
  setDeleteEmpTypeObj,
  empTypeList,
  setEmpTypeList,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleEdit = async (e) => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeDetailsById`,
      {
        params: {
          employeeTypeId: e.currentTarget.id,
        },
      }
    );

    setEmpTypeObj({
      empTypeId: result.data.rows[0].employeetypeid,
      empTypeName: result.data.rows[0].employeetypename,
    });

    handleEmpTypeEdit();
  };

  const handleDelete = async (e) => {
    toggleDeleteModal();
    setDeleteEmpTypeObj({
      empTypeId: e.currentTarget.id,
    });
  };

  const getEmployeeTypeList = async () => {
    let result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeList`
    );
    setEmpTypeList(result.data);
    setIsLoading(false);
  };
  useEffect(() => {
    getEmployeeTypeList();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <Loader message="Fetching employee type list." />
      ) : (
        <div className="bg-white rounded-lg w-3/4 p-3">
          <p className="text-lg text-left font-medium mb-3">
            Employee Type List
          </p>

          {empTypeList && empTypeList.length > 0 ? (
            <div className="text-left">
              <div className="p-3 flex text-white font-medium items-center shadow-md rounded-lg bg-blue-200 text-gray-600 mb-2 grid grid-cols-2 gap-3">
                <div className="col-span-1">Employee type name</div>
                <div className="col-span-1 flex justify-end">Action</div>
              </div>
              {empTypeList.map((x) => {
                return (
                  <div className="p-3 flex items-center shadow-md rounded-lg border-l-4 border-blue-300 border-b-none mb-2 grid grid-cols-2 gap-3">
                    <div className="col-span-1">{x.employeetypename}</div>
                    <div className="col-span-1 flex justify-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5  cursor-pointer"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        id={x.employeetypeid}
                        onClick={handleEdit}
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-9 cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          id={x.employeetypeid}
                          onClick={handleDelete}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No employee types added as of now.</div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default EmployeeTypeList;

const MONTHS = [
  { month: "January", monthid: 1 },
  { month: "February", monthid: 2 },
  { month: "March", monthid: 3 },
  { month: "April", monthid: 4 },
  { month: "May", monthid: 5 },
  { month: "June", monthid: 6 },
  { month: "July", monthid: 7 },
  { month: "August", monthid: 8 },
  { month: "September", monthid: 9 },
  { month: "October", monthid: 10 },
  { month: "November", monthid: 11 },
  { month: "December", monthid: 12 },
];

const RELIGIONLIST = [
  "Hindu",
  "Buddhist",
  "Christian",
  "Muslim",
  "Sikh",
  "Jain",
  "Other religions",
];

const CAST = ["General", "OBC", "ST", "SC"];

const YEARS = [2022, 2023, 2024, 2025];

module.exports = { MONTHS, YEARS, RELIGIONLIST, CAST };

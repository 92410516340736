import React, { useState, useEffect } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DeleteModal from "../../../utilities/DeleteModal";
import AddLevel from "./AddLevel";
import axios from "axios";
import LevelList from "../level-components/LevelList";

const LevelConfiguration = () => {
  const [designationList, setDesignationList] = useState();
  const [levelList, setLevelList] = useState();
  const [isList, setIsList] = useState(true);
  const [isLevelAdd, setIsLevelAdd] = useState(false);
  const [levelObj, setLevelObj] = useState({
    levelId: "",
    designationId: "",
    designationName: "",
    levelNo: "",
    levelName: "",
  });
  const [deleteLevelObj, setDeleteLevelObj] = useState({
    levelId: "",
  });
  const [actionType, setActionType] = useState("");
  const { addToast } = useToasts();

  const handleLevelAdd = () => {
    setIsLevelAdd(true);
    setIsList(false);
    setActionType("add");
  };

  const handleLevelEdit = (e) => {
    setIsLevelAdd(true);
    setIsList(false);
    setActionType("edit");
  };
  const handleBackToList = (calledFrom, deletedId) => {
    setIsLevelAdd(false);
    setIsList(true);
    setActionType("");
    if (calledFrom === "addlevel") {
      addToast("Level successfully added.", {
        appearance: "success",
      });
    }
    if (calledFrom === "editlevel") {
      addToast("Level successfully updated.", {
        appearance: "success",
      });
    }
    if (calledFrom === "deletelevel") {
      let newLevelList = levelList.filter(function removeLevel(x) {
        return parseInt(x.levelid) !== parseInt(deletedId);
      });
      setLevelList(newLevelList);
      addToast("Level successfully deleted.", {
        appearance: "success",
      });
    }
  };

  useEffect(() => {
    let result;
    async function getDesignationList() {
      // result = await axios.get("http://localhost:3001/getDesignationList");
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getDesignationList`
      );
      setDesignationList(result.data);
    }
    getDesignationList();
  }, []);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <span className="font-medium text-xl">Level Configuration</span>
        {isList ? (
          <div>
            <button
              className="bg-theme-green p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handleLevelAdd}
            >
              <span>Add Level</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div>
            <button
              className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center"
              onClick={handleBackToList}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Back to List</span>
            </button>
          </div>
        )}
      </div>
      <div className="rounded-lg">
        {isLevelAdd && (
          <div>
            <ToastProvider autoDismiss={true} placement="top-center">
              <div className="flex justify-center">
                <AddLevel
                  handleBackToList={handleBackToList}
                  actionType={actionType}
                  levelObj={levelObj}
                  designationList={designationList}
                />
              </div>
            </ToastProvider>
          </div>
        )}
        {isList && (
          <div>
            <DeleteModal
              id={deleteLevelObj.levelId}
              type="level"
              handleBackToList={handleBackToList}
            />

            <LevelList
              handleLevelEdit={handleLevelEdit}
              setLevelObj={setLevelObj}
              setDeleteLevelObj={setDeleteLevelObj}
              levelList={levelList}
              setLevelList={setLevelList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LevelConfiguration;

import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../../utilities/Loader";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import { getFormattedDateForMinMax } from "../../../utilities/functions/UtilityFunctions";

const AddAcr = () => {
  const { state } = useLocation();
  console.log("Hannah !", state);
  const { register, handleSubmit, errors, formState } = useForm();
  const appointmentTypes = ["Muster Roll", "Direct Appointment"];
  const [appointmentType, setAppointmentType] = useState();
  const [empType, setEmpType] = useState();
  const [isMr, setIsMr] = useState(true);
  const employeeTypeList = ["MR", "Consolidated", "Regular"];
  const employeeid = state.employeeid;
  const employeename = state.employeename;
  const employeedesignation = state.employeedesignation;
  const employeephoto = state.employeephoto;
  const employeetype = state.employeetype;
  const fromDate = state.fromdate;
  const toDate = state.todate;
  const acrTransId = state.acrtransid;
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmploymentTypeChange = (e) => {
    setEmpType(e.target.value);
    if (e.target.value !== "MR" && e.target.value !== "") {
      setIsMr(false);
    } else {
      setIsMr(true);
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeAcr`,
      {
        data: {
          name: employeename,
          employmentType: data.empType,
          dateOfAppointment: data.dateOfAppointment,
          designation: data.empDesignation,
          presentPayScale: data.payScale,
          recruitmentType: data.recruitmentType,
          redesignationDate: data.redesignationDate,
          totalYearsInService: data.totalYears,
          previousIncrementDate:
            data.empType !== "MR" ? data.prevIncrementDate : null,
          presentIncrementDate:
            data.empType !== "MR" ? data.presentIncrementDate : null,
          dateOfDueIncrement:
            data.empType !== "MR" ? data.dateOfDueIncrement : null,
          levelOfRating: data.empType !== "MR" ? data.levelOfRating : null,
          noOfPromotions: data.empType !== "MR" ? data.noOfPromotions : null,
          natureOfJob: data.natureOfJob,
          natureOfJob_controller: data.natureOfJob_controller,
          knowledgeOfJob: data.knowledgeOfJob,
          knowledgeOfJob_controller: data.knowledgeOfJob_controller,
          dutiesPerformed: data.dutiesPerformed,
          dutiesPerformed_controller: data.dutiesPerformed_controller,
          valuesAdded: data.valuesAdded,
          valuesAdded_controller: data.valuesAdded_controller,
          officeEttiquetes: data.officeEttiquetes,
          officeEttiquetes_controller: data.officeEttiquetes_controller,
          communication: data.communication,
          communication_controller: data.communication_controller,
          fromDate: fromDate,
          toDate: toDate,
          employeeId: employeeid,
          acrTransId: acrTransId,
        },
      }
    );

    if (result.data) {
      history.push({
        pathname: "/sectionhead/acrlist",
        state: {
          employeeid: state.employeeid,
          employeename: state.employeename,
          employeedesignation: state.employeedesignation,
          employeephoto: state.employeephoto,
          employeetype: state.employeetype,
        },
      });
    } else {
      console.log("ERROR : Could not add ACR.");
    }
  };
  useEffect(() => {}, []);

  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between">
          <span className="font-medium text-2xl text-gray-700">ACR FORM</span>
          <Link
            to={{
              pathname: "/sectionhead/acrforwardedempList",
            }}
          >
            <button
              type="button"
              className="bg-gray-900 text-white py-2 px-4 rounded-md focus:outline-none rounded-sm shadow-md flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Back to List</span>
            </button>
          </Link>
        </div>
        <div className="mt-4">
          <p className="text-center font-medium text-lg">
            THE SIKKIM CO-OPERATIVE MILK PRODUCERS' LIMITED
          </p>
          <p className="text-center">TADONG</p>
          <p className="underline text-center mt-3">
            INDIVIDUAL ASSESSMENT FORM
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="bg-white shadow px-4 py-8 mt-4">
              <p className="text-center pb-3">Review for the period from</p>
              <div className="flex justify-center items-center space-x-4">
                <input
                  type="date"
                  name="fromDate"
                  className="border rounded-lg w-64 py-1.5 px-3 text-sm outline-none border-gray-500"
                  ref={register({ required: true })}
                  defaultValue={getFormattedDateForMinMax(fromDate)}
                  disabled
                />
                {errors.fromDate && <Error message="From date is required" />}
                <span>to</span>
                <input
                  type="date"
                  name="toDate"
                  className="border rounded-lg w-64 py-1.5 px-3 text-sm outline-none border-gray-500"
                  ref={register({ required: true })}
                  defaultValue={getFormattedDateForMinMax(toDate)}
                  disabled
                />
                {errors.toDate && <Error message="To date is required" />}
              </div>
              <div className="grid grid-cols-4 gap-6 mt-7">
                <div className="col-span-1">
                  <p>Name :</p>
                  <input
                    type="text"
                    name="empName"
                    className="border bg-gray-200 rounded-md w-full py-2 px-3 text-sm outline-none border-gray-200"
                    defaultValue={employeename}
                    disabled
                    ref={register({ required: true })}
                  />
                </div>
                <div className="col-span-1">
                  <p>Type of employment</p>
                  <select
                    name="empType"
                    ref={register({ required: true })}
                    onChange={handleEmploymentTypeChange}
                    className="border rounded-lg w-full  py-1.5 px-3 border-gray-400 text-sm outline-none"
                    value={empType}
                  >
                    <option value="">--select employee type-- </option>
                    {employeeTypeList &&
                      employeeTypeList.map((x) => {
                        return <option value={x}>{x}</option>;
                      })}
                  </select>
                  {errors.empType && (
                    <Error message="Employee type is required" />
                  )}
                </div>
                <div className="col-span-1">
                  <p>Date of Appointment :</p>
                  <input
                    type="date"
                    name="dateOfAppointment"
                    className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                    ref={register({ required: true })}
                  />
                  {errors.dateOfAppointment && (
                    <Error message="Date of appointment is required" />
                  )}
                </div>
                <div className="col-span-1">
                  <p>Present Designation :</p>
                  <input
                    type="text"
                    name="empDesignation"
                    className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                    ref={register({ required: true })}
                  />
                  {errors.empDesignation && (
                    <Error message="Employee designation is required" />
                  )}
                </div>
                <div className="col-span-1">
                  <p>Present Pay Scale / Salary :</p>
                  <input
                    type="text"
                    name="payScale"
                    ref={register({ required: true })}
                    className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                  />
                  {errors.payScale && (
                    <Error message="Present pay scale is required" />
                  )}
                </div>
                <div className="col-span-1">
                  <p>Recruitment type :</p>
                  <div>
                    {" "}
                    <select
                      name="recruitmentType"
                      ref={register({ required: true })}
                      onChange={(e) => {
                        setAppointmentType(e.target.value);
                      }}
                      className="border rounded-lg w-full  py-1.5 px-3 border-gray-400 text-sm outline-none"
                      value={appointmentType}
                    >
                      <option value="">--select recruitment type-- </option>
                      {appointmentTypes &&
                        appointmentTypes.map((x) => {
                          return <option value={x}>{x}</option>;
                        })}
                    </select>
                    {errors.recruitmentType && (
                      <Error message="Recruitment type is required" />
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <p>Redesignation (If any on date) :</p>
                  <input
                    type="date"
                    name="redesignationDate"
                    className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col-span-1">
                  <p>Total no. of years in Service :</p>
                  <input
                    type="number"
                    name="totalYears"
                    className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                    ref={register({ required: true })}
                  />
                  {errors.totalYears && (
                    <Error message="Total no. of years in service is required" />
                  )}
                </div>
              </div>
              {!isMr ? (
                <Fragment>
                  <div className="grid grid-cols-4 gap-6 mt-7">
                    <div className="col-span-1">
                      <p>Previous increment date :</p>
                      <input
                        type="date"
                        name="prevIncrementDate"
                        ref={register({ required: true })}
                        className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                      />
                      {errors.prevIncrementDate && (
                        <Error message="Previous increment date is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Present increment date :</p>
                      <input
                        type="date"
                        name="presentIncrementDate"
                        ref={register({ required: true })}
                        className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                      />
                      {errors.presentIncrementDate && (
                        <Error message="Present increment date is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Date of due increment :</p>
                      <input
                        type="date"
                        name="dateOfDueIncrement"
                        ref={register({ required: true })}
                        className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                      />
                      {errors.dateOfDueIncrement && (
                        <Error message="Date of due increment is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Level of rating in number :</p>
                      <input
                        type="number"
                        name="levelOfRating"
                        ref={register({ required: true })}
                        className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                      />
                      {errors.levelOfRating && (
                        <Error message="Level of rating is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>No. of promotions till date</p>
                      <input
                        type="number"
                        name="noOfPromotions"
                        className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                        ref={register({ required: true })}
                      />
                      {errors.noOfPromotions && (
                        <Error message="No. of promotions is required" />
                      )}
                    </div>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
            </div>
            {/* <div className="grid grid-cols-3 gap-20 mt-7">
              <div className="col-span-1">
                <p>Name :</p>
                <input
                  type="text"
                  name="empName"
                  className="border bg-gray-200 rounded-md w-full py-2 px-3 text-sm outline-none border-gray-200"
                  defaultValue={employeename}
                  disabled
                  ref={register({ required: true })}
                />
              </div>
              <div className="col-span-1">
                <p>Present Designation :</p>
                <input
                  type="text"
                  name="empDesignation"
                  className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                  ref={register({ required: true })}
                />
                {errors.empDesignation && (
                  <Error message="Employee designation is required" />
                )}
              </div>
              <div className="col-span-1">
                <p>Date of Appointment :</p>
                <input
                  type="date"
                  name="dateOfAppointment"
                  className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                  ref={register({ required: true })}
                />
                {errors.dateOfAppointment && (
                  <Error message="Date of appointment is required" />
                )}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-20 mt-7">
              <div className="col-span-1">
                <p>Present Pay Scale :</p>
                <input
                  type="text"
                  name="payScale"
                  ref={register({ required: true })}
                  className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                />
                {errors.payScale && (
                  <Error message="Present pay scale is required" />
                )}
              </div>
              <div className="col-span-1">
                <p>Previous increment date :</p>
                <input
                  type="date"
                  name="prevIncrementDate"
                  ref={register({ required: true })}
                  className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                />
                {errors.prevIncrementDate && (
                  <Error message="Previous increment date is required" />
                )}
              </div>
              <div className="col-span-1">
                <p>Present increment date :</p>
                <input
                  type="date"
                  name="presentIncrementDate"
                  ref={register({ required: true })}
                  className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                />
                {errors.presentIncrementDate && (
                  <Error message="Present increment date is required" />
                )}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-20 mt-7">
              <div className="col-span-1">
                <p>Date of due increment :</p>
                <input
                  type="date"
                  name="dateOfDueIncrement"
                  ref={register({ required: true })}
                  className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none border-gray-400"
                />
                {errors.dateOfDueIncrement && (
                  <Error message="Date of due increment is required" />
                )}
              </div>
              <div className="col-span-1">
                <p>Level of rating in number :</p>
                <input
                  type="number"
                  name="levelOfRating"
                  ref={register({ required: true })}
                  className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                />
                {errors.levelOfRating && (
                  <Error message="Level of rating is required" />
                )}
              </div>

              <div className="col-span-1">
                <p>Recruitment type :</p>
                <div>
                  {" "}
                  <select
                    name="recruitmentType"
                    ref={register({ required: true })}
                    onChange={(e) => {
                      setAppointmentType(e.target.value);
                    }}
                    className="border rounded-lg w-full  py-1.5 px-3 border-gray-400 text-sm outline-none"
                    value={appointmentType}
                  >
                    <option value="">--select recruitment type-- </option>
                    {appointmentTypes &&
                      appointmentTypes.map((x) => {
                        return <option value={x}>{x}</option>;
                      })}
                  </select>
                  {errors.recruitmentType && (
                    <Error message="Recruitment type is required" />
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-20 mt-7">
              <div className="col-span-1">
                <p>Redesignation (If any on date) :</p>
                <input
                  type="date"
                  name="redesignationDate"
                  className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                  ref={register({ required: false })}
                />
              </div>
              <div className="col-span-1">
                <p>Total no. of years in Service :</p>
                <input
                  type="number"
                  name="totalYears"
                  className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                  ref={register({ required: true })}
                />
                {errors.totalYears && (
                  <Error message="Total no. of years in service is required" />
                )}
              </div>
              <div className="col-span-1">
                <p>No. of promotions till date</p>
                <input
                  type="number"
                  name="noOfPromotions"
                  className="border rounded-md w-full py-2 px-3 text-sm outline-none border-gray-400"
                  ref={register({ required: true })}
                />
                {errors.noOfPromotions && (
                  <Error message="No. of promotions is required" />
                )}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-20 mt-7">
              <div className="col-span-1">
                <p>Type of employee</p>
                <select
                  name="empType"
                  ref={register({ required: true })}
                  onChange={(e) => {
                    setEmpType(e.target.value);
                  }}
                  className="border rounded-lg w-full  py-1.5 px-3 border-gray-400 text-sm outline-none"
                  value={empType}
                >
                  <option value="">--select employee type-- </option>
                  {employeeTypeList &&
                    employeeTypeList.map((x) => {
                      return <option value={x}>{x}</option>;
                    })}
                </select>
                {errors.empType && (
                  <Error message="Employee type is required" />
                )}
              </div>
            </div> */}

            <div className="border mt-12">
              <div className="bg-white shadow">
                <p className="mb-4 text-sky-blue p-3 bg-white border-b font-medium">
                  1. What was your total attendance for the year ?{" "}
                  <span className="italic text-sm">(In numbers)</span>
                </p>
                <div className="grid grid-cols-3 gap-6 p-4">
                  <div className="col-span-1">
                    <span className="text-sm">Total working days :</span>
                    <input
                      type="number"
                      name="totalWorkingDays"
                      ref={register({ required: true })}
                      className="border rounded-md w-2/4 py-2 px-3 text-sm outline-none border-gray-400 ml-3"
                    />
                    {errors.totalWorkingDays && (
                      <Error message="Total working days is required" />
                    )}
                  </div>
                  <div className="col-span-1">
                    <span className="text-sm">No. of days present :</span>
                    <input
                      type="number"
                      name="noOfDaysPresent"
                      ref={register({ required: true })}
                      className="border rounded-md w-2/4 py-2 px-3 text-sm outline-none border-gray-400 ml-3"
                    />
                    {errors.noOfDaysPresent && (
                      <Error message="No. of days present is required" />
                    )}
                  </div>
                  <div className="col-span-1">
                    <span>Leaves :</span>
                    <input
                      type="number"
                      name="noOfLeaves"
                      ref={register({ required: true })}
                      className="border rounded-md w-2/4 py-2 px-3 text-sm outline-none border-gray-400 ml-3"
                    />
                    {errors.noOfLeaves && (
                      <Error message="No. of leaves is required" />
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-12 bg-white shadow">
                <p className="mb-4 text-sky-blue p-3 bg-white border-b font-medium">
                  {" "}
                  2. What is the nature of your job role in the organisation ?{" "}
                </p>{" "}
                <div className="p-4">
                  <p className="italic text-sm">
                    (This includes your daily routine in the organisation)
                  </p>{" "}
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="natureOfJob"
                    ref={register({ required: true })}
                  />
                  {errors.natureOfJob && (
                    <Error message="Nature of job is required" />
                  )}
                  <p className="text-lg font-medium text-gray-800 mt-2">
                    {" "}
                    Controller's Remarks :{" "}
                  </p>
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="natureOfJob_controller"
                    ref={register({ required: true })}
                  />{" "}
                  {errors.natureOfJob_controller && (
                    <Error message="Controller remarks is required" />
                  )}
                </div>
              </div>
              <div className="mt-12 bg-white shadow">
                <p className="mb-4 text-sky-blue p-3 bg-white border-b font-medium">
                  3. What is knowledge about the job role that you currently
                  hold ?
                </p>
                <div className="p-4">
                  <p className="italic text-sm">
                    ( This includes : Knowledge/execution of work, Ability to
                    learn, Quality of work delivered & Creativity at workplace
                    to make tasks convenient )
                  </p>{" "}
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="knowledgeOfJob"
                    ref={register({ required: true })}
                  />
                  {errors.knowledgeOfJob && (
                    <Error message="Knowledge of job is required" />
                  )}
                  <p className="text-lg font-medium mt-2 text-gray-800">
                    {" "}
                    Controller's Remarks :{" "}
                  </p>
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="knowledgeOfJob_controller"
                    ref={register({ required: true })}
                  />
                  {errors.knowledgeOfJob && (
                    <Error message="Controller remarks is required" />
                  )}
                </div>
              </div>
              <div className="mt-12 bg-white shadow">
                <p className="mb-4 text-sky-blue p-3 bg-white border-b font-medium">
                  4. What are the duties that you have performed in the last
                  year ?
                </p>
                <div className="p-4">
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="dutiesPerformed"
                    ref={register({ required: true })}
                  />
                  {errors.dutiesPerformed && (
                    <Error message="Duties performed is required" />
                  )}
                  <p className="text-lg font-medium mt-4 text-gray-800">
                    {" "}
                    Controller's Remarks :{" "}
                  </p>
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="dutiesPerformed_controller"
                    ref={register({ required: true })}
                  />
                  {errors.dutiesPerformed_controller && (
                    <Error message="Controller remarks is required" />
                  )}
                </div>
              </div>
              <div className="mt-12 bg-white shdaow">
                <p className="mb-4 text-sky-blue p-3 bg-white border-b font-medium">
                  5. What value have you added to the organisation by applying
                  your innovative idea and how has the company benefitted ?
                </p>
                <div className="p-4">
                  <p className="italic text-sm">
                    ( This includes extra work to make your job role valuable or
                    any idea that was profitable for the organisation )
                  </p>{" "}
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="valuesAdded"
                    ref={register({ required: true })}
                  />
                  {errors.valuesAdded && (
                    <Error message="values added is required" />
                  )}
                  <p className="text-lg font-medium text-gray-800 mt-4">
                    {" "}
                    Controller's Remarks :{" "}
                  </p>
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="valuesAdded_controller"
                    ref={register({ required: true })}
                  />
                  {errors.valuesAdded_controller && (
                    <Error message="Controller remarks is required" />
                  )}
                </div>
              </div>
              <div className="mt-12 bg-white shdaow">
                <p className="mb-4 text-sky-blue p-3 bg-white border-b font-medium">
                  6. How well are you aware of Office etiquettes ?
                </p>
                <div className="p-4">
                  <p className="italic text-sm">
                    ( This includes : Punctuality, Attendance, Integrity,
                    Personal appearance, dealing with colleagues, public or
                    seniors )
                  </p>

                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="officeEttiquetes"
                    ref={register({ required: true })}
                  />
                  {errors.officeEttiquetes && (
                    <Error message="Office ettiquetes is required" />
                  )}
                  <p className="text-lg font-medium text-gray-800 mt-4">
                    {" "}
                    Controller's Remarks :{" "}
                  </p>
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="officeEttiquetes_controller"
                    ref={register({ required: true })}
                  />
                  {errors.officeEttiquetes_controller && (
                    <Error message="Controller remarks is required" />
                  )}
                </div>
              </div>
              <div className="mt-12 bg-white shadow">
                <p className="mb-4 text-sky-blue p-3 bg-white border-b font-medium">
                  7. how good is your communication ?
                </p>
                <div className="p-4">
                  <p className="italic text-sm">
                    ( This includes : Communication with colleagues, voicing out
                    your opinion and thoughts about your job role or the
                    organisation )
                  </p>

                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="communication"
                    ref={register({ required: true })}
                  />
                  {errors.communication && (
                    <Error message="Communication is required" />
                  )}
                  <p className="text-lg font-medium text-gray-800 mt-4">
                    Controller's Remarks :{" "}
                  </p>
                  <textarea
                    className="border rounded-md w-full h-32 py-2 px-3 text-sm outline-none border-gray-400"
                    name="communication_controller"
                    ref={register({ required: true })}
                  />
                  {errors.communication_controller && (
                    <Error message="Controller remarks is required" />
                  )}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="py-2 px-6 rounded-md bg-sky-blue text-white mt-8 flex items-center"
              disabled={isSubmitting ? true : false}
            >
              {isSubmitting ? (
                <Fragment>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Submitting</span>
                </Fragment>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddAcr;

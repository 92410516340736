import axios from "axios";
import React, { useEffect, useState } from "react";
import { getFormattedDateInputDate } from "../../../../utilities/getFormattedDateInputDate";
import LeaveEntryModal from "./LeaveEntryModal";

export default function LeaveHistoryList({ employeedetails }) {
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const onClickAdd = () => {
    setIsLeaveModalVisible(true);
  };
  const getLeaveHistoryList = async () => {
    try {
      setIsLoading(true);
      let result;
      result = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeLeaveHistory`,
        { params: { employeeid: employeedetails?.employeeid } }
      );
      setLeaveHistory(result.data);
    } catch (error) {
      console.log("Error", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getLeaveHistoryList();
  }, []);
  return (
    <div className="p-4 my-6 bg-white">
      <LeaveEntryModal
        isVisible={isLeaveModalVisible}
        setIsLeaveModalVisible={setIsLeaveModalVisible}
        setLeaveHistory={setLeaveHistory}
        employeedetails={employeedetails}
        getLeaveHistoryList={getLeaveHistoryList}
      />
      <div className="flex items-center justify-between border-b pb-3">
        <p className="font-medium">Leave History</p>
        <button
          className="bg-sky-blue px-3 py-2 text-white focus:outline-none rounded-md flex items-center shadow-lg text-sm font-medium"
          onClick={onClickAdd}
        >
          <span>Add Leave</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 ml-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      {leaveHistory?.length > 0 ? (
        <div className="flex flex-col px-1 mt-4">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 text-sm">
                  <thead className="bg-gray-200 text-gray-800">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                      >
                        Sl No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                      >
                        Leave Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                      >
                        From
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                      >
                        To
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                      >
                        Days
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-medium uppercase tracking-wider"
                      >
                        Reason
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {leaveHistory.map((leavetype, index) => (
                      <tr>
                        <td className="px-6 py-2 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          {leavetype.leavetypename}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          {getFormattedDateInputDate(leavetype.datefrom)}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          {getFormattedDateInputDate(leavetype.dateto)}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          {leavetype.numberofdays}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          {leavetype.reason}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-6 bg-gray-50 border text-center font-medium mt-4">
          No leave history found !
        </div>
      )}
    </div>
  );
}

import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../utilities/Loader";
import nouser from "../../../assets/noUser.jpg";

const EmployeeList = () => {
  const [empList, setEmpList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const getEmployeeList = async () => {
    let result;
    result = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeListWithCurrentDetailsGangtok`
    );

    setEmpList(result.data);
    setIsLoading(false);
  };
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between">
          <span className="font-medium text-2xl text-gray-700 pl-1">
            Gangtok Employees
          </span>
        </div>
        <div className="rounded-lg">
          {isLoading ? (
            <Loader message={"fetching employees...."} />
          ) : (
            <Fragment>
              <div className="flex items-center justify-between py-4 sticky top-14 z-99 bg-gray-100 px-1">
                <div class="flex items-center text-sm w-96 rounded-lg border border-gray-300 bg-gray-900">
                  <input
                    class="rounded-l-md w-full px-4 py-3 text-gray-800 focus:outline-none"
                    id="search"
                    type="text"
                    placeholder="Search employee name"
                    onChange={handleSearchTextChange}
                    value={searchText}
                    autoComplete="off"
                    autoFocus
                  ></input>
                  <div class="py-2 px-3 bg-gray-900">
                    {!searchText ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-white cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-white font-bold cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={() => {
                          setSearchText("");
                        }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <div class="flex flex-col px-1">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Designation
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Employee Type
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Unit
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">View</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          {empList && empList.length > 0 ? (
                            <Fragment>
                              {searchText === "" ? (
                                <Fragment>
                                  {empList.map((emp, index) => (
                                    <TableData key={index} item={emp} />
                                  ))}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {empList
                                    .filter((x) =>
                                      x.employeename
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    )
                                    .map((emp, index) => (
                                      <TableData key={index} item={emp} />
                                    ))}
                                </Fragment>
                              )}
                            </Fragment>
                          ) : (
                            <div>No employess found !</div>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const TableData = ({ item }) => {
  return (
    <tr>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="flex items-center">
          <div class="flex-shrink-0 h-12 w-12">
            {item.employeephoto ? (
              <img
                class="h-12 w-12 rounded-full"
                src={item.employeephoto}
                alt="user"
              />
            ) : (
              <img class="h-12 w-12 rounded-full" src={nouser} alt="nouser" />
            )}
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {item.employeename}
            </div>
            {/* <div class="text-sm text-gray-500">jane.cooper@example.com</div> */}
          </div>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="text-sm text-gray-900">{item.empdesignationname}</div>
        <div class="text-sm text-gray-500">{item.empsectionname}</div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {item.emptypename}
        </span>
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {item.empunitname}
      </td>
      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link
          to={{
            pathname: "/highermanagement/employeedetails",
            state: {
              employeeid: item.employeeid,
              unitname: "Gangtok",
            },
          }}
        >
          <span className="text-indigo-600 hover:text-indigo-900">
            View details
          </span>
        </Link>
      </td>
    </tr>
  );
};

export default EmployeeList;

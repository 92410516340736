const monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const getFormattedDateInputDate = (date) => {
  let dd, mm, yy;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  return `${dd}, ${monthsArr[mm]} ${yy}`;
};

const getFormattedDateInputDateYYYYMMDD = (date) => {
  let dd, mm, yy;
  dd = new Date(date).getDate();
  mm = new Date(date).getMonth();
  yy = new Date(date).getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  mm = mm + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${yy}-${mm}-${dd}`;
};

module.exports = {
  getFormattedDateInputDate,
  getFormattedDateInputDateYYYYMMDD,
};

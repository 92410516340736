import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Error from "../../../utilities/Error";
import axios from "axios";

const AddEmployeeType = ({ handleBackToList, actionType, empTypeObj }) => {
  const { register, handleSubmit, errors, reset, formState } = useForm();

  const onSubmit = async (data) => {
    if (actionType === "add") {
      // const result = await axios.post("http://localhost:3001/addEmployeeType",
      const result = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/addEmployeeType`,
        {
          data: {
            employeeTypeName: data.employeeTypeName,
          },
        }
      );
      if (result !== null && result !== "") {
        handleBackToList("addemptype");
      }
    } else if (actionType == "edit") {
      console.log("INSIDE EDIT : ", empTypeObj);
      const result = await axios.post(
        // "http://localhost:3001/editEmployeeTypeDetails",
        `${process.env.REACT_APP_ENDPOINT_URL}/editEmployeeTypeDetails`,
        {
          data: {
            employeeTypeId: empTypeObj.empTypeId,
            employeeTypeName: data.employeeTypeName,
          },
        }
      );

      if (result !== null && result !== "") {
        handleBackToList("editemptype");
      }
    }
  };

  const handleCancel = () => {
    handleBackToList();
  };
  return (
    <Fragment>
      {actionType === "add" ? (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">
            Add Employee Type{" "}
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <p>Employee type name :</p>
            <input
              name="employeeTypeName"
              type="text"
              className={`border rounded-lg w-3/4 py-1.5 px-3 text-sm outline-none ${
                errors.employeeTypeName ? "border-red-700" : ""
              }`}
              ref={register({ required: true })}
            />
            {errors.employeeTypeName && (
              <Error message="Employee type name is required" />
            )}
            <br></br>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="bg-white w-1/2 p-3 rounded-lg shadow-xl">
          <p className="text-lg text-left font-medium mb-3">
            Edit Employee type
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="text-left">
            <input
              type="hidden"
              name="employeeTypeId"
              defaultValue={empTypeObj.empTypeId}
            />
            <p>Employee Type name :</p>
            <input
              name="employeeTypeName"
              type="text"
              defaultValue={empTypeObj.empTypeName}
              className={`border rounded-lg w-3/4 py-1.5 px-3 text-sm outline-none ${
                errors.employeeTypeName ? "border-red-700" : ""
              }`}
              ref={register({ required: true })}
            />
            {errors.employeeTypeName && (
              <Error message="Employee type name is required" />
            )}
            <br></br>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-theme-green text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
              >
                Submit
              </button>
              <button
                className="bg-gray-400 text-white focus:outline-none rounded-sm text-sm px-4 py-1 mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};
export default AddEmployeeType;

import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Loader from "../../../utilities/Loader";
import axios from "axios";
import Error from "../../../utilities/Error";
import { useForm } from "react-hook-form";
import { getFormattedDateForMinMax } from "../../../utilities/functions/UtilityFunctions";
import { CAST, MONTHS, RELIGIONLIST } from "../../Constants";

const noUserImage = require("../../../assets/noUser.jpg");

const EditEmployee = () => {
  const { state } = useLocation();
  const employeeid = state.employeeid;
  const unitname = state.unitname;
  const [appointmentType, setAppointmentType] = useState();

  const { register, handleSubmit, errors, formState } = useForm();
  const [designationList, setDesignationList] = useState();
  const [levelList, setLevelList] = useState();
  const [sectionList, setSectionList] = useState();
  const [employeeTypeList, setEmployeeTypeList] = useState();
  const [designationId, setDesignationId] = useState();
  const [designationIdApp, setDesignationIdApp] = useState();
  const [levelId, setLevelId] = useState();
  const [levelIdApp, setLevelIdApp] = useState();
  const [sectionId, setSectionId] = useState();
  const [sectionIdApp, setSectionIdApp] = useState();
  const [empTypeId, setEmpTypeId] = useState();
  const [empTypeIdApp, setEmpTypeIdApp] = useState();
  const unitList = ["Gangtok", "Jorethang"];
  const appointmentTypes = ["Muster Roll", "Direct Appointment"];
  const [empPhotoSrc, setEmpPhotoSrc] = useState(noUserImage.default);
  const [hasCoi, setHasCoi] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [empPersonalDetails, setEmpPersonalDetails] = useState({});
  const [empAppointmentDetails, setEmpAppointmentDetails] = useState({});
  const [empDob, setEmpDob] = useState();
  const [empRetirementDate, setEmpRetirementDate] = useState();

  const [datePromotedToCurrentStatus, setDatePromotedToCurrentStatus] =
    useState();
  const [dateOfRegularization, setDateOfRegularization] = useState();

  const [empCurrentOfficialDetails, setEmpCurrentOfficialDetails] = useState(
    {}
  );

  const handleDesignationChange = (e) => {
    setDesignationId(e.target.value);
  };

  const handleDesignationChangeApp = (e) => {
    setDesignationIdApp(e.target.value);
  };

  const handleLevelChange = (e) => {
    setLevelId(e.target.value);
  };

  const handleLevelChangeApp = (e) => {
    setLevelIdApp(e.target.value);
  };
  const handleSectionChange = (e) => {
    setSectionId(e.target.value);
  };
  const handleSectionChangeApp = (e) => {
    setSectionIdApp(e.target.value);
  };

  const handleEmployeeTypeChange = (e) => {
    setEmpTypeId(e.target.value);
  };

  const handleEmployeeTypeChangeApp = (e) => {
    setEmpTypeIdApp(e.target.value);
  };

  const handleEmpPhotoChange = (e) => {
    setEmpPhotoSrc(URL.createObjectURL(e.target.files[0]));
  };

  const handleCoiRadioChange = (e) => {
    setHasCoi(e.target.value);
  };

  const getConfigurationsList = async () => {
    let result1;
    result1 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getDesignationList`
    );
    setDesignationList(result1.data);
    let result2;
    result2 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getLevelList`
    );
    setLevelList(result2.data);
    let result3;
    result3 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getSectionList`
    );
    setSectionList(result3.data);
    let result4;
    result4 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeTypeList`
    );
    setEmployeeTypeList(result4.data);
  };

  const getEmployeeDetails = async () => {
    let result1 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeePersonalDetails`,
      {
        params: {
          employeeId: employeeid,
        },
      }
    );
    if (result1.data.employeephoto) {
      setEmpPhotoSrc(result1.data.employeephoto);
    } else {
      setEmpPhotoSrc(noUserImage.default);
    }
    if (
      result1.data.employeedateofbirth !== "" &&
      result1.data.employeedateofbirth !== null
    ) {
      setEmpDob(getFormattedDateForMinMax(result1.data.employeedateofbirth));
    }
    if (
      result1.data.retirementdate !== "" &&
      result1.data.retirementdate !== null
    ) {
      setEmpRetirementDate(
        getFormattedDateForMinMax(result1.data.retirementdate)
      );
    }
    setEmpPersonalDetails(result1.data);
    console.log("Per", result1.data);
    let result2 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeAppointmentDetails`,
      {
        params: {
          employeeId: employeeid,
        },
      }
    );
    if (result2.data.empappointeddesignationid) {
      setDesignationIdApp(result2.data.empappointeddesignationid);
    }
    if (result2.data.empappointedemptypeid) {
      setEmpTypeIdApp(result2.data.empappointedemptypeid);
    }
    if (result2.data.empappointedlevelid) {
      setLevelIdApp(result2.data.empappointedlevelid);
    }
    if (result2.data.empappointedsectionid) {
      setSectionIdApp(result2.data.empappointedsectionid);
    }
    if (
      result2.data.empappointmentdate !== null &&
      result2.data.empappointmentdate !== ""
    ) {
      setAppointmentDate(
        getFormattedDateForMinMax(result2.data.empappointmentdate)
      );
    }
    if (result2.data.has_coi) {
      setHasCoi(true);
    } else {
      setHasCoi(false);
    }
    setEmpAppointmentDetails(result2.data);
    let result3 = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/getEmployeeCurrentOfficialDetails`,
      {
        params: {
          employeeId: employeeid,
        },
      }
    );
    if (result3.data.empcurrentdesignationid) {
      setDesignationId(result3.data.empcurrentdesignationid);
    }
    if (result3.data.empcurrentlevelid) {
      setLevelId(result3.data.empcurrentlevelid);
    }
    if (result3.data.empcurrentemptypeid) {
      setEmpTypeId(result3.data.empcurrentemptypeid);
    }
    if (result3.data.empcurrentsectionid) {
      setSectionId(result3.data.empcurrentsectionid);
    }
    if (
      result3.data.datepromotedtocurrentstatus !== null &&
      result3.data.datepromotedtocurrentstatus !== ""
    ) {
      setDatePromotedToCurrentStatus(
        getFormattedDateForMinMax(result3.data.datepromotedtocurrentstatus)
      );
    }
    if (
      result3.data.dateofregularization !== null &&
      result3.data.dateofregularization !== ""
    ) {
      setDateOfRegularization(
        getFormattedDateForMinMax(result3.data.dateofregularization)
      );
    }
    setEmpCurrentOfficialDetails(result3.data);
    setIsLoading(false);
  };
  const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };
  async function uploadImageToCloudinary(base64) {
    let data = new FormData();
    data.append("upload_preset", process.env.uploadPreset || "duq8w80m");
    data.append("cloud_name", process.env.cloundName || "dqexskoss");
    data.append("file", base64);
    data.append("folder", "SMU-HRMS");

    const url = `https://api.cloudinary.com/v1_1/${
      process.env.cloundName || "dqexskoss"
    }/image/upload`;
    let result = await axios.post(url, data);
    if (result) {
      return result.data.secure_url;
    }
  }
  const onSubmit = async (data) => {
    setIsSubmitting(true);
    let coiDoc = "";
    let coiDocBase64 = "";
    let birthDoc = "";
    let birthDocBase64 = "";
    let voterDoc = "";
    let voterDocBase64 = "";
    let empPassPhoto = "";
    let empPassPhotoBase64;
    if (data.coidoc.length > 0) {
      coiDocBase64 = await readUploadedFileAsText(data.coidoc[0]);
      coiDoc = await uploadImageToCloudinary(coiDocBase64);
    }
    if (data.birthdoc.length > 0) {
      birthDocBase64 = await readUploadedFileAsText(data.birthdoc[0]);
      birthDoc = await uploadImageToCloudinary(birthDocBase64);
    }
    if (data.voterdoc.length > 0) {
      voterDocBase64 = await readUploadedFileAsText(data.voterdoc[0]);
      voterDoc = await uploadImageToCloudinary(voterDocBase64);
    }
    if (data.employeePhoto.length > 0) {
      empPassPhotoBase64 = await readUploadedFileAsText(data.employeePhoto[0]);
      empPassPhoto = await uploadImageToCloudinary(empPassPhotoBase64);
    }
    //UPDATE EMPLOYEE PERSONAL DETAILS
    const result1 = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/editEmployeePersonalDetails`,
      {
        data: {
          empId: empPersonalDetails.employeeid,
          empCode: "",
          empName: data.empName,
          empContact: data.empContact,
          empDob: empDob,
          empAddress: data.empAddress,
          empPhoto:
            empPassPhoto !== ""
              ? empPassPhoto
              : empPersonalDetails.employeephoto,
          empReligion: data.religion,
          educationQualification: data.educationqualification,
          gender: data.gender,
          empcast: data.cast,
        },
      }
    );
    if (result1.data) {
      //UPDATE EMPLOYEE APPOINTMENT DETAILS
      const result2 = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}/editEmployeeAppointmentDetails`,
        {
          data: {
            empId: empPersonalDetails.employeeid,
            empAppointmentType: data.appointmentType,
            empAppointmentDate: appointmentDate,
            empAppointedDesignationId: data.empDesignationIdApp,
            empAppointedLevelId: data.empLevelIdApp,
            empAppointedTypeId: data.empTypeIdApp,
            hasCoi: hasCoi,
            coiDocument:
              coiDoc !== "" ? coiDoc : empAppointmentDetails.coi_document,
            birthDocument:
              birthDoc !== "" ? birthDoc : empAppointmentDetails.dob_document,
            voterDocument:
              voterDoc !== ""
                ? voterDoc
                : empAppointmentDetails.votercard_document,
            empAppointedSectionId: data.empSectionIdApp,
            empAppointedUnit: data.empUnitApp,
            firstIncrementMonth: data.firstIncrementMonth,
            secondIncrementMonth: data.secondIncrementMonth,
          },
        }
      );

      if (result2.data) {
        //UPDATE EMPLOYEE CURRENT OFFICIAL DETAILS
        const result3 = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/editEmployeeCurrentOfficialDetails`,
          {
            data: {
              empId: empPersonalDetails.employeeid,
              empCurrentEmpTypeId: data.empTypeId,
              empCurrentLevelId: data.empLevelId,
              empCurrentDesignationId: data.empDesignationId,
              empPromotedToCurrentDate: datePromotedToCurrentStatus,
              empCurrentSectionId: data.empSectionId,
              empCurrentUnit: data.empUnit,
              empDateOfRegularization: dateOfRegularization,
            },
          }
        );
        if (result3.data) {
          if (unitname === "Gangtok") {
            window.location.replace(
              `${process.env.REACT_APP_REDIRECT_URL}admin/employeelistgangtok`
            );
          } else {
            window.location.replace(
              `${process.env.REACT_APP_REDIRECT_URL}admin/employeelistjorethang`
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    getEmployeeDetails();
    getConfigurationsList();
  }, []);
  return (
    <Fragment>
      <div className="p-6">
        <div className="flex justify-between">
          <span className="font-medium text-xl">Edit Employee Details</span>
          {unitname === "Gangtok" ? (
            <Fragment>
              <Link to="/admin/employeelistgangtok" className="mb-5">
                <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List </span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          ) : (
            <Fragment>
              <Link to="/admin/employeelistjorethang" className="mb-5">
                <button className="bg-gray-900 p-2 text-white focus:outline-none rounded-sm flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Back to Employee List</span>
                </button>{" "}
              </Link>{" "}
            </Fragment>
          )}
        </div>
        {isLoading ? (
          <Loader message={"fetching employee details..."} />
        ) : (
          <div className="w-full rounded-lg">
            <form onSubmit={handleSubmit(onSubmit)} className="text-left">
              <div className="p-3 shadow bg-white">
                <div className="w-full rounded-sm border-b font-medium text-blue-600 p-2 mb-2">
                  Personal Details
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-5 gap-5">
                    <div className="col-span-4">
                      <div className="grid grid-cols-3 gap-3">
                        <div className="col-span-1">
                          <p>
                            Employee code{" "}
                            <small className="ml-1">(if any)</small>
                          </p>
                          <input
                            name="empCode"
                            placeholder="enter employee code"
                            type="text"
                            ref={register({ required: false })}
                            className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none`}
                            defaultValue={empPersonalDetails.employeecode}
                            disabled={true}
                          />
                        </div>
                        <div className="col-span-1">
                          <p>Employee name</p>
                          <input
                            name="empName"
                            type="text"
                            defaultValue={empPersonalDetails.employeename}
                            placeholder="enter employee name"
                            className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                              errors.empName ? "border-red-700" : ""
                            }`}
                            ref={register({ required: true })}
                          />
                          {errors.empName && (
                            <Error message="Employee name is required" />
                          )}
                        </div>
                        <div className="col-span-1">
                          <p>Contact No.</p>
                          <input
                            name="empContact"
                            defaultValue={empPersonalDetails.employeecontact}
                            ref={register({ required: false })}
                            placeholder="enter employee contact"
                            type="text"
                            className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                              errors.empContact ? "border-red-700" : ""
                            }`}
                          />

                          {errors.empContact && (
                            <Error message="Employee contact is required" />
                          )}
                        </div>
                      </div>
                      <div className="mt-3 grid grid-cols-3 gap-3">
                        <div className="col-span-1">
                          <p>Education Qualification</p>
                          <input
                            name="educationqualification"
                            type="text"
                            defaultValue={
                              empPersonalDetails.educationqualification
                            }
                            placeholder="enter employee name"
                            className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                              errors.educationqualification
                                ? "border-red-700"
                                : ""
                            }`}
                            ref={register({ required: false })}
                          />
                          {errors.educationqualification && (
                            <Error message="Education qualification is required" />
                          )}
                        </div>
                        <div className="col-span-1">
                          <p>Date of birth</p>
                          <input
                            name="empDob"
                            type="date"
                            value={empDob}
                            onChange={(e) => setEmpDob(e.target.value)}
                            className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                              errors.empDob ? "border-red-700" : ""
                            }`}
                            ref={register({ required: false })}
                          />
                          {errors.empDob && (
                            <Error message="Employee date of birth is required" />
                          )}
                        </div>

                        <div className="col-span-1">
                          <label>Employee has COI ?</label>
                          <div className="flex items-center ml-2">
                            <label>
                              <input
                                type="radio"
                                name="coiradio"
                                className="mr-1"
                                defaultChecked={
                                  empAppointmentDetails.has_coi ? true : false
                                }
                                value="true"
                                ref={register({ required: true })}
                                onChange={handleCoiRadioChange}
                              />
                              Yes
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="coiradio"
                                defaultChecked={
                                  empAppointmentDetails.has_coi ? false : true
                                }
                                className="ml-5 mr-1"
                                value="false"
                                ref={register({ required: true })}
                                onChange={handleCoiRadioChange}
                              />
                              No
                            </label>
                            {errors.coiradio && (
                              <Error message="Please select whether employee has COI" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 grid grid-cols-3 gap-3">
                        <div className="col-span-1">
                          <p>Religion</p>
                          <select
                            name="religion"
                            defaultValue={empPersonalDetails.employeereligion}
                            ref={register({ required: true })}
                            className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                              errors.religion ? "border-red-700" : ""
                            }`}
                          >
                            <option value="">--select religion-- </option>
                            {RELIGIONLIST &&
                              RELIGIONLIST.map((x) => {
                                return <option value={x}>{x}</option>;
                              })}
                          </select>
                        </div>
                        <div className="col-span-1">
                          <p>Gender</p>
                          <select
                            name="gender"
                            defaultValue={empPersonalDetails.gender}
                            ref={register({ required: true })}
                            className={`border rounded-lg w-full  py-2 px-3 text-sm outline-none ${
                              errors.gender ? "border-red-700" : ""
                            }`}
                          >
                            <option value="">--select gender-- </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {errors.gender && (
                            <Error message="Gender is required" />
                          )}
                        </div>
                        <div className="col-span-1">
                          <p>Cast</p>
                          <select
                            name="cast"
                            ref={register({ required: true })}
                            defaultValue={empPersonalDetails.empcast}
                            className={`border rounded-lg w-full  py-2 px-3 text-sm outline-none ${
                              errors.cast ? "border-red-700" : ""
                            }`}
                          >
                            <option value="">--select cast-- </option>
                            {CAST.map((cast) => {
                              return <option value={cast}>{cast}</option>;
                            })}
                          </select>
                          {errors.cast && (
                            <Error message="Employee cast is required" />
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-3 mt-3">
                        <div className="col-span-3">
                          <p>Address</p>
                          <input
                            name="empAddress"
                            type="textarea"
                            defaultValue={empPersonalDetails.employeeaddress}
                            placeholder="enter employee address"
                            className={`border rounded-lg w-full h-16 px-3 text-sm outline-none ${
                              errors.empAddress ? "border-red-700" : ""
                            }`}
                            ref={register({ required: true })}
                          />
                          {errors.empAddress && (
                            <Error message="Employee address is required" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 flex justify-c">
                      <Fragment>
                        {" "}
                        <div class="">
                          <img src={empPhotoSrc} className="h-56 w-auto" />
                          <input
                            type="file"
                            name="employeePhoto"
                            ref={register({ required: false })}
                            onChange={handleEmpPhotoChange}
                            className="my-2"
                          />
                        </div>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 shadow bg-white mt-4">
                <div className="w-full rounded-sm border-b font-medium text-blue-600 p-2 mb-2">
                  Appointment Details
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-1">
                      <p>Appointment type</p>
                      <div>
                        {" "}
                        <select
                          name="appointmentType"
                          defaultValue={
                            empAppointmentDetails.empappointmenttype
                          }
                          ref={register({ required: true })}
                          className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                            errors.appointmentType ? "border-red-700" : ""
                          }`}
                          value={appointmentType}
                        >
                          <option value="">--select appointment type-- </option>
                          {appointmentTypes &&
                            appointmentTypes.map((x) => {
                              return <option value={x}>{x}</option>;
                            })}
                        </select>
                      </div>

                      {errors.appointmentType && (
                        <Error message="Appointment type is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Appointment date</p>
                      <input
                        name="empAppointmentDate"
                        onChange={(e) => {
                          setAppointmentDate(e.target.value);
                        }}
                        value={appointmentDate}
                        type="date"
                        className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                          errors.empAppointmentDate ? "border-red-700" : ""
                        }`}
                        ref={register({ required: false })}
                      />
                      {errors.empAppointmentDate && (
                        <Error message="Appointment date  is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Appointed in Designation</p>
                      <div>
                        <select
                          name="empDesignationIdApp"
                          ref={register({ required: false })}
                          onChange={handleDesignationChangeApp}
                          defaultValue={
                            empAppointmentDetails.empappointeddesignationname
                          }
                          className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                            errors.empDesignationIdApp ? "border-red-700" : ""
                          }`}
                          value={designationIdApp}
                        >
                          <option value="">--select designation--</option>

                          {designationList &&
                            designationList.map((x) => {
                              return (
                                <option value={x.designationid}>
                                  {x.designationname}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      {errors.empDesignationIdApp && (
                        <Error message="Appointment in designation is required" />
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-3 mt-3">
                    <div className="col-span-1">
                      <p>Appointment as Employee Type</p>
                      <div>
                        <select
                          name="empTypeIdApp"
                          ref={register({ required: true })}
                          onChange={handleEmployeeTypeChangeApp}
                          className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                            errors.empTypeIdApp ? "border-red-700" : ""
                          }`}
                          value={empTypeIdApp}
                        >
                          <option value="">--select employee type-- </option>
                          {employeeTypeList &&
                            employeeTypeList.map((x) => {
                              return (
                                <option value={x.employeetypeid}>
                                  {x.employeetypename}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      {errors.empTypeIdApp && (
                        <Error message="Appointed as Employee type is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Appointment in Level</p>
                      <div>
                        <select
                          name="empLevelIdApp"
                          ref={register({ required: false })}
                          onChange={handleLevelChangeApp}
                          className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                            errors.empLevelIdApp ? "border-red-700" : ""
                          }`}
                          value={levelIdApp}
                        >
                          <option value="">--select level-- </option>
                          {levelList &&
                            levelList.map((x) => {
                              return (
                                <option value={x.levelid}>
                                  {" "}
                                  Level {x.levelnumber}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      {errors.empLevelIdApp && (
                        <Error message="Appointment in level is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Appointment in Unit</p>
                      <select
                        name="empUnitApp"
                        defaultValue={
                          empAppointmentDetails.empappointedunitname
                        }
                        ref={register({ required: true })}
                        className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                          errors.empUnitApp ? "border-red-700" : ""
                        }`}
                      >
                        <option value="">--select unit-- </option>
                        {unitList &&
                          unitList.map((x) => {
                            return <option value={x}> {x}</option>;
                          })}
                      </select>
                      {errors.empUnitApp && (
                        <Error message="Appointmed in unit is required" />
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-3 mt-3">
                    <div className="col-span-1">
                      <p>Appointed in Section</p>
                      <select
                        name="empSectionIdApp"
                        ref={register({ required: false })}
                        onChange={handleSectionChangeApp}
                        className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                          errors.empSectionIdApp ? "border-red-700" : ""
                        }`}
                        value={sectionIdApp}
                      >
                        <option value="">--select section-- </option>
                        {sectionList &&
                          sectionList.map((x) => {
                            return (
                              <option value={x.sectionid}>
                                {x.sectionname}
                              </option>
                            );
                          })}
                      </select>
                      {errors.empSectionIdApp && (
                        <Error message="Appointment in unit is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>First Increment Month</p>
                      <select
                        name="firstIncrementMonth"
                        ref={register({ required: false })}
                        defaultValue={empAppointmentDetails.firstincrementmonth}
                        className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none`}
                      >
                        <option value="">
                          --select first increment month--{" "}
                        </option>
                        {MONTHS.map((month) => (
                          <option value={month.monthid}>{month.month}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-span-1">
                      <p>Second Increment Month</p>
                      <select
                        name="secondIncrementMonth"
                        ref={register({ required: false })}
                        defaultValue={
                          empAppointmentDetails.secondincrementmonth
                        }
                        className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none`}
                      >
                        <option value="">
                          --select second increment month--{" "}
                        </option>
                        {MONTHS.map((month) => (
                          <option value={month.monthid}>{month.month}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-3 mt-3">
                    <div className="col-span-1">
                      <p>Date of Retirement</p>
                      <input
                        name="empDob"
                        type="date"
                        value={empRetirementDate}
                        className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none`}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="currentOfficialDetailsDiv"
                className="p-3 shadow bg-white mt-4"
              >
                <div className="w-full rounded-sm border-b font-medium text-blue-600 p-2 mb-2">
                  Current Official Details
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-1">
                      <p>Current Designation : </p>
                      <div>
                        <select
                          name="empDesignationId"
                          ref={register({ required: true })}
                          onChange={handleDesignationChange}
                          className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                            errors.empDesignationId ? "border-red-700" : ""
                          }`}
                          value={designationId}
                        >
                          <option value="">
                            --select current designation--
                          </option>

                          {designationList &&
                            designationList.map((x) => {
                              return (
                                <option value={x.designationid}>
                                  {x.designationname}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      {errors.empDesignationId && (
                        <Error message="Designation is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Current Level</p>
                      <div>
                        <select
                          name="empLevelId"
                          ref={register({ required: false })}
                          onChange={handleLevelChange}
                          className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                            errors.empLevelId ? "border-red-700" : ""
                          }`}
                          value={levelId}
                        >
                          <option value="">--select current level-- </option>
                          {levelList &&
                            levelList.map((x) => {
                              return (
                                <option value={x.levelid}>
                                  {" "}
                                  Level {x.levelnumber}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      {errors.empLevelId && (
                        <Error message="Level is required" />
                      )}
                    </div>
                    <div className="col-span-1">
                      <p>Current Unit</p>
                      <select
                        name="empUnit"
                        defaultValue={
                          empCurrentOfficialDetails.empcurrentunitname
                        }
                        ref={register({ required: true })}
                        className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                          errors.empUnit ? "border-red-700" : ""
                        }`}
                      >
                        <option value="">--select unit-- </option>
                        {unitList &&
                          unitList.map((x) => {
                            return <option value={x}> {x}</option>;
                          })}
                      </select>
                      {errors.empUnit && (
                        <Error message="Current unit is required" />
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-3 mt-3">
                    <div className="col-span-1">
                      <p>Current Employee Type</p>
                      <div>
                        <select
                          name="empTypeId"
                          ref={register({ required: true })}
                          onChange={handleEmployeeTypeChange}
                          className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                            errors.empTypeId ? "border-red-700" : ""
                          }`}
                          value={empTypeId}
                        >
                          <option value="">
                            --select current employee type--{" "}
                          </option>
                          {employeeTypeList &&
                            employeeTypeList.map((x) => {
                              return (
                                <option value={x.employeetypeid}>
                                  {x.employeetypename}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      {errors.empTypeId && (
                        <Error message="Employee type is required" />
                      )}
                    </div>

                    <div className="col-span-1">
                      <p>Current Section</p>
                      <select
                        name="empSectionId"
                        ref={register({ required: false })}
                        onChange={handleSectionChange}
                        className={`border rounded-lg w-full  py-1.5 px-3 text-sm outline-none ${
                          errors.empSectionId ? "border-red-700" : ""
                        }`}
                        value={sectionId}
                      >
                        <option value="">--select section-- </option>
                        {sectionList &&
                          sectionList.map((x) => {
                            return (
                              <option value={x.sectionid}>
                                {x.sectionname}
                              </option>
                            );
                          })}
                      </select>
                      {errors.empSectionId && (
                        <Error message="Current section is required" />
                      )}
                    </div>

                    <div className="col-span-1">
                      <p>Date promoted to current status</p>
                      <input
                        name="empPromotedToCurrentDate"
                        type="date"
                        value={datePromotedToCurrentStatus}
                        onChange={(e) => {
                          setDatePromotedToCurrentStatus(e.target.value);
                        }}
                        className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                          errors.empPromotedToCurrentDate
                            ? "border-red-700"
                            : ""
                        }`}
                        ref={register({ required: false })}
                      />
                      {errors.empPromotedToCurrentDate && (
                        <Error message="Promotion date to current employee type is required" />
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-3 mt-3">
                    <div className="col-span-1">
                      <p>
                        Date of regularization
                        <small className="ml-1 text-green-600 font-medium">
                          ( if regular employee )
                        </small>
                      </p>
                      <input
                        name="dateofregularization"
                        type="date"
                        value={dateOfRegularization}
                        onChange={(e) => {
                          setDateOfRegularization(e.target.value);
                        }}
                        className={`border rounded-lg w-full py-1.5 px-3 text-sm outline-none ${
                          errors.dateofregularization ? "border-red-700" : ""
                        }`}
                        ref={register({ required: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3 shadow bg-white mt-4">
                <div className="w-full rounded-sm border-b font-medium text-blue-600 p-2 mb-2">
                  Upload documents
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-3">
                    <div className="col-span-1 flex flex-col">
                      <label className="mb-1">
                        COI (Certificate of Identfication)
                      </label>
                      <input
                        type="file"
                        name="coidoc"
                        ref={register({ required: false })}
                      ></input>
                    </div>
                    <div className="col-span-1 flex flex-col">
                      <label className="mb-1">Birth Certificate</label>
                      <input
                        type="file"
                        name="birthdoc"
                        ref={register({ required: false })}
                      ></input>
                    </div>
                    <div className="col-span-1 flex flex-col">
                      <label className="mb-1">Voter Card</label>
                      <input
                        type="file"
                        name="voterdoc"
                        ref={register({ required: false })}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              {/* {showPromotionHistoryDiv && (
                <Fragment>
                  <div className="w-full rounded-sm bg-blue-200 text-gray-600 font-medium p-2 mt-4">
                    Promotion History Details <small>(optional)</small>
                  </div>
                  <button
                    className="px-2 py-1 text-sm text-black mt-2 rounded-sm flex border border-gray-600"
                    type="button"
                    //   onClick={handlePromotionHistoryAdd}
                  >
                    Add promotion history
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                  <div className=" mt-2 text-left">
                    <table
                      className="table table-bordered table-hover w-3/4"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th className="font-normal">
                            Promoted to employee type{" "}
                          </th>
                          <th className="font-normal"> Promoted to level </th>
                          <th className="font-normal"> Promoted on </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotionHistoryList.map((item, index) => (
                          <tr key={index} className="">
                            <td>
                              <div>
                                <select
                                  name="empTypeIdHistory"
                                  onChange={handleChange(index)}
                                  className={`border rounded-lg w-3/4  py-1.5 px-3 text-sm outline-none ${
                                    errors.empTypeIdHistory
                                      ? "border-red-700"
                                      : ""
                                  }`}
                                  value={item.empTypeIdHistory}
                                >
                                  <option value="">
                                    --select employee type--{" "}
                                  </option>
                                  {employeeTypeList &&
                                    employeeTypeList.map((x) => {
                                      return (
                                        <option
                                          value={x.employeetypeid}
                                          selected={
                                            x.employeetypeid ===
                                            item.empTypeIdHistory
                                              ? true
                                              : false
                                          }
                                        >
                                          {x.employeetypename}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </td>
                            <td>
                              <select
                                name="empLevelIdHistory"
                                onChange={handleChange(index)}
                                className="border rounded-lg w-3/4  py-1.5 px-3 text-sm outline-none"
                                value={item.empLevelIdHistory}
                              >
                                <option value="">--select level-- </option>
                                {levelList &&
                                  levelList.map((x) => {
                                    return (
                                      <option value={x.levelid}>
                                        {" "}
                                        Level {x.levelnumber}-{x.levelname}
                                      </option>
                                    );
                                  })}
                              </select>
                            </td>
                            <td>
                              <input
                                name="dateHistory"
                                type="date"
                                className="border rounded-lg w-full py-1.5 px-3 text-sm outline-none"
                                value={item.dateHistory}
                                onChange={handleChange(index)}
                              />
                            </td>
                            <td className="flex justify-center ml-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={handleRemoveSpecificRow(index)}
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Fragment>
              )} */}
              <br></br>
              <div className="flex justify-end">
                <button
                  className="px-6 py-2 bg-sky-blue text-white mr-3 shadow-lg"
                  type="submit"
                  disabled={isSubmitting ? true : false}
                >
                  {isSubmitting ? (
                    <Fragment>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Submitting</span>
                    </Fragment>
                  ) : (
                    "Submit"
                  )}
                </button>
                {unitname === "Gangtok" ? (
                  <Fragment>
                    {" "}
                    <Link to="/admin/employeelistgangtok">
                      <button className="px-4 py-3 border border-gray-300 text-sky-blue bg-white">
                        <span>Cancel</span>
                      </button>
                    </Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link to="/admin/employeelistjorethang">
                      <button className="bg-gray-400 p-2 text-white focus:outline-none rounded-sm flex items-center">
                        <span>Cancel</span>
                      </button>
                    </Link>
                  </Fragment>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EditEmployee;
